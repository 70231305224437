// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg[data-v-18bba6a0] {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.forgot-tip[data-v-18bba6a0] {
  font-size: 13px;
  padding: 10px 0;
  color: gray;
}
.pill[data-v-18bba6a0] {
  padding: 8px 18px;
  margin: 10px;
  background: #01bad4;
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.details-input[data-v-18bba6a0] {
  padding: 10px;
  margin-top: 10px;
  color: #01bad4;
  font-size: 14px;
  border: solid 1px #01bad4;
  border-radius: 7px;
}
.dialog[data-v-18bba6a0] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  /* Set a max width for better layout */
  text-align: left;
  z-index: 100;
  border-radius: 8px;
  /* Rounded corners */
}
`, "",{"version":3,"sources":["webpack://./src/views/Account/PassModal.vue"],"names":[],"mappings":"AACA;EACE,oCAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;AAAF;AAGA;EACE,eAAA;EACA,eAAA;EACA,WAAA;AADF;AAIA;EACE,iBAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AAFF;AAKA;EACE,aAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;AAHF;AAMA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,iBAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EAJA,sCAAsC;EAKtC,gBAAA;EACA,YAAA;EACA,kBAAA;EAHA,oBAAoB;AACtB","sourcesContent":["\n.bg {\n  background-color: rgba(0, 0, 0, 0.6);\n  position: fixed;\n  z-index: 10;\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  left: 0;\n}\n\n.forgot-tip {\n  font-size: 13px;\n  padding: 10px 0;\n  color: gray;\n}\n\n.pill {\n  padding: 8px 18px;\n  margin: 10px;\n  background: #01bad4;\n  color: white;\n  text-transform: uppercase;\n  border-radius: 5px;\n  cursor: pointer;\n  text-align: center;\n}\n\n.details-input {\n  padding: 10px;\n  margin-top: 10px;\n  color: #01bad4;\n  font-size: 14px;\n  border: solid 1px #01bad4;\n  border-radius: 7px;\n}\n\n.dialog {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: white;\n  padding: 20px;\n  width: 100%;\n  max-width: 400px; /* Set a max width for better layout */\n  text-align: left;\n  z-index: 100;\n  border-radius: 8px; /* Rounded corners */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
