<template>
  <section class="w-full">
    <!-- Header for Back Arrow and Title -->
    <header class="w-full px-4 py-3 bg-white border-b border-gray-100 flex justify-center items-center gap-2">
      <button
        type="button"
        class="w-6 h-6 flex flex-wrap items-center justify-center cursor-pointer"
        @click="emmitBack"
        aria-label="Go back"
      >
        <img :src="img.backArrow" class="w-6 h-6 rounded-full" alt="Back arrow">
      </button>
      <h1 class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Select Pickup Slot
      </h1>
    </header>

    <!-- Instructions -->
    <div class="mt-5 mb-7">
      <h2 class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Select your pickup slot
      </h2>
      <p class="text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">
        Please select the time and date you'd like us to collect your laundry. View available slots below.
      </p>
    </div>

    <!-- Warning for Disabled Slots -->
    <div class="warning" role="alert" v-if="limit">
      Due to our carrying capacity, slots with fewer than {{ limit > maxSpaces ? maxSpaces : limit }} {{ limit === 1 ? "space" : "spaces" }} have been disabled
    </div>

    <!-- Loading State -->
    <loader v-if="loading" name="collection slots"></loader>

    <form v-else class="container">
      <!-- Header for Choosing Date -->
      <div class="mb-4">
        <label for="day-select" class="text-[#111826] text-base font-bold font-['Manrope'] leading-7 tracking-tight block">
          Choose Day
        </label>
      </div>

      <!-- Day Selection Dropdown -->
      <div class="overflow-x-auto mb-6">
        <select
          id="day-select"
          v-model="daySelected"
          class="font-['Manrope'] text-sm mb-2 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb] sm:text-sm"
          aria-label="Select day for pickup"
        >
          <option v-for="day in parseDays" :key="day.id" :value="day" class="font-['Manrope'] text-sm whitespace-normal">
            {{ day.relative }}
          </option>
        </select>
      </div>

      <!-- Slot Pills (only show when a day is selected) -->
      <fieldset v-if="daySelected" class="mb-6">
        <legend class="text-[#111826] text-base font-bold font-['Manrope'] leading-7 tracking-tight mb-4">
          {{ selected.relative }} Slot
        </legend>

        <div class="flex full-w justify-center flex-wrap gap-2" role="radiogroup" aria-label="Available time slots">
          <label
            v-for="slot in daySelected.slots"
            :key="slot.start"
            :class="{
              'bg-[#081f3f] text-white': slot.start == selected.start,
              'bg-gray-300 text-gray-500 line-through': slot.disabled,
              'cursor-pointer': !slot.disabled,
              'cursor-not-allowed': slot.disabled
            }"
            class="py-2 px-4 border-gray-200 border rounded-full text-sm font-medium relative"
            :for="`slot-${slot.start}`"
          >
            {{ slot.time_string }}
            <input
              type="radio"
              :id="`slot-${slot.start}`"
              name="pickup-slot"
              :value="slot"
              v-model="radioSelectedSlot"
              :disabled="slot.disabled"
              class="sr-only"
              @change="setSlot(slot)"
            >
          </label>
        </div>
      </fieldset>
    </form>

    <!-- Additional Information -->
    <div class="text-zinc-700 text-center text-xs font-medium font-['Manrope'] leading-tight tracking-tight" v-if="!loading && !isUpgraded">
      All laundry orders will be delivered between <strong>13:00 - 18:00</strong> on the day they are collected.<br><strong> Any orders 14Kg+ in size will possibly require a next day return.</strong>
    </div>
    <div class="text-zinc-700 text-center text-xs font-medium font-['Manrope'] leading-tight tracking-tight" v-if="!loading">
      Please note we only come to Penarth and St Fagans once a day between 10-1pm, so if you're in either of these areas, please bear this in mind when choosing a collection slot. All orders to these areas will have a next day return.
    </div>
    <div class="text-zinc-700 text-center text-xs font-medium font-['Manrope'] leading-tight tracking-tight" v-if="!loading && isUpgraded">
      All orders will be returned the following day in the same time slot.
    </div>

    <!-- NEXT Button -->
    <button
      type="button"
      @click="changeView"
      class="w-full flex-grow h-12 px-6 py-3 bg-[#2ab3cb] rounded-lg shadow-sm justify-center items-center gap-3 inline-flex overflow-hidden mt-4 lg:mt-2"
      :class="{ 'cursor-not-allowed': selected.start === null, 'bg-gray-400': selected.start === null }"
      :disabled="selected.start === null"
      aria-label="Proceed to next step"
    >
      <span class="text-black text-sm font-bold font-['Manrope'] leading-normal tracking-tight">NEXT</span>
    </button>
  </section>
</template>

<script>
import backArrow from "assets/backarrow.svg";
export default {
  props: ["value", "limit", "isUpgraded"],

  data() {
    return {
      img: {
        backArrow,
      },
      loading: true, // Whether loading AJAX data
      days: [], // All the slot information for different days
      selected: this.value,
      radioSelectedSlot: null, // For v-model binding with radio inputs
      daySelected: null,
      reloadSeconds: 30,
      maxSpaces: 5, // NOTE: This should not be hardcoded, fix it later
    };
  },

  created() {
    this.reload();
    // Initialize radioSelectedSlot when component is created
    if (this.value && this.value.start) {
      this.radioSelectedSlot = this.value;
    }
  },

  watch: {
    limit(val) {
      this.reset();
    },
    // Keep radioSelectedSlot and selected in sync
    radioSelectedSlot(val) {
      if (val) {
        this.selected = val;
        this.$emit("input", val);
      }
    },
    // Keep radioSelectedSlot and selected in sync from the other direction
    selected(val) {
      if (val && val.start) {
        this.radioSelectedSlot = val;
      }
    }
  },

  methods: {
    // Change view (next step)
    changeView() {
      this.$emit("changeView");
    },

    // Emit back event
    emmitBack() {
      this.$emit("back");
    },

    // Set the selected slot for the day
    setSlot(slot) {
      if (!slot.disabled) {
        this.selected = slot;
        this.radioSelectedSlot = slot;
        this.$emit("input", slot);
      }
    },

    // Reset the selection
    reset() {
      this.selected = {
        start: null,
        end: null,
      };
      this.radioSelectedSlot = null;
      this.$emit("input", this.selected);
    },

    // Reload data
    reload() {
      this.$api
        .get("/slots?days=14", {
          params: {
            user: this.user,
          },
        })
        .then((res) => {
          this.loading = false;
          this.days = res.data;

          // After loading data, try to select the previously selected slot if it exists
          if (this.selected && this.selected.start) {
            this.radioSelectedSlot = this.selected;
          }

          window.setTimeout(() => this.reload(), this.reloadSeconds * 1000);
        });
    },

    // Select a slot
    selectSlot(slot) {
      if (!slot.disabled) {
        this.selectedSlot = slot;
        // Handle further actions here when a slot is selected (like booking)
      }
    },
  },

  computed: {
    parseDays() {
      let clone = (obj) => JSON.parse(JSON.stringify(obj));
      let days = clone(this.days);

      days.map((day) => {
        day.slots.map((slot) => {
          if (slot.spaces < this.limit && slot.spaces !== this.maxSpaces)
            slot.disabled = true;
          return slot;
        });

        return day;
      });

      return days;
    },
  },
};
</script>