var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"w-full max-w-full rounded-3xl relative"},[_c('div',{staticClass:"w-full px-4 py-3 bg-white border-b border-gray-100 flex justify-center items-center gap-2"},[_c('div',{staticClass:"w-6 h-6 flex items-center justify-center cursor-pointer",on:{"click":_vm.emmitBack}},[_c('img',{staticClass:"w-6 h-6 rounded-full",attrs:{"src":_vm.img.backArrow}})]),_vm._v(" "),_c('div',{staticClass:"flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight"},[_vm._v("\n        Choose a subscription bundle\n      ")])]),_vm._v(" "),(_vm.loading)?_c('loader',{attrs:{"name":"subscription bundles"}}):_c('div',{staticClass:"options"},_vm._l((_vm.bundles),function(bundle){return _c('div',{staticClass:"flex-start flex-col w-full rounded-xl border flex p-4 cursor-pointer relative mb-4 transition-all duration-200",class:{ 
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': _vm.selected.bundle.type === bundle.type, 
      'border-gray-200': _vm.selected.bundle.type !== bundle.type 
    },on:{"click":function($event){return _vm.setBundle(bundle)}}},[_c('div',{staticClass:"text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight"},[_vm._v("\n      "+_vm._s(bundle.name)+"\n    ")]),_vm._v(" "),(bundle.softener_upgrade)?_c('div',{staticClass:"text-zinc-700 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mt-1"},[_vm._v("\n      Free softener upgrade\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"gap-4 mt-4"},_vm._l((bundle.items),function(item){return _c('div',{staticClass:"gap-4 mt-4 justify-between items-center"},[_c('div',{staticClass:"name text-zinc-900 text-sm font-semibold font-['Manrope'] leading-tight tracking-tight"},[_vm._v("\n          "+_vm._s(item.name_string)+" x"+_vm._s(item.credits)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"text-zinc-500 text-xs font-medium font-['Manrope'] leading-tight tracking-tight"},[_vm._v("\n          "+_vm._s(item.treatment_string)+"\n        ")])])}),0),_vm._v(" "),_c('div',{staticClass:"price text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight mt-4 text-right"},[_vm._v("\n      "+_vm._s(_vm._f("formatPrice")(bundle.price))+" / month\n    ")]),_vm._v(" "),_c('input',{staticClass:"absolute top-4 right-4",attrs:{"type":"radio"},domProps:{"checked":_vm.selected.bundle.type === bundle.type}})])}),0),_vm._v(" "),_c('div',{staticClass:"text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight"},[_vm._v("Choose a renewal option")]),_vm._v(" "),_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex-grow h-[50px] rounded-xl border items-center justify-center cursor-pointer relative mr-2 flex",class:{
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': _vm.selected.renew, 
      'border-gray-200': !_vm.selected.renew
    },on:{"click":function($event){return _vm.setRenew(true)}}},[_c('div',{staticClass:"text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight"},[_vm._v("\n      Auto-Renew \n    ")]),_vm._v(" "),_c('input',{staticClass:"absolute top-3 right-3",attrs:{"type":"radio"},domProps:{"checked":_vm.selected.renew}})]),_vm._v(" "),_c('div',{staticClass:"flex-grow h-[50px] rounded-xl border items-center justify-center cursor-pointer relative ml-2 flex",class:{
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': !_vm.selected.renew, 
      'border-gray-200': _vm.selected.renew
    },on:{"click":function($event){return _vm.setRenew(false)}}},[_c('div',{staticClass:"text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight"},[_vm._v("\n      No Renewal\n    ")]),_vm._v(" "),_c('input',{staticClass:"absolute top-3 right-3",attrs:{"type":"radio"},domProps:{"checked":!_vm.selected.renew}})])]),_vm._v(" "),_c('div',{staticClass:"flex-grow h-12 w-full px-6 py-3 bg-[#2ab3cb] rounded-lg shadow-sm justify-center items-center gap-3 inline-flex overflow-hidden mt-4 lg:mt-2",on:{"click":_vm.changeView}},[_c('div',{staticClass:"text-black text-sm font-bold font-['Manrope'] leading-normal tracking-tight *: cursor-pointer"},[_vm._v("NEXT")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }