<template>
  <div class="footer-nav flex justify-between bg-white border-t border-gray-200 p-4 rounded-b-3xl fixed inset-x-0 bottom-0 lg:relative lg:bottom-auto lg:inset-x-auto lg:rounded-none">
    <div
      v-for="(item, index) in navItems"
      :key="index"
      @click="handleNavigation(item.path)"
      class="flex flex-col items-center"
    >
      <!-- Display the SVG content with v-html -->
      <div
        v-html="getSvgContent(item.icon, isActive(item.path))"
        class="w-6 h-6 rounded-full"
      ></div>
      <p :class="isActive(item.path) ? 'text-[#081F3F] font-bold' : 'text-gray-400'" class="text-xs">
        {{ item.label }}
      </p>
    </div>
  </div>
</template>

<script>
import homeIcon from 'assets/home.svg';
import ordersIcon from 'assets/pastOrders.svg';
import supportIcon from 'assets/support.svg';
import accountIcon from 'assets/account.svg';

export default {
  name: 'FooterNav',
  data() {
    return {
      navItems: [
        { icon: homeIcon, label: 'Home', path: '/' },
        { icon: ordersIcon, label: 'Order', path: '/order' },
        { icon: supportIcon, label: 'Support', path: '/support' },
        { icon: accountIcon, label: 'Account', path: '/account' }
      ],
      svgContents: {}
    };
  },
  async mounted() {
    // Load SVG contents when the component is mounted
    await Promise.all(this.navItems.map(async (item) => {
      const response = await fetch(item.icon);
      const svgText = await response.text();
      this.$set(this.svgContents, item.icon, svgText);
    }));
  },
  methods: {
    redirectTo(route) {
  this.$router.push(route);
    },
    redirectToFullUrl(url) {
    window.location.href = url;
  },
    handleNavigation(path) {
        if (path.startsWith('http')) {
        this.redirectToFullUrl(path);
      } else {
        this.redirectTo(path);
      }
    },
    redirectToHome() {
      this.$router.push("/");
    },
    redirectToOrders() {
      this.$router.push("/orders");
    },
    redirectToSupport() {
         this.$router.push("/support");
    },
    redirectToAccount() {
      this.$router.push("/account");
    },
    isActive(path) {
      return this.$route.path === path;
    },
    getSvgContent(url, isActive) {
      const svgContent = this.svgContents[url] || '';
      const fillColor = isActive ? '#081F3F' : '#A1A1AA';
      return svgContent.replace(/fill="[^"]*"/g, `fill="${fillColor}"`);
    }
  }
};
</script>

<style scoped>
/* Add component-specific styles here if needed */
</style>
