// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message[data-v-12956732] {
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.button[data-v-12956732] {
  background: #fef7f7 !important;
  border-radius: 20px;
}
.warning[data-v-12956732] {
  background-color: #fbdede;
  color: #cc3333;
}
.warning .theme--light.v-btn[data-v-12956732] {
  color: rgba(241, 79, 79, 0.87) !important;
}
.left[data-v-12956732] {
  margin-top: -10px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/views/Dashboard/Messages.vue","webpack://./Messages.vue"],"names":[],"mappings":"AAEA;EAAS,aAAA;EACL,YAAA;EACA,mBAAA;EACH,eAAA;ACAD;ADIA;EAAQ,8BAAA;EAEJ,mBAAA;ACFJ;ADGA;EAAS,yBAAA;EACL,cAAA;ACAJ;ADCA;EACI,yCAAA;ACCJ;ADCA;EAAM,iBAAA;EAAiB,gBAAA;ACGvB","sourcesContent":["\n\n.message{padding: 20px;\n    margin: 20px;\n    border-radius: 10px;\n\tcursor: pointer;\n \n  \n}\n.button{background: #fef7f7!important;\n    \n    border-radius: 20px;}\n.warning{background-color: #fbdede;\n    color: #cc3333;}\n.warning .theme--light.v-btn {\n    color: rgba(241, 79, 79, 0.87)!important;\n}\n.left{margin-top:-10px;text-align: left;}\n\t\n",".message {\n  padding: 20px;\n  margin: 20px;\n  border-radius: 10px;\n  cursor: pointer;\n}\n.button {\n  background: #fef7f7 !important;\n  border-radius: 20px;\n}\n.warning {\n  background-color: #fbdede;\n  color: #cc3333;\n}\n.warning .theme--light.v-btn {\n  color: rgba(241, 79, 79, 0.87) !important;\n}\n.left {\n  margin-top: -10px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
