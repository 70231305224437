// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge2 {
  background-color: #4CAF50;
  border-radius: 25px;
  padding: 5px;
  margin-left: 10px;
  color: #ddd;
}
.eco {
  background-color: #01d457;
  /* Green background */
}
.options-box {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
.options-header {
  font-weight: bold;
  margin-bottom: 10px;
}
@media (max-width: 1100px) {
section.content .calc {
    display: inline-flex;
}
}
@media (max-width: 900px) {
.badge2 {
    display: block;
    margin-top: 3px;
}
}
`, "",{"version":3,"sources":["webpack://./src/views/Order/Type.vue","webpack://./Type.vue"],"names":[],"mappings":"AACA;EACE,yBAAA;EACC,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;ACAH;ADGA;EACC,yBAAA;ECDC,qBAAqB;AACvB;ADGA;EACI,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;ACDJ;ADGA;EACI,iBAAA;EACA,mBAAA;ACDJ;ADQC;AAAA;IAAgB,oBAAA;ACJf;AACF;ADMA;AAAmB;IAChB,cAAA;IACA,eAAA;ACHD;AACF","sourcesContent":["\n.badge2 {\n\t\tbackground-color: #4CAF50;\n\t\t\tborder-radius: 25px;\n\t\t\tpadding: 5px;\n\t\t\tmargin-left: 10px;\n\t\t\tcolor: #ddd;\n}\n\n.eco {\n\tbackground-color: #01d457;\n\t/* Green background */\n}\n.options-box {\n    margin-bottom: 20px;\n    border: 1px solid #ccc;\n    padding: 10px;\n    border-radius: 5px;\n}\n.options-header {\n    font-weight: bold;\n    margin-bottom: 10px;\n}\n@mobile: ~'(max-width: 1100px)';\n@mobile900: ~'(max-width: 900px)';\nsection.content {\n.calc{\n\t\n\t@media @mobile {display: inline-flex;}\n}\n}\n@media @mobile900 {.badge2 {\n\t\t\tdisplay: block;\n\t\t\tmargin-top: 3px;}\n}\n",".badge2 {\n  background-color: #4CAF50;\n  border-radius: 25px;\n  padding: 5px;\n  margin-left: 10px;\n  color: #ddd;\n}\n.eco {\n  background-color: #01d457;\n  /* Green background */\n}\n.options-box {\n  margin-bottom: 20px;\n  border: 1px solid #ccc;\n  padding: 10px;\n  border-radius: 5px;\n}\n.options-header {\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n@media (max-width: 1100px) {\n  section.content .calc {\n    display: inline-flex;\n  }\n}\n@media (max-width: 900px) {\n  .badge2 {\n    display: block;\n    margin-top: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
