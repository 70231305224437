    <template>
      <div class="hidden lg:block lg:w-1/3 bg-gray-100 p-6 rounded-l-3xl">
        <div class="flex flex-col items-center">
          <div class="flex items-center justify-between w-full mb-6">
            <img :src="img.logoDark" class="w-14 h-14 rounded-lg" alt="Logo">
          </div>
          <div class="mt-5 text-center">
            <p class="text-xl font-bold text-gray-900">Hi {{ user.name }}</p>
            <p class="text-sm text-gray-600">Welcome to your Wash Cycle.</p>
          </div>
        </div>
      </div>
    </template>
    
    <script>
    export default {
      name: 'Sidebar',
      props: {
        img: {
          type: Object,
          required: true
        },
        user: {
          type: Object,
          required: true
        }
      }
    }
    </script>
    
    <style scoped>
    /* Add any component-specific styles here */
    </style>