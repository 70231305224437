<template lang="html">
  <div class="w-full max-w-md lg:max-w-4xl h-auto relative p-4">
    <div class="h-12 flex flex-col justify-start items-start mb-4">
      <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Laundry Content</div>
      <div class="text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">Is your laundry load whites or colours?</div>
    </div>
    <div class="flex justify-between ">
      <div @click="setWhites(false)" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': !whites, 'border-gray-200': whites}" class="flex-grow h-[50px] rounded-xl border  items-center justify-center cursor-pointer relative mr-2 flex">
        <div class="text-center text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">Colours</div>
        <input type="radio" :checked="!whites" class="absolute top-3 right-3">
      </div>
      <div @click="setWhites(true)" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': whites, 'border-gray-200': !whites}" class="flex-grow h-[50px] rounded-xl border  items-center justify-center cursor-pointer relative ml-2 flex">
        <div class="text-center text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">Whites</div>
        <input type="radio" :checked="whites" class="absolute top-3 right-3">
      </div>
    </div>
  </div>
</template>

<script>
import colours from "assets/icons/colours.png";
import whites from "assets/icons/whites.png";

export default {
	props: [
		"value"
	],

	data() {
		return {
			img: { colours, whites },
			whites: this.value
		}
	},

	methods: {
		setWhites(whites) {
			this.whites = whites;
			this.$emit("input", this.whites);
		},
	}
}
</script>
