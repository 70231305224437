<template lang="html">
  <div class="w-full max-w-md lg:max-w-4xl h-auto relative bg-white rounded-3xl overflow-hidden p-4">
    <!-- Header with Back Icon -->
    <div class="w-full px-4 py-3 bg-white border-b border-gray-100 flex justify-center items-center gap-2">
      <div class="w-6 h-6 flex items-center justify-center cursor-pointer" @click="emmitBack">
        <img :src="img.backArrow" class="w-6 h-6 rounded-full" alt="Back">
      </div>
      <div class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Choose Desired Load Weight
      </div>
    </div>

    <!-- Description -->
    <div class="h-12 flex flex-wrap justify-start items-start mt-5 mb-7">
      <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Select Laundry Load Weight
      </div>
      <div class="text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">
        Choose a type that includes tumble drying or select an eco-friendly option.
      </div>
    </div>

    <!-- Options Section -->
    <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
      <!-- Dynamic Option Cards for Clothes Loads -->
      <div
        v-if="value.type === 'clothes'"
        v-for="load in clothesLoads" @click="setLoad(load)"
        :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': load.type === selected.load.type, 'border-gray-200': load.type !== selected.load.type}"
        class="w-full  rounded-xl border flex flex-col items-center justify-center p-2 cursor-pointer relative"
      >
       <div class="text-center pr-2">
          <div class="text-zinc-900 text-sm sm:text-base font-bold font-['Manrope'] leading-7 tracking-tight">
            {{ load.name_string }}
          </div>
          <div class="text-zinc-500 text-xs sm:text-sm font-medium font-['Manrope'] leading-tight tracking-tight">
            {{ load.treatment_string }}
          </div>
          <div class="text-[#081f3f] text-sm mt-5 font-extrabold font-['Manrope'] leading-none tracking-tight">
            £{{ load.price.toFixed(2) }}
          </div>
        </div>
        <input
          type="radio"
          :checked="load.type === selected.load.type"
          class="absolute top-3 right-3"
        />
      </div>

      <!-- Dynamic Option Cards for Towel Loads -->
      <div
        v-if="value.type === 'towels'"
        v-for="load in towelLoads" @click="setLoad(load)"
        class="w-full  rounded-xl border flex flex-col items-center justify-center p-2 cursor-pointer relative"
      >
       <div class="text-center pr-2">
          <div class="text-zinc-900 text-sm sm:text-base font-bold font-['Manrope'] leading-7 tracking-tight">
            {{ load.name_string }}
          </div>
          <div class="text-zinc-500 text-xs sm:text-sm font-medium font-['Manrope'] leading-tight tracking-tight">
            {{ load.treatment_string }}
          </div>
          <div class="text-[#081f3f] text-sm mt-5 font-extrabold font-['Manrope'] leading-none tracking-tight">
            £{{ load.price }}
          </div>
        </div>
        <input
          type="radio"
          :checked="load.type === selected.load.type"
          class="absolute top-3 right-3"
        />
      </div>

      <!-- Dynamic Option Cards for Duvet Loads -->
      <div
        v-if="value.type === 'duvets'"
        v-for="load in duvetsLoads" @click="setLoad(load)"
        :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': load.type === selected.load.type, 'border-gray-200': load.type !== selected.load.type}"
        class="w-full  rounded-xl border flex flex-col items-center justify-center p-2 cursor-pointer relative"
      >
       <div class="text-center pr-2">
          <div class="text-zinc-900 text-sm sm:text-base font-bold font-['Manrope'] leading-7 tracking-tight">
            {{ load.name_string }}
          </div>
          <div class="text-zinc-500 text-xs sm:text-sm font-medium font-['Manrope'] leading-tight tracking-tight">
            {{ load.treatment_string }}
          </div>
          <div class="text-[#081f3f] text-sm mt-5 font-extrabold font-['Manrope'] leading-none tracking-tight">
            £{{ load.price }}
          </div>
        </div>
        <input
          type="radio"
          :checked="load.type === selected.load.type"
          class="absolute top-3 right-3"
        />
      </div>

      <!-- Dynamic Option Cards for Mixed Loads -->
      <div
        v-if="value.type === 'wash_spin_fold'"
        v-for="load in mixed" @click="setLoad(load)"
        :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': load.type === selected.load.type, 'border-gray-200': load.type !== selected.load.type}"
        class="w-full  rounded-xl border flex flex-col items-center justify-center p-2 cursor-pointer relative"
      >
       <div class="text-center pr-2">
          <div class="text-zinc-900 text-sm sm:text-base font-bold font-['Manrope'] leading-7 tracking-tight">
            {{ load.name_string }}
          </div>
          <div class="text-zinc-500 text-xs sm:text-sm font-medium font-['Manrope'] leading-tight tracking-tight">
            {{ load.treatment_string }}
          </div>
          <div class="text-[#081f3f] text-sm mt-5 font-extrabold font-['Manrope'] leading-none tracking-tight">
            £{{ load.price }}
          </div>
        </div>
        <input
          type="radio"
          :checked="load.type === selected.load.type"
          class="absolute top-3 right-3"
        />
      </div>
    </div>

    <!-- Feather Option -->
    <feather-view v-if="value.type === 'duvets'" v-model="value.feather"></feather-view>

    <!-- Footer with Next Button -->
    <div class="flex justify-center items-center gap-3">
      <div @click="changeView" class="flex-grow h-12 px-6 py-3 bg-[#2ab3cb] rounded-lg shadow-sm justify-center items-center gap-3 inline-flex overflow-hidden mt-4 lg:mt-2">
        <div class="text-black text-sm font-bold font-['Manrope'] leading-normal tracking-tight cursor-pointer">NEXT</div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentView from "./Content";
import FeatherView from "./Feather";
import backArrow from "assets/backarrow.svg";

export default {
  components: {
    ContentView,
    FeatherView,
  },

  props: {
    value: {
      type: Object,
      default: () => ({ load: { type: null } }),
    },
  },

  data() {
    return {
      img: { backArrow },
      loading: true, // Whether loading AJAX data
      loads: [], // Array of standalone loads
      clothesLoads: [],
      duvetsLoads: [],
      showSoftener: false,
      towelLoads: [],
      mixed: [],
      selected: this.value,
      showCalculator: false,
    };
  },

  created() {
    this.$api.get("/products/standalone").then((res) => {
      this.loading = false;
      this.loads = res.data;

      this.clothesLoads = this.loads.filter((load) => load.clothes);
      this.towelLoads = this.loads.filter((load) => load.towels);
      this.duvetsLoads = this.loads.filter((load) => load.duvettab);
      this.mixed = this.loads.filter((load) => load.mixed);

      if (this.selected?.type === "clothes") {
        this.setLoad(this.clothesLoads[0]);
      }
      if (this.selected?.type === "towels") {
        this.setLoad(this.towelLoads[0]);
      }
      if (this.selected?.type === "duvets") {
        this.setLoad(this.duvetsLoads[0]);
      }
      if (this.selected?.type === "mixed") {
        this.setLoad(this.mixed[0]?.type);
      }
      this.$emit("input", this.selected);
    });
  },

  methods: {
    setLoad(load) {
      this.selected.load = load;
      this.$emit("input", this.selected);
    },
    emmitBack() {
      this.$emit("back");
    },
    changeView() {
      this.$emit("changeView");
    },
  },
};
</script>