<template lang="html">
  <section class="billing flex w-full  p-4 pb-[73px]">
    <loader name="your cards" v-if="loading"></loader>
    <div class="cards flex flex-wrap justify-center w-full" v-else>

      <div class="card bg-white p-4 rounded-lg shadow-md w-full max-w-sm m-2" v-for="card in cards" :key="card.id">
        <div class="meta mb-2">
          <div class="brand text-lg font-semibold">{{ card.brand }}</div>
          <div class="number text-gray-600">•••• •••• •••• {{ card.last4 }}</div>
          <div class="expiry text-gray-500">{{ card.expiry_string }}</div>
        </div>

        <div class="actions">
          <div class="action text-red-500 cursor-pointer" @click="remove(card)">Remove</div>
        </div>
      </div>

      <div class="block" v-if="processing"></div>
<div class="card new text-white text-center bg-[#2ab3cb] p-4  mt-3 w-full rounded-lg shadow-md cursor-pointer mb-4" @click="showNewCard = true">
    Add a new card
</div>

    </div>

    <new-card v-if="showNewCard" @close="close"></new-card>
  </section>
</template>

<script>
import NewCard from "../../components/NewCard";

export default {
  components: {
    NewCard,
  },

  data() {
    return {
      loading: true,
      processing: false,
      cards: [],
      default_source: null,
      showNewCard: false,
    };
  },

  created() {
    this.reload();
  },

  methods: {
    reload() {
      this.$api.get("/user/cards").then((res) => {
        this.loading = false;
        this.processing = false;
        this.cards = res.data.cards;
      });
    },

    close() {
      this.showNewCard = false;
      this.reload();
    },

    remove(card) {
      let confirm = window.confirm(`Are you sure you wish to delete the ${card.brand} ending in ${card.last4}?`);

      if (confirm) {
        this.processing = true;
        this.$api.delete("/card/" + card.id, { redirect: false }).then((res) => {
          if (res.status !== 200) window.alert(res.data);
          this.reload();
        });
      }
    },
  },
};
</script>
