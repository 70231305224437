<template lang="html">
<section class="w-full max-w-full   rounded-3xl relative">
     <div class="w-full px-4 py-3  bg-white border-b border-gray-100 flex justify-center items-center gap-2">
      <!-- Back Icon Placeholder -->
      <div class="w-6 h-6 flex items-center justify-center cursor-pointer" @click="emmitBack">
        <!--  with actual back icon -->
        <img :src="img.backArrow" class="w-6 h-6 rounded-full">
      </div>
      <div class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Choose a subscription bundle
      </div>
      
    </div>
	<loader v-if="loading" name="subscription bundles"></loader>
<div class="options" v-else>
  <div
    class="flex-start flex-col w-full  rounded-xl border flex p-4 cursor-pointer relative mb-4 transition-all duration-200"
    :class="{ 
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected.bundle.type === bundle.type, 
      'border-gray-200': selected.bundle.type !== bundle.type 
    }"
    v-for="bundle in bundles"
    @click="setBundle(bundle)"
  >
    <!-- Bundle Name -->
    <div class=" text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
      {{ bundle.name }}
    </div>

    <!-- Softener Upgrade -->
    <div
      v-if="bundle.softener_upgrade"
      class="text-zinc-700 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mt-1"
    >
      Free softener upgrade
    </div>

    <!-- Items Section -->
    <div class="gap-4 mt-4">
      <div
        class="gap-4 mt-4	  justify-between items-center"
        v-for="item in bundle.items"
      >
        <div class="name text-zinc-900 text-sm font-semibold font-['Manrope'] leading-tight tracking-tight">
          {{ item.name_string }} x{{ item.credits }}
        </div>
        <div class=" text-zinc-500 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">
          {{ item.treatment_string }}
        </div>
      </div>
    </div>

    <!-- Price Section -->
    <div class="price text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight mt-4 text-right">
      {{ bundle.price | formatPrice }} / month
    </div>

    <!-- Selection Indicator -->
    <input
      type="radio"
      :checked="selected.bundle.type === bundle.type"
      class="absolute top-4 right-4"
    />
  </div>

</div>



<div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Choose a renewal option</div>
<div class="flex justify-between">
  <div 
    @click="setRenew(true)" 
    :class="{
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected.renew, 
      'border-gray-200': !selected.renew
    }" 
    class="flex-grow h-[50px]  rounded-xl border items-center justify-center cursor-pointer relative mr-2 flex"
  >
    <div class=" text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
      Auto-Renew 
    </div>
    <input type="radio" :checked="selected.renew" class="absolute top-3 right-3">
  </div>
  <div 
    @click="setRenew(false)" 
    :class="{
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': !selected.renew, 
      'border-gray-200': selected.renew
    }" 
    class="flex-grow h-[50px]  rounded-xl border items-center justify-center cursor-pointer relative ml-2 flex"
  >
    <div class=" text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
      No Renewal
    </div>
    <input type="radio" :checked="!selected.renew" class="absolute top-3 right-3">
  </div>

</div>
      <div  @click="changeView"  class="flex-grow h-12 w-full px-6 py-3 bg-[#2ab3cb] rounded-lg shadow-sm justify-center items-center gap-3 inline-flex overflow-hidden  mt-4 lg:mt-2">
      <div class="text-black text-sm font-bold font-['Manrope'] leading-normal tracking-tight    *:
      cursor-pointer">NEXT</div>
    </div>
</section>
</template>

<script>
import backArrow from "assets/backarrow.svg";
export default {
	props: [
		"value"
	],

	data() {
    return {
      img: { backArrow },
			loading: true, // Whether loading data from AJAX
			bundles: [], // The bundles
			selected: this.value
		}
	},

	created() {
		this.$api.get("/products/bundles").then(res => {
			this.loading = false;
			this.bundles = res.data;
		});
	},

	methods: {
		setBundle(bundle) {
			this.selected.bundle = bundle;
			this.$emit("input", this.selected);
		},
    changeView() {
      this.$emit("changeView");
    },
		setRenew(renew) {
			this.selected.renew = renew;
			this.$emit("input", this.selected);
    },
        emmitBack() {
      this.$emit("back");
    }
	},

	filters: {
		formatPrice: price => "£" + price.toFixed(2)
	}
}
</script>

<style lang="less" scoped>

</style>
