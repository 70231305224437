import axios from "axios";
import "regenerator-runtime/runtime";
let r = null; // Vue router instance


let defaultOpts = {
	redirect: true, // Always redirect on failure condition
	axios: {
		validateStatus: status => true,
		withCredentials: true
	}
};

// Merge and filter the supplied options through the default
let mergeOpts = opts => Object.assign({}, defaultOpts, opts);

/*
 * If the user is not authenticated on any AJAX request, we want to redirect
 * them to the login page so they can authenticate again instead of having the
 * AJAX requests failing silently. This is why we pass the router object - so
 * we can redirect back to the login page on a failure condition.
 *
 * 401 - Unauthorized: there was no token present anywhere
 * 419 - Token expired: there is a token present but it's no longer valid
 */
let redirect = (enabled, status) => {
	if (enabled) {
		if (status === 401 || status === 419) r.push("/welcome");
		else if (status >= 400 && status < 600) window.alert(status); // Catch user (4xx) and server (5xx) error
	}
};

let methods = {
	async get(url, opts) {
		opts = mergeOpts(opts);
		let res = await axios.get(API_URL + url, opts.axios);
		redirect(opts.redirect, res.status);
		return res;
	},

	async post(url, data, opts) {
		opts = mergeOpts(opts);
		let res = await axios.post(API_URL + url, data, opts.axios);
		redirect(opts.redirect, res.status);
		return res;
	},

	async put(url, data, opts) {
		opts = mergeOpts(opts);
		let res = await axios.put(API_URL + url, data, opts.axios);
		redirect(opts.redirect, res.status);
		return res;
	},

	async delete(url, opts) {
		opts = mergeOpts(opts);
		let res = await axios.delete(API_URL + url, opts.axios);
		redirect(opts.redirect, res.status);
		return res;
	}
};

export default router => {
	r = router; // Set the view router instance
	return methods;
};
