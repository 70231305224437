<template lang="html">
  <section class="user p-4 pb-[73px]">
    <div class="max-w-xl mx-auto">
      <div class="mb-4">
        <p class="text">
          You successfully referred 
          <span class="text-blue-500 font-bold">{{ refereal.tokens }}</span>, 
          refer more people to get 
          <span class="text-blue-500 font-bold">5%</span> off your next order.
        </p>
      </div>

      <div class="mb-4">
        <span class="block mb-2">Your Referral Code:</span>
        <input 
          v-model="refereal.referral_code" 
          :placeholder="refereal.referral_code" 
          class="details-input w-full border border-[#2ab3cb] rounded p-2 text-[#2ab3cb]" 
          id="referralCodeInput" 
          readonly
        />
        <span 
          class="  button mt-2 inline-block bg-[#2ab3cb] rounded text-white py-2 px-4  cursor-pointer" 
          @click="copyToClipboard()"
        >
          Copy
        </span>
      </div>

      <p class="text" v-if="refereal.is_blocked">
        Referral code already entered:
      </p>
      <p class="text" v-else>
        If you got a referral code from somebody, enter it below:
      </p>

      <div class="email mb-4">
        <span v-if="!refereal.is_blocked" class="block mb-2">Referred Code:</span>
        <input 
          v-model="newrefereal.code" 
          class="details-input w-full border border-[#2ab3cb] rounded p-2" 
          :placeholder="refereal.referring_code" 
          :readonly="refereal.is_blocked" 
        />
      </div>

      <div v-if="!refereal.is_blocked" class="flex justify-end mb-4 space-x-2">
        <span 
          class="pill selected button bg-[#2ab3cb] rounded text-white py-2 px-4 rounded-full cursor-pointer" 
          @click="savechanges()"
        >
          Save Changes
        </span>
        <span 
          class="pill button bg-gray-200 rounded text-gray-800 py-2 px-4 rounded-full cursor-pointer" 
          @click="cancel()"
        >
          Cancel
        </span>
      </div>

      <pass-modal v-if="showmodal" :user="user" @close="showmodal = false"></pass-modal>	
    </div>	
  </section>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      processing: false,
      showmodal: false,
      refereal: {
        id: null,
        user_id: null,
        referral_code: null,
        referring_code: null,
        referred_users: [],
        tokens: 0,
        has_ordered: null
      },
      user: {
        name: null,
        email: null,
        id: null
      },
      newrefereal: {
        code: null,
      }
    };
  },

  created() {
    this.$api.get("/user").then(res => {
      this.user = res.data;
    });
    this.$api.get("/referral").then(res => {
      this.refereal = res.data;
      this.loading = false;
    });
  },

  methods: {
    copyToClipboard() {
      const copyText = document.getElementById("referralCodeInput");
      copyText.select();
      document.execCommand("copy");
    },

    savechanges() {
      if (this.newrefereal.code === this.refereal.referral_code) {
        window.alert("You can't use your own code.");
        return;
      }
      if (!this.newrefereal.code) {
        window.alert("Referred code cannot be empty");
        return;
      }
      const strippedString = this.newrefereal.code.replace(/[^\w]/g, '');
      if (!strippedString) {
        window.alert("Referred code cannot be empty");
        return;
      }

      const code = this.newrefereal.code.toString();
      this.$api.post("/referral/updatecode", { refferedCode: code })
        .then(res => {
          if (res.status === 200) {
            window.alert("Referral code updated successfully");
          } else {
            window.alert(`${res.data.message}`);
          }
        })
        .catch(error => {
          window.alert(`${error.response.data.message}`);
        });
    },

    cancel() {
      this.$router.push("/");
    }
  }
}
</script>

<style lang="less" scoped>

</style>
