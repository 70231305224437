// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure responsive scaling for logo and back arrow */
@media (max-width: 640px) {
.login img[data-v-635b641c] {
    width: 30px;
    height: 30px;
}
}
@media (min-width: 641px) {
.login img[data-v-635b641c] {
    width: 40px;
    height: 40px;
}
}
`, "",{"version":3,"sources":["webpack://./src/views/Register.vue"],"names":[],"mappings":"AAAA,sDAAsD;AAEtD;AACE;IACE,WAAA;IACA,YAAA;AAAF;AACF;AAGA;AACE;IACE,WAAA;IACA,YAAA;AADF;AACF","sourcesContent":["\n/* Ensure responsive scaling for logo and back arrow */\n@media (max-width: 640px) {\n  .login img {\n    width: 30px;\n    height: 30px;\n  }\n}\n\n@media (min-width: 641px) {\n  .login img {\n    width: 40px;\n    height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
