<template lang="html">
  <div class="min-h-screen flex items-center justify-center bg-gray-100 pt-4 pb-[73px]">
    <div class="bg-white w-full max-w-md lg:max-w-4xl h-auto lg:flex lg:flex-row rounded-3xl shadow-lg overflow-hidden">

        <!-- Sidebar for Desktop -->
      <Sidebar :img="img" :user="user" />

      <!-- Main Content Section -->
      <div class="flex-1 flex flex-col p-4">
        <!-- Header Section -->
        <MobileHeader :img="img" :user="user" />
        <div class="flex items-center justify-between mb-4">
          <div class="flex flex-col">
            <span class="text-xl font-bold text-gray-900">Support</span>
          </div>
        </div>


        <!-- Support Request Form -->
        <div class="flex-1 overflow-y-auto">
          <div class="bg-white shadow-lg mt-4 rounded-2xl">
            <form @submit.prevent="submitSupportRequest">
              <div class="mb-4">
                <label class="block text-gray-700 font-bold mb-2" for="message">Your Message</label>
                <textarea v-model="supportMessage" id="message" rows="4" class="w-full border border-gray-300 rounded-lg p-2" placeholder="Describe your issue..."></textarea>
              </div>
              <div v-if="responseMessage" class="mt-4 p-2 text-center text-gray-600">
                {{ responseMessage }}
              </div>
              <button type="submit" class="bg-[#2ab3cb] text-white p-2 mb-5 rounded-lg w-full ">
                <div class="text-white text-sm font-bold leading-normal tracking-tight">
                  Submit Support Request
                </div>
              </button>
            </form>
            <div class="text-center">
              <button @click="openWhatsApp" class="bg-green-500 text-white p-2 rounded-lg w-full">
                Chat with Us on WhatsApp
              </button>
            </div>
          </div>
        </div>
        <FooterView />
      </div>
    </div>
  </div>
</template>

<script>
import FooterView from "@/components/Footer.vue";
import logoDark from "@/assets/logo-dark.png";
import Sidebar from "@/components/Sidebar.vue";
import MobileHeader from "../components/MobileHeader.vue";

export default {
  components: {
    FooterView,
    Sidebar,
    MobileHeader
  },
  data() {
    return {
      img: {
        logoDark,
      },
      loading: false,
      supportMessage: '',
      responseMessage: '',
      user: {
        name: null,
        email: null,
      },
    };
  },

  methods: {
    async submitSupportRequest() {
      if (!this.supportMessage.trim()) {
        this.responseMessage = "Please enter a message before submitting.";
        return; // Prevents submission if the message is empty
      }
      try {
        const res = await this.$api.post("/support", { 
          message: this.supportMessage,
          email: this.user.email,
          name: this.user.name
        });
        if (res.status === 200) {
          this.responseMessage = "Your message has been sent successfully!";
          this.supportMessage = ''; // Clear the message after submission
        } else {
          this.responseMessage = "Failed to send your message. Please try again.";
        }
      } catch (error) {
        console.error("Failed to submit support request", error);
        this.responseMessage = "An error occurred. Please try again.";
      } finally {
        this.loading = false;
      }
    },

    openWhatsApp() {
      const message = encodeURIComponent(this.supportMessage || 'I need help with...');
      const whatsappUrl = `https://wa.me/447867493409`;
      window.open(whatsappUrl, '_blank');
    },

    async fetchUserData() {
      try {
        const res = await this.$api.get("/user");
        if (res.status === 200 && res.data) {
          this.user = res.data;
        } else {
          this.redirectToWelcome();
        }
      } catch (error) {
        this.redirectToWelcome();
      }
    },

    redirectToWelcome() {
      this.$router.push("/welcome");
    },
  },

  created() {
    this.fetchUserData();
  },
};
</script>

<style lang="less">
</style>
