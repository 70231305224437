<template lang="html">
<section class="standalone w-full  bg-gray-50 rounded-3xl ">
  <!-- Header -->
  <div class="w-full px-4 py-3 bg-white border-b border-gray-100 flex justify-center items-center gap-2">
    <div class="w-6 h-6 flex flex-wrap items-center justify-center cursor-pointer" @click="emmitBack()">
      <img :src="img.backArrow" class="w-6 h-6 rounded-full" alt="Back">
    </div>
    <div class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
      Basket Summary
    </div>
  </div>

   <loader v-if="loading" name="basket"></loader>
  <div class="p-4 space-y-4"v-else>

    <!-- Payment Method -->
 <div class="bg-white p-4 rounded-lg shadow-sm">
      <div class="flex justify-between items-center mb-3">
        <div class="text-sm font-bold text-[#111826]">Your Payment Methods:</div>
        <button @click="showNewCard = true" class="px-3 py-1.5 text-xs font-bold text-[#081f3f] border rounded-lg shadow-sm">
          Add New
        </button>
      </div>
<div class="overflow-x-auto mb-6">
  <select 
    v-model="selectedCard" 
    class="font-['Manrope'] text-sm mb-2 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb] sm:text-sm">
    <option 
      v-for="(item, index) in cards" 
      :key="index" 
      :value="item" 
      class="font-['Manrope'] text-sm whitespace-normal">
      {{ item.brand.toUpperCase() }} ending in {{ item.last4 }}
    </option>
  </select>
</div>
    </div>
<!-- Voucher -->
<div class="bg-white p-4 rounded-lg shadow-sm">
  <div class="flex justify-between items-center mb-3">
    <div class="text-sm font-bold text-[#111826]">Voucher:</div>
    <button @click="checkvoucher()" class="px-4 py-2 text-xs font-semibold text-white bg-[#081f3f] border rounded-lg shadow-sm hover:bg-[#0a2b49] focus:outline-none focus:ring-2 focus:ring-[#2ab3cb]">
      Apply
    </button>
  </div>
  <div class="overflow-x-auto mb-6">
    <div class="relative">
      <input type="text" v-model="vouchername" class="block w-full px-3 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb]" name="voucher" placeholder="Enter your voucher code">
    </div>
  </div>
</div>

<!-- Gift Code -->
<div class="bg-white p-4 rounded-lg shadow-sm">
  <div class="flex justify-between items-center mb-3">
    <div class="text-sm font-bold text-[#111826]">Gift Codes:</div>
    <button @click="applyGiftCode(giftcodes.giftcode)" class="px-4 py-2 text-xs font-semibold text-white bg-[#081f3f] border rounded-lg shadow-sm hover:bg-[#0a2b49] focus:outline-none focus:ring-2 focus:ring-[#2ab3cb]">
      Apply
    </button>
  </div>
  <div class="overflow-x-auto mb-6">
    <div class="relative">
      <input type="text" v-model="giftcodes.giftcode" class="block w-full px-3 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb]" name="giftcode" placeholder="Enter your gift code">
    </div>
  </div>
</div>

    <!-- Order Summary -->
<div class="bg-white p-4 rounded-lg shadow-sm">
  <div class="text-sm font-bold text-[#111826] mb-2">Order Summary</div>

  <!-- Loop Through Basket Items -->
<div v-for="(item, index) in basket" :key="index" class="flex flex-wrap text-sm border-b pb-2 mb-2">
  <div class="flex justify-between w-full">
    <div>{{ item.load?.name_string }}</div>
    <div class="font-bold text-zinc-900">£{{ (item.load?.price || 0).toFixed(2) }}</div>
  </div>
  <div class="flex justify-between w-full">
    <div>Addons:</div>
    <div class="font-bold text-zinc-900">£{{ item.addons.toFixed(2) }}</div>
  </div>
  <button 
    @click="removeItem(index)" 
    class="bg-red-500 text-white text-xs px-3 py-1 rounded mt-2 hover:bg-red-700 transition">
    Remove
  </button>
</div>

</div>
<div v-if="voucherdata.name" class="flex justify-between text-sm">
  <div>Voucher Applied: {{ voucherdata.name }}</div>
</div>
   <div class="flex flex-wrap flex-col gap-4 p-5">
      <div class="flex flex-wrap items-center gap-2 ">
        <div class="flex flex-wrap items-center justify-center w-5 h-5 bg-gray-50 border border-gray-300 rounded">
          <input type="checkbox" v-model="tc"    class=" w-full h-full rounded focus:ring-2 focus:ring-blue-500" />
        </div>
        <div class="text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
  Terms and Conditions
</div>
<div class="text-zinc-500 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">
  By clicking "Pay Now," you accept our <a href="https://washcycle.co.uk/terms" class="text-blue-500 underline">Terms and Conditions</a>.
</div>
      </div></div>
    <!-- Total Summary for all items -->
    <div class="flex justify-between mt-4 text-sm font-bold">
      <div>Total for all items</div>
      <div class="text-base">£{{ totalPrice.toFixed(2) }}</div>
    </div>
  </div>

  <!-- Footer -->
  <div class="w-full p-4 bg-white border-t border-gray-200 flex flex-wrap gap-4">
    <!-- Pay Now Button -->
 <button 
  @click="trypay" 
  :disabled="!selectedCard || processing|| basket.length === 0||!tc " 
  :class="[
    'flex-grow h-12 px-6 py-3 font-bold rounded-lg shadow-sm',
    (!selectedCard || processing ||  basket.length === 0||!tc) 
      ? 'bg-gray-400 text-gray-500 cursor-not-allowed' 
      : 'bg-[#081F3F] text-white'
  ]">
  Pay Now
</button>

  </div>
  <new-card v-if="showNewCard" @close="close"></new-card>

</section>
</template>


<script>
import NewCard from "../../components/NewCard";
import backArrow from "assets/backarrow.svg";
import Cookies from 'js-cookie';
export default {
	components: {
		NewCard,
	},

	props: [
		"value"
	],

	data() {
		return {
			img: { backArrow },
			vouchername: null,
      totalPrice: null,
        tc:false,
			giftcodes: {
				price: null,
				giftcode: null,	
				used:null			},
			voucherdata:{name:'',voucherenabled:false,value:0},
			loading: true, // Whether loading AJAX data
			cards: [],
				paydetails: {
          price: 0,
				discountedprice: 0,
				card: null,
				basket: [{}],
				pride: 0, //pride stuff
				ordername: null,
				appVersion: 31,
				refereal: false,
				giftcodes: {
				price: null,
				giftcode: null,	
				used:null
				}

			},
			processing:false,
			selectedCard: null,
			basket: this.value,
			showNewCard: false,
		}
	},

	created() {
		this.totalPrice = this.total();
		this.reload();
	},

	methods: {
		reload() {
			this.$api.get("/user/cards").then(res => {
				this.loading = false;
				this.cards = res.data.cards;
			});
		},
		emmitBack() {
			this.$emit("back");
		},
		changeView() {
			this.$emit("changeView");
    },
		
checkvoucher() {
	let voucherword = { word: this.vouchername }
	 this.processing = true;
  this.$api.post("/vouchercheck", voucherword).then(res => {
    if (!res.data.error) {
      let voucherdata = res.data;
      this.voucherdata = voucherdata; // Update voucher data
      this.totalPrice = this.total();  // Recalculate total with voucher applied

			window.alert("Voucher applied successfully");
			
      this.vouchername = null;
      this.processing = false;
    } else {
      window.alert(res.data.error);
      this.voucherdata = { name: '', voucherenabled: false, value: 0 }; // Reset voucher data on failure
      this.vouchername = null;
      this.processing = false;
    }
  });
},
		close() {
      this.showNewCard = false;
      this.showNewAddress = false;
			this.reload();
		},
  	applyGiftCode(giftcode) {
			if (this.paydetails.giftcodes.giftcode && this.paydetails.giftcodes.price <= 0) {
				window.alert("Already applied successfully")
				return

			}
			this.processing = true;
			this.$api.post("/giftcodecheck", { giftcode: giftcode }).then(res => {
				if (!res.data.error) {
					this.giftcodes = res.data
					this.paydetails.giftcodes = res.data
					window.alert("Gift code applied successfully")
					if (this.totalPrice > this.giftcodes.price) {
						// Set paydetails.price to the remainder of price
						this.totalPrice -= this.giftcodes.price;
						// Set paydetails.giftcodes.price to 0
						this.paydetails.giftcodes.used = this.giftcodes.price;
						this.paydetails.giftcodes.price = 0;

					} else if (this.totalPrice < this.giftcodes.price) {
						// Set paydetails.price to 0
						this.paydetails.giftcodes.price -= this.totalPrice;
						this.paydetails.giftcodes.used = this.paydetails.giftcodes.price

						this.totalPrice = 0;
						// Set paydetails.giftcodes.price to the remainder
					} else {
						// If paydetails.price equals this.giftcodes.price
						// Set paydetails.price to 0 and paydetails.giftcodes.price to 0
						this.paydetails.giftcodes.used = this.paydetails.giftcodes.price
						this.totalPrice = 0;
						this.paydetails.giftcodes.price = 0;
						
					}
					this.processing = false;
				}
				else {
					window.alert("gift code not found")
					this.processing = false;

				}
			})
		},
		total() {
  let total = this.basket.reduce((total, item) => {
    const itemTotal = item.load.price + item.addons;
    return total + itemTotal;
  }, 0);

  if (this.voucherdata.name) {
    total -= total * (this.voucherdata.value / 100);
  }

  // Round the total to two decimal places
  return Math.round(total * 100) / 100;
		},
		removeItem(index) {
			this.basket.splice(index, 1);
			this.totalPrice = this.total();
			this.reload()
    },
		trypay() {
			this.paydetails.card = this.selectedCard
			this.paydetails.basket = this.basket
			this.paydetails.price = this.totalPrice
			this.paydetails.voucher = this.voucherdata
			if (Cookies.get('washcycle')) {
				alert("Last order is still processing, please try again in 5 minutes")
				return;
			}
			var inTwentySec = new Date(new Date().getTime() + 0.50 * 6 * 1);
			Cookies.set('washcycle', 'processing', {
				expires: inTwentySec,
			});
				this.processing = true;
				this.$api.post("/payment", this.paydetails,  {
					redirect: false
				}).then(res => {
					this.handleServerResponse(res)

				});
		},
				 paymentsuccess() {
					const now = new Date();
					const transaction_id = `${now.getDate()}${now.getMonth() + 1}${now.getFullYear().toString().slice(-2)}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
					//analitics hooks
					// Enable ecommerce tracking
					// Log a purchase event
					if (document.location.hostname === "washcycle.co.uk" && document.location.pathname === "/order") {
						this.paydetails.basket.forEach(function (item) {
							// Log item details
							gtag('event', 'item_purchase', {
								transaction_id: transaction_id, // Transaction ID. Required.
								item_name: item.order.name, // Product name. Required.
								item_price: item.order.price, // Unit price.
								quantity: 1 // Quantity.
							});
						});
					}
					this.changeView()

		},
handleServerResponse(response) {
    const stripe = Stripe(STRIPE_KEY);
    if (response.error) {
        window.alert(response.error);
        // Show error from server on payment form
    } else if (response.data.error) {
        window.alert(response.data.error);
        this.processing = false;
        // Show error from server on payment form
    } else if (response.data.requires_action) {
        // Use Stripe.js to handle required card action
        stripe.handleCardAction(
            response.data.payment_intent_client_secret
        ).then((result) => {  // Use arrow function here
            if (result.error) {
                window.alert(result.error.message);
                this.processing = false;  // 'this' now refers to the outer scope
            } else {
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server
                this.$api.post("/payment", {
                    payment_intent_id: result.paymentIntent.id,
                    basket:this.paydetails.basket
                }, {
                    redirect: false
                }).then((res) => res.data) // Arrow function
                .then((confirmresult) => {  // Arrow function
                    if (confirmresult.success) {
                        this.processing = false;
                        if (this.voucherdata.singleuse === true) {
                            let voucherword = { word: this.voucherdata.word };
                            this.$api.post("/voucheruse", voucherword);
                        }
                        this.paymentsuccess();
                    } else {
                        this.processing = false;
                        window.alert(confirmresult.error);
                    }
                });
            }
        });
    } else {
        this.processing = false;
        if (this.voucherdata.singleuse === true) {
            let voucherword = { word: this.voucherdata.word };
            this.$api.post("/voucheruse", voucherword);
        }
        this.paymentsuccess();
    }
}

},
}
</script>

<style lang="less" scoped>
</style>
