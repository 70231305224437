// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.new-card[data-v-a39b9988] {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  z-index: 999;
}
section.new-card .container[data-v-a39b9988] {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  box-sizing: border-box;
  min-width: 100%;
  min-height: 100%;
}
section.new-card .background[data-v-a39b9988] {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
section.new-card .dialog[data-v-a39b9988] {
  background-color: #FFF;
  padding: 30px;
  z-index: 11;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-width: 400px;
}
section.new-card .dialog .button[data-v-a39b9988] {
  padding: 14px 20px;
  background-color: #CCC;
  color: #FFF;
  margin-top: 20px;
  text-align: center;
  cursor: default;
  border-radius: 4px;
  font-size: 14px;
}
section.new-card .dialog .button.active[data-v-a39b9988] {
  background-color: #01BAD4;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/NewCard.vue"],"names":[],"mappings":"AACA;EACC,eAAA;EACA,OAAA;EACA,MAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;AAAD;AAPA;EAUE,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;AADF;AAnBA;EAwBE,oCAAA;EAEA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AAHF;AA5BA;EAmCE,sBAAA;EACA,aAAA;EACA,WAAA;EACA,kBAAA;EACA,yCAAA;EAEA,aAAA;EACA,sBAAA;EAEA,gBAAA;AANF;AAtCA;EA+CG,kBAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;AANH;AAQG;EACC,yBAAA;EACA,eAAA;AANJ","sourcesContent":["\nsection.new-card {\n\tposition: fixed;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100vw;\n\theight: 100vh;\n\toverflow: scroll;\n\tz-index: 999;\n\n\t.container {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\n\t\tposition: absolute;\n\t\tleft: 0;\n\t\ttop: 0;\n\t\tpadding: 20px;\n\t\tbox-sizing: border-box;\n\t\tmin-width: 100%;\n\t\tmin-height: 100%;\n\t}\n\n\t.background {\n\t\tbackground-color: rgba(0, 0, 0, 0.5);\n\n\t\tposition: absolute;\n\t\tleft: 0;\n\t\ttop: 0;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tz-index: 10;\n\t}\n\n\t.dialog {\n\t\tbackground-color: #FFF;\n\t\tpadding: 30px;\n\t\tz-index: 11;\n\t\tborder-radius: 4px;\n\t\tbox-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);\n\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\n\t\tmin-width: 400px;\n\n\t\t.button {\n\t\t\tpadding: 14px 20px;\n\t\t\tbackground-color: #CCC;\n\t\t\tcolor: #FFF;\n\t\t\tmargin-top: 20px;\n\t\t\ttext-align: center;\n\t\t\tcursor: default;\n\t\t\tborder-radius: 4px;\n\t\t\tfont-size: 14px;\n\n\t\t\t&.active {\n\t\t\t\tbackground-color: #01BAD4;\n\t\t\t\tcursor: pointer;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
