<template lang="html">
<section @click.self="close" class="fixed inset-0 flex lg:items-center lg:justify-center bg-black bg-opacity-50 z-50 overflow-y-auto px-4 py-6">
  <div class="bg-white rounded-lg shadow-lg w-full max-w-4xl p-4 sm:p-6 relative overflow-y-auto">
    <div class="flex justify-between items-center mb-4">
      <p class="text-lg font-semibold">Add a new address</p>
      <button @click="close" class="text-gray-500 hover:text-gray-700 text-2xl">
        &times;
      </button>
    </div>
    <form @submit.prevent="submit" method="POST" class="space-y-4">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label class="block text-sm font-medium text-gray-700 required">
            Name
          </label>
          <input
            type="text"
            v-model="address.name"
            placeholder="John Smith"
            class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />

          <label class="block text-sm font-medium text-gray-700 required mt-4">
            Phone number
          </label>
          <input
            type="tel"
            v-model="address.phone"
            placeholder="+44 9742 83771"
            class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />

          <label class="flex items-center text-sm font-medium text-gray-700 mt-4">
            <span>what3words</span>
            <span class="ml-2 text-xs font-semibold text-white bg-blue-500 rounded px-2 py-1">NEW</span>
          </label>
          <what3words-autosuggest @click="reconcileAutosuggest">
            <input
              type="text"
              v-model="w3w"
              ref="autosuggest"
              class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </what3words-autosuggest>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700 required">
            Address Line 1
          </label>
          <input
            type="text"
            v-model="address.address_line_1"
            placeholder="13 Greenway Road"
            class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />

          <label class="block text-sm font-medium text-gray-700 required mt-4">
            Address Line 2
          </label>
          <input
            type="text"
            v-model="address.address_line_2"
            placeholder="Rumney"
            class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />

          <label class="block text-sm font-medium text-gray-700 mt-4">
            Address Line 3
          </label>
          <input
            type="text"
            v-model="address.address_line_3"
            class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />

          <label class="block text-sm font-medium text-gray-700 required mt-4">
            City
          </label>
          <input
            type="text"
            v-model="address.city"
            placeholder="Cardiff"
            class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />

          <label class="block text-sm font-medium text-gray-700 mt-4">
            County
          </label>
          <input
            type="text"
            v-model="address.county"
            class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />

          <label class="block text-sm font-medium text-gray-700 required mt-4">
            Postcode
          </label>
          <input
            type="text"
            v-model="address.postcode"
            placeholder="CF3 3HJ"
            class="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      <button
        :class="{
          'bg-green-500 hover:bg-green-600': ready,
          'bg-gray-400': !ready,
        }"
        :disabled="!ready || processing"
        class="w-full text-white font-medium py-2 px-4 rounded-md transition duration-200"
      >
        {{ processing ? "Please wait..." : "Add new address" }}
      </button>
    </form>
  </div>
</section>
</template>


<script>
export default {
  data() {
    return {
      address: {
        name: "",
        phone: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        city: "",
        county: "",
        postcode: "",
      },
      w3w: "",
      processing: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    reconcileAutosuggest() {
      this.w3w = this.$refs.autosuggest.value;
    },
    async submit() {
      if (this.ready) {
        this.processing = true;
        try {
          if (this.w3w) {
            const res = await what3words.api.convertToCoordinates(this.w3w);
          }
        } catch (err) {
          console.error(err);
        }
        this.$api
          .post("/user/address", this.address, { redirect: false })
          .then((res) => {
            this.processing = false;
            if (res.status === 201) this.close();
            else window.alert(res.data);
          });
      }
    },
  },
  computed: {
    ready() {
      if (this.processing) return false;

      const required = [
        "name",
        "phone",
        "address_line_1",
        "address_line_2",
        "city",
        "postcode",
      ];
      return required.every((field) => this.address[field]?.trim().length > 1);
    },
  },
};
</script>

<style scoped>
.required::after {
  content: " *";
  color: #e74c3c;
}
</style>
