<template lang="html">
<section class="forgot-password min-h-screen flex items-center justify-center bg-gray-100">
  <div class="relative bg-white rounded-3xl shadow-lg p-8 w-full max-w-md sm:max-w-lg lg:max-w-xl">
    <!-- Back Arrow and Empty Space -->
    <div class="flex justify-between items-center mb-6">
      <!-- Back Arrow -->
      <div class="w-6 h-6 flex justify-center items-center">
        <img :src="backArrow" alt="Back Arrow" class="w-full h-full object-contain cursor-pointer" @click=loginClick />
      </div>
      <!-- Logo -->
      <div class="w-10 h-10">
        <img :src="logoDark" alt="Logo" class="w-full h-full object-contain cursor-pointer" @click=home />
      </div>
    </div>

    <!-- Main Content -->
    <div class="text-center mb-8">
      <div class="text-zinc-900 text-2xl font-bold font-['Manrope'] leading-9 tracking-tight">Forgot Password?</div>
      <div class="text-zinc-700 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mt-2">
        Enter your email address below and we will send you a link to reset your password.
      </div>
    </div>

    <div class="mb-6">
      <label class="block text-zinc-900 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mb-2" for="email">Email Address</label>
      <input id="email" type="email" placeholder="Enter your email address" class="w-full px-3 py-2 bg-white rounded-xl border border-[#d2d6db] text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight"
			 v-model="user.email"
			/>
    </div>

    <div class="mb-6">
      <button
        class="w-full px-6 py-3 rounded-lg shadow border text-sm font-bold font-['Manrope'] leading-normal tracking-tight"
        :class="{
          'bg-gray-100 border-gray-100 text-zinc-300': !resetReady,
          'bg-[#2AB3CB] border-[#2AB3CB] text-white': resetReady
        }"
        :disabled="!resetReady"
        @click="submitForgotPassword"
      >
        Reset Password
      </button>
    </div>

    <div class="text-center text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight" >
      Remember your password? 
      <a  class="text-[#2ab3cb] text-xs font-bold font-['Manrope'] leading-tight" @click=loginClick>Sign In</a>
    </div>
  </div>
</section>
</template>

<script>
const DEV = process.env.NODE_ENV !== "production";
import logoDark from "../assets/logo-dark.png"
import backArrow from "../assets/icons/blackArrow.png"

export default {
  data() {
    return {
      logoDark,
      backArrow,
      user: {
        email: "",
      },
      warning: null,
    };
  },
  methods: {
    home() {
      this.$router.push("/welcome");
    },
    loginClick() {
      this.$router.push("/login");
    },
    resetClick() {
      this.$router.push("/reset");
    },
    submitForgotPassword() {
      this.warning = null;
      if (this.resetReady) {
        this.processing = true;
        this.$api.post("/user/forgot", this.user, { redirect: false }).then(res => {
          this.processing = false;
          if (res.status !== 201) this.warning = res.data;
          else alert("Please check your inbox for the reset link.");
        });
      }
    },
  },
  computed: {
    resetReady() {
      let { user } = this;
      return user.email.length > 4;
    },
  },
};
</script>

<style lang="less" scoped>
/* Ensure responsive scaling for logo and back arrow */
@media (max-width: 640px) {
  .forgot-password img {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 641px) {
  .forgot-password img {
    width: 40px;
    height: 40px;
  }
}
</style>
