<template lang="html">
  <div class="w-full max-w-full  bg-white rounded-3xl relative">
    <!-- Header Section -->
    <div class="w-full px-4 py-3 flex-wrap bg-white border-b border-gray-100 flex justify-center items-center gap-2">
      <!-- Back Icon Placeholder -->
      <div class="w-6 h-6 flex items-center justify-center cursor-pointer" @click="redirectToHome">
        <!--  with actual back icon -->
        <img :src="img.backArrow" class="w-6 h-6 rounded-full">
      </div>
      <div class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Select Your Order Type
      </div>
      
    </div>

    <!-- Options Section -->
    <div class="w-full px-4 flex flex-col gap-3 flex-grow">
      <!-- Standalone Option -->
      <div @click="setCategory('standalone')" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected === 'standalone', 'border-gray-200 border': selected !== 'standalone'}" class="p-3 rounded-xl flex flex-col gap-3 items-start flex-grow min-h-[100px] cursor-pointer relative">
        <div class="border-gray-200 border w-10 h-10 bg-[#f2f4fd] rounded-full flex items-center justify-center">
          <!-- Replace with actual standalone icon -->
          <img :src="img.standalone" class="w-6 h-6 rounded-full">
        </div>
        <div class="flex-grow">
          <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Standalone</div>
          <div class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">A single Laundry Job</div>
        </div>
        <input type="radio" :checked="selected === 'standalone'" class="absolute top-3 right-3">
      </div>

      <!-- Express Standalone Option -->
      <div @click="setCategory('express')" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected === 'express', 'border-gray-200 border': selected !== 'express'}" class="p-3 rounded-xl flex flex-col gap-3 items-start flex-grow min-h-[100px] cursor-pointer relative">
        <div class="border-gray-200 border  w-10 h-10 bg-[#f2f4fd] rounded-full flex items-center justify-center">
          <!-- Replace with actual express icon -->
          <img :src="img.express" class="w-6 h-6 rounded-full">
        </div>
        <div class="flex-grow">
          <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Express Standalone</div>
          <div class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">Guaranteed same day return</div>
        </div>
        <input type="radio" :checked="selected === 'express'" class="absolute top-3 right-3">
      </div>

      <!-- Bundle Option -->
               <div @click="setCategory('bundle')" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected === 'bundle', 'border-gray-200 border': selected !== 'bundle'}" class="p-3 rounded-xl flex flex-col gap-3 items-start flex-grow min-h-[100px] cursor-pointer relative">
            <div class="w-10 h-10 bg-[#f2f4fd] rounded-full flex items-center justify-center">
              <!-- Replace with actual bundle icon -->
              <img :src="img.bundle" class="w-6 h-6 rounded-full">
            </div>
            <div class="flex-grow">
              <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Bundle</div>
              <div class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">A discounted subscription</div>
            </div>
            <input type="radio" :checked="selected === 'bundle'" class="absolute top-3 right-3">
          </div>

      <!-- Custom Bundle Option -->
      <div @click="setCategory('custom')" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected === 'custom', 'border-gray-200 border': selected !== 'custom'}" class="p-3 rounded-xl flex flex-col gap-3 items-start flex-grow min-h-[100px] cursor-pointer relative">
        <div class="border-gray-200 border  w-10 h-10 bg-[#f2f4fd] rounded-full flex items-center justify-center">
          <!-- Replace with actual custom bundle icon -->
          <img :src="img.custom" class="w-6 h-6 rounded-full">
        </div>
        <div class="flex-grow">
          <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Custom Bundle</div>
          <div class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">A custom discounted bundle</div>
        </div>
        <input type="radio" :checked="selected === 'custom'" class="absolute top-3 right-3">
      </div>
        <div  @click="changeView"  class="flex-grow h-12 px-6 py-3 bg-[#2ab3cb] rounded-lg shadow-sm justify-center items-center gap-3 inline-flex overflow-hidden  mt-4 lg:mt-2">
      <div class="text-black text-sm font-bold font-['Manrope'] leading-normal tracking-tight    *:
      cursor-pointer">NEXT</div>
    </div>
    </div>
  </div>
</template>


<script>
import standalone from "assets/tshirt.svg"
import express from "assets/alarm.svg";
import bundle from "assets/bundle.svg";
import custom from "assets/custombundle.svg";
import basket from "assets/basket.svg";
import backArrow from "assets/backarrow.svg";

export default {
	props: [
		"value"
	],

	data() {
    return {
			img: { standalone, bundle, express, custom,basket,backArrow },
			selected: this.value,
		}
	},
 
  methods: {
   changeView() {
      switch (this.selected) {
        case 'bundle':
          this.$emit("changeBundle");
          break;
        case 'standalone':
          this.$emit("changeStandalone");
          break;
        case 'express':
          this.$emit("changeStandalone");
          break;
        case 'custom':
          this.$emit("changeStandalone");
          break;
        case 'redeem':
          this.$emit("changeRedeem");
          break;
        default:
          console.warn(`Unknown category: ${this.selected}`);
      }
    },
		 redirectToHome() {
      this.$router.push("/");
    },
		setCategory(id) {
      this.selected = id;
			this.$emit("input", id);
		}
	}
}
</script>
