<template lang="html">
  <div class="w-full max-w-md lg:max-w-4xl h-auto relative bg-white rounded-3xl overflow-hidden p-4">
      
     
      
      <!-- Thank You Message -->
      <div class="text-center mb-6">
        <div class="text-xl font-bold text-zinc-900">Thank you for your order!</div>
        <div class="text-xs text-zinc-600 mt-2">We’ll notify you via email, SMS, and app notifications at every step of your order.</div>
      </div>
      
      <!-- Order Details Section -->
      <div class="w-full flex flex-col items-center justify-center gap-3">
        <div class="w-[64px] h-[64px] bg-[#2ab3cb]/20 rounded-2xl flex justify-center items-center mb-3">
          <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="5" width="98" height="98" rx="25" fill="white"/>
            <rect x="5" y="5" width="98" height="98" rx="25" stroke="#2AB3CB" stroke-width="10"/>
            <g clip-path="url(#clip0_1637_5811)">
              <path d="M52.9387 58.6934L56.7041 62.4588L79.2801 39.8828L83.0507 43.6534L56.7041 70.0001L39.7334 53.0294L43.5041 49.2588L49.1707 54.9254L52.9387 58.6908V58.6934ZM52.9441 51.1521L66.1494 37.9441L69.9094 41.7041L56.7041 54.9121L52.9441 51.1521ZM45.4054 66.2321L41.6374 70.0001L24.6667 53.0294L28.4374 49.2588L32.2054 53.0268L32.2027 53.0294L45.4054 66.2321Z" fill="#081F3F"/>
            </g>
            <defs>
              <clipPath id="clip0_1637_5811">
                <rect width="64" height="64" fill="white" transform="translate(22 22)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <!-- Back to Home Button -->
      <div @click="back" class="bg-[#2ab3cb] rounded-lg text-sm font-bold text-black py-3 px-6 mt-4 text-center cursor-pointer">
        Back to home
      </div>
    </div>
</template>


<script>
const DEV = process.env.NODE_ENV !== "production";
export default {

 data() {
		return {
     
       impact: {
         trees:null,
         carbonOffset:null,
         },
		}
	},
created(){

this.$api.get("/user/buyimpact").then(res => {


})


        
},
  methods: {
    back(){
      	if (!DEV){
			 window.dataLayer = window.dataLayer || [];
 			 window.dataLayer.push({
 			'event': 'Order Processed'
 			});}
      this.$router.push("/")
    }

  }
};
</script>

<style lang="less" scoped>

</style>
