<template lang="html">
  <div class="w-full max-w-md lg:max-w-4xl h-auto relative bg-white rounded-3xl overflow-hidden p-4">
     <div class="w-full px-4 py-3 flex-wrap  bg-white border-b border-gray-100 flex justify-center items-center gap-2">
      <!-- Back Icon Placeholder -->
      <div class="w-6 h-6 flex flex-wrap items-center justify-center cursor-pointer" @click="emmitBack">
        <!--  with actual back icon -->
        <img :src="img.backArrow" class="w-6 h-6 rounded-full">
      </div>
      <div class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Select Laundry Type
      </div>
      
    </div>
    <div class="h-12 flex  flex-wrap justify-start items-start mt-5 mb-4">
      <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Choose a Laundry Load Type</div>
      <div class="text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">Choose a type that includes tumble drying or you can choose to line dry at home</div>
    </div>

    <!-- Options Section -->
    <div class="flex  flex-wrap gap-4">
      <!-- Clothes Option -->
      <div @click="setType('clothes')" :class="{'bg-[#2ab3cb border-[#2ab3cb]': type === 'clothes', 'border-gray-200': type !== 'clothes'}" class="w-full h-[70px]  rounded-xl border flex items-center justify-between p-4 cursor-pointer relative">
        <div>
          <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Clothes</div>
          <div class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">Includes tumble drying</div>
        </div>
 <input type="radio" :checked="type === 'clothes'" class="absolute top-3 right-3">
      </div>

      <!-- Towels, Sheets Option -->
      <div @click="setType('towels')" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': type === 'towels', 'border-gray-200': type !== 'towels'}" class="w-full h-[70px]  rounded-xl border flex items-center justify-between p-4 cursor-pointer relative">
        <div>
          <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Towels, Sheets</div>
          <div class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">Includes tumble drying</div>
        </div>
 <input type="radio" :checked="type === 'towels'" class="absolute top-3 right-3">
      </div>

      <!-- Duvets, Pillows Option -->
      <div @click="setType('duvets')" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': type === 'duvets', 'border-gray-200': type !== 'duvets'}" class="w-full h-[70px]  rounded-xl border flex items-center justify-between p-4 cursor-pointer relative">
        <div>
          <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Duvets, Pillows</div>
          <div class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">Includes tumble drying</div>
        </div>
 <input type="radio" :checked="type === 'duvets'" class="absolute top-3 right-3">
      </div>

      <!-- Wash, Spin & Fold Option -->
<div @click="setType('wash_spin_fold')" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': type === 'wash_spin_fold', 'border-gray-200': type !== 'wash_spin_fold'}" class="w-full h-[70px]  rounded-xl border flex items-center justify-between p-4 cursor-pointer relative">
  <div class="flex flex-wrap flex-col">
    <div class="flex  flex-wrap items-center">
      <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Wash, Spin & Fold
      </div>
      <div class="w-[78px] h-[21px] ml-2 bg-[#16b364] rounded-[20px] flex items-center justify-center">
        <div class="text-white text-[10px] font-light font-['Manrope'] leading-none tracking-tight">Eco-Friendly</div>
      </div>
    </div>
    <div class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">
      Not Tumble Dried - For line drying at home
    </div>
  </div>
  <input type="radio" :checked="type === 'wash_spin_fold'" class="absolute top-3 right-3">
</div>
        </div>
      </div>
</template>

<script>
import Calculator from "../../components/Calculator";
import backArrow from "assets/backarrow.svg";
export default {

components: {
	
		Calculator
	},

	props: [
		
		"value"
	],

	data() {
    return {
      img: { backArrow },
			type: this.value,
			showCalculator: false
			
		}
	},

	methods: {
		setType(type) {
			this.type = type;
			this.$emit("input", this.type);
			
			
		},
    emmitBack() {
      this.$emit("back");
    }
	}
}
</script>
<style lang="less">

</style>