<template lang="html">
<section class="standalone">	

<type-view  @back="stepback" v-model="selected.type"></type-view>

	<content-view v-model="selected.whites"></content-view>
	
	<div v-if="showSoftener" class="header">Choose a fabric softener for your laundry</div>
	<div v-if="showSoftener" class="options">
		<div class="option" v-for="softener in softeners" :class="{ selected: selected.softener.type === softener.type }" @click="setSoftener(softener)">
			<div class="name">{{ softener.name }}</div>
			<div class="price">{{ softener.price | formatPrice }}</div>
		</div>
	</div>
<div class="flex justify-center items-center gap-3">
        <div  @click="changeView"  class="flex-grow h-12 px-6 py-3 bg-[#2ab3cb] rounded-lg shadow-sm justify-center items-center gap-3 inline-flex overflow-hidden  mt-4 lg:mt-2">
      <div class="text-black text-sm font-bold font-['Manrope'] leading-normal tracking-tight    *:
      cursor-pointer">NEXT</div>
    </div></div>
</section>
</template>

<script>
import ContentView from "./Content";
import TypeView from "./Type";
import FeatherView from "./Feather";
import PreferencesView from "./Preferences"

export default {
	components: {
		ContentView,
		TypeView,
		FeatherView,
		PreferencesView,
		
	},

	props: [
		"value"
	],

	data() {
		return {
			loading: true, // Whether loading AJAX data
			loads: [], // Array of standalone loads
			clothesLoads: [],
			duvetsLoads: [],
			mixed:[],
			showSoftener: false,
			towelLoads: [],			
			selected: this.value,

			showCalculator: false
		}
	},

	methods: {
		changeView() {
			this.$emit("changeView");
    },
		setLoad(load) {
			this.selected.load = load;
			this.$emit("input", this.selected);
		  
		},
stepback() {
     this.$emit("back");
    },
		setSoftener(softener) {
			this.selected.softener = softener;
			this.$emit("input", this.selected);
		}
	},
	

	filters: {
		formatPrice: price => "£" + price.toFixed(2)
	}
}
</script>
