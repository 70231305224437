<template lang="html">
  <section class="flex items-center justify-center min-h-screen bg-[#081f3f] ">
    <div class="w-full max-w-md bg-[#081f3f] rounded-3xl relative overflow-hidden flex flex-col items-center p-6">
      
      <!-- Image -->
      <div class="w-28 h-28 bg-white rounded-2xl border-8 border-[#2ab3cb] flex items-center justify-center mb-6 p-2">
        <img class="w-15 h-15 object-cover rounded-2xl" :src="logoDark" alt="Welcome Image" />
      </div>

      <!-- Welcome Text -->
      <div class="text-center text-[#fdfdfd] mb-6">
        <h1 class="text-2xl font-bold font-['Manrope'] leading-10 tracking-tight mb-2">
          Welcome to <br /> Wash Cycle!
        </h1>
        <p class="text-zinc-100 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">
          Thanks for Registering!
        </p>
      </div>

      <!-- Button -->
      <div class="mb-8">
        <button class="px-6 py-3 bg-[#2ab3cb] rounded-lg shadow text-white text-sm font-bold font-['Manrope'] leading-normal tracking-tight"
				@click="home">
          Get Started
        </button>
      </div>
    </div>
  </section>
</template>



<script>
const DEV = process.env.NODE_ENV !== "production";
import logoDark from "../assets/logo-dark.png"
export default {
	data() {
		return {
			logoDark,
		}
	},

	methods: {
		home() {
			this.$router.push("/");
		},
	},

	created() {
		let existingMetaTag = document.querySelector('meta[name="description"]');
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Signup";
			existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff! - Signup");
	},
}
</script>

<style lang="less" scoped>

</style>
