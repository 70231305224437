<template>
  <section class="kidsclothes">
    <div class="w-full px-4 py-3 bg-white border-b border-gray-100 flex justify-center items-center gap-2">
      <div class="w-6 h-6 flex flex-wrap items-center justify-center cursor-pointer" @click="emmitBack">
        <img :src="img.backArrow" class="w-6 h-6 rounded-full" alt="Back">
      </div>
      <div class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Select Laundry Preferences
      </div>
    </div>

    <div class="h-12 flex flex-wrap justify-start items-start mt-5 mb-7">
      <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Order Preferences
      </div>
      <div class="text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">
        Please select from the following options. Note that premium options may increase the price.
      </div>
    </div>

    <preferences-view v-model="preferencesTotal" :hide-drying="hideDrying"></preferences-view>

    <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
      Additional Options
    </div>

    <div class="flex flex-wrap flex-col gap-4 p-5">
      <!-- Children's Clothes Option -->
      <div class="flex flex-wrap items-center gap-2 ">
        <div class="flex flex-wrap items-center justify-center w-5 h-5 bg-gray-50 border border-gray-300 rounded">
          <input type="checkbox" v-model="kidsClothes"    class=" w-full h-full rounded focus:ring-2 focus:ring-blue-500" />
        </div>
        <div class="text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
          Children's clothes?
        </div>
        <div class="text-zinc-500 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">
          If your laundry includes children's clothes (under 10 years old) there is a £2 surcharge.
        </div>
      </div>

      <!-- Own Bag Discount -->
      <div class="flex flex-wrap items-center gap-2">
        <div class="flex flex-wrap items-center justify-center w-5 h-5 bg-gray-50 border border-gray-300 rounded">
          <input type="checkbox" v-model="ownBag"   class=" w-full h-full rounded focus:ring-2 focus:ring-blue-500" />
        </div>
        <div class="text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
          Own bag discount
        </div>
        <div class="text-zinc-500 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">
          We recommend that customers supply laundry in their own kitbag or IKEA bag to reduce our plastic waste and receive a 20p discount.
        </div>
      </div>

      <div @click="changeView" class="flex-grow h-12 px-6 py-3 bg-[#2ab3cb] rounded-lg shadow-sm justify-center items-center gap-3 inline-flex overflow-hidden mt-4 lg:mt-2">
        <div class="text-black text-sm font-bold font-['Manrope'] leading-normal tracking-tight cursor-pointer">NEXT</div>
      </div>
    </div>
  </section>
</template>

<script>
import PreferencesView from "./Preferences";
import backArrow from "assets/backarrow.svg";

export default {

  data() {
    return {
      img: {
        backArrow
      },
      selected: this.value,
      preferencesTotal: null,
      ownBag: false,
      kidsClothes: false,
    };
  },
  components: {
    PreferencesView
  },
  props: ["hideDrying","value"],
  created() {
   this.updatePreferencesView() 
},
  methods: {

    changeView() {
      this.emmitOwnBag()
      this.emmitkidsClothes()
      this.emmitPrefrences();
      this.$emit("changeView");
    },
    emmitBack() {
      this.$emit("back");
    },
    	emmitOwnBag() {
			this.selected.ownBag = this.ownBag;
      this.$emit("input", this.selected);
      
    },
    	emmitkidsClothes() {
			this.selected.kidsClothes = this.kidsClothes;
			this.$emit("input", this.selected);
			
			
    },
      emmitPrefrences() {
			this.selected.preferencesTotal = this.preferencesTotal;
			this.$emit("input", this.selected);
			
			
		},
    updatePreferencesView() {
      this.$forceUpdate(); 
    }
  },

  computed: {

  }
};
</script>

<style lang="less" scoped>
</style>
