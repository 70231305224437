// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.kidsclothes[data-v-3affb5de] {
  font-size: 14px;
}
section.kidsclothes li[data-v-3affb5de]:not(:last-of-type) {
  margin-bottom: 12px;
}
section.kidsclothes .kidsclothes[data-v-3affb5de] {
  cursor: default;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}
section.kidsclothes .kidsclothes.active[data-v-3affb5de] {
  background-color: rgba(46, 204, 113, 0.1);
  color: #27ae60;
}
section.kidsclothes .kidsclothes span[data-v-3affb5de] {
  margin-left: 10px;
  display: inline-block;
  width: 90%;
}
section.kidsclothes .kidsclothes input[data-v-3affb5de] {
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/views/Order/KidsClothes.vue","webpack://./KidsClothes.vue"],"names":[],"mappings":"AACA;EACE,eAAA;ACAF;ADDA;EAII,mBAAA;ACAJ;ADJA;EAQI,eAAA;EACA,aAAA;EACA,sCAAA;EACA,kBAAA;EAEA,6CAAA;ACFJ;ADII;EACE,yCAAA;EACA,cAAA;ACFN;ADfA;EAqBM,iBAAA;EACA,qBAAA;EACF,UAAA;ACHJ;ADpBA;EAyBc,kBAAA;ACFd","sourcesContent":["\nsection.kidsclothes {\n  font-size: 14px;\n\n  li:not(:last-of-type) {\n    margin-bottom: 12px;\n  }\n\n  .kidsclothes {\n    cursor: default;\n    padding: 20px;\n    background-color: rgba(0, 0, 0, 0.025);\n    border-radius: 4px;\n\n    transition: background-color 0.3s, color 0.3s;\n\n    &.active {\n      background-color: rgba(46, 204, 113, 0.1);\n      color: rgba(39, 174, 96, 1);\n    }\n\n    span {\n      margin-left: 10px;\n      display: inline-block;\n    width: 90%;\n    }\n    input{    position: relative;\n   }\n  }\n}\n","section.kidsclothes {\n  font-size: 14px;\n}\nsection.kidsclothes li:not(:last-of-type) {\n  margin-bottom: 12px;\n}\nsection.kidsclothes .kidsclothes {\n  cursor: default;\n  padding: 20px;\n  background-color: rgba(0, 0, 0, 0.025);\n  border-radius: 4px;\n  transition: background-color 0.3s, color 0.3s;\n}\nsection.kidsclothes .kidsclothes.active {\n  background-color: rgba(46, 204, 113, 0.1);\n  color: #27ae60;\n}\nsection.kidsclothes .kidsclothes span {\n  margin-left: 10px;\n  display: inline-block;\n  width: 90%;\n}\nsection.kidsclothes .kidsclothes input {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
