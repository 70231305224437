// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.footer[data-v-b5fd4804] {
  border-top: solid 1px #DDD;
  font-size: 12px;
  padding: 40px 0;
  margin-top: 50px;
  text-align: center;
}
section.footer .wrapper-full[data-v-b5fd4804] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.footer img[data-v-b5fd4804] {
  width: 50px;
  height: auto;
  margin-bottom: 20px;
}
section.footer p[data-v-b5fd4804] {
  max-width: 600px;
  line-height: 1.6;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.vue","webpack://./Footer.vue"],"names":[],"mappings":"AACA;EAEC,0BAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;ACDD;ADLA;EASE,aAAA;EACA,sBAAA;EACA,mBAAA;ACDF;ADVA;EAeE,WAAA;EACA,YAAA;EACA,mBAAA;ACFF;ADfA;EAqBE,gBAAA;EACA,gBAAA;ACHF","sourcesContent":["\nsection.footer {\n\n\tborder-top: solid 1px #DDD;\n\tfont-size: 12px;\n\tpadding: 40px 0;\n\tmargin-top: 50px;\n\ttext-align: center;\n\n\t.wrapper-full {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t}\n\n\timg {\n\t\twidth: 50px;\n\t\theight: auto;\n\t\tmargin-bottom: 20px;\n\t}\n\n\tp {\n\t\tmax-width: 600px;\n\t\tline-height: 1.6;\n\t}\n}\n","section.footer {\n  border-top: solid 1px #DDD;\n  font-size: 12px;\n  padding: 40px 0;\n  margin-top: 50px;\n  text-align: center;\n}\nsection.footer .wrapper-full {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\nsection.footer img {\n  width: 50px;\n  height: auto;\n  margin-bottom: 20px;\n}\nsection.footer p {\n  max-width: 600px;\n  line-height: 1.6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
