<template lang="html">
<section class="w-full">
  <!-- Header for Back Arrow and Title -->
  <div class="w-full px-4 py-3 bg-white border-b border-gray-100 flex justify-center items-center gap-2">
    <div class="w-6 h-6 flex flex-wrap items-center justify-center cursor-pointer" @click="emmitBack">
      <img :src="img.backArrow" class="w-6 h-6 rounded-full" alt="Back">
    </div>
    <div class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
      Select Pickup Slot
    </div>
  </div>

  <div class=" mt-5 mb-7">
    <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
      Select your pickup slot
    </div>
    <div class="text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">
      Please select the time and date you’d like us to collect your laundry. View available slots below.
    </div>
  </div>

  <!-- Warning for Disabled Slots -->
  <div class="warning" v-if="limit">Due to our carrying capacity, slots with fewer than {{ limit > maxSpaces ? maxSpaces : limit }} {{ limit === 1 ? "space" : "spaces" }} have been disabled</div>

  <!-- Loading State -->
  <loader v-if="loading" name="collection slots"></loader>

  <div class="container" v-else>
    <!-- Header for Choosing Date -->
    <div class="text-[#111826] text-base font-bold font-['Manrope'] leading-7 tracking-tight mb-4">
      Choose Day
    </div>

 <!-- Day Squares -->
<div class="overflow-x-auto mb-6">
  <select v-model="daySelected" class="font-['Manrope'] text-sm mb-2 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb] sm:text-sm">
    <option v-for="day in parseDays" :key="day.id" :value="day" class="font-['Manrope'] text-sm whitespace-normal">
      {{ day.relative }} 
    </option>
  </select>
</div>



    <!-- Slot Pills (only show when a day is selected) -->
<div v-if="daySelected" class="mb-6">
  <div class="text-[#111826] text-base font-bold font-['Manrope'] leading-7 tracking-tight mb-4">
    {{ selected.relative }} Slot
  </div>

  <div class="flex full-w justify-center flex-wrap gap-2">
<div
  v-for="slot in daySelected.slots"
  :key="slot.start"
  :class="{
    'bg-[#081f3f] text-white': slot.start == selected.start,  // Apply bg-[#081f3f] when the slot is selected
    'bg-gray-300 text-gray-500 line-through': slot.disabled  // Disabled slot color
  }"
  class="py-2 px-4 border-gray-200 border rounded-full text-sm font-medium cursor-pointer"
  @click="setSlot(slot)">
  {{ slot.time_string }}
</div>
  </div>
</div>

  </div>

  <!-- Additional Information -->
  <div class="text-zinc-700 text-center text-xs font-medium font-['Manrope'] leading-tight tracking-tight" v-if="!loading && !isUpgraded">
    All laundry orders will be delivered between <b>13:00 - 18:00</b> on the day they are collected.<br><b> Any orders 14Kg+ in size will possibly require a next day return.</b>
  </div>
  <div class="text-zinc-700 text-center text-xs font-medium font-['Manrope'] leading-tight tracking-tight" v-if="!loading">
    Please note we only come to Penarth and St Fagans once a day between 10-1pm, so if you're in either of these areas, please bare this in mind when choosing a collection slot. All orders to these areas will have a next day return.
  </div>
  <div class="text-zinc-700 text-center text-xs font-medium font-['Manrope'] leading-tight tracking-tight" v-if="!loading && isUpgraded">
    All orders will be returned the following day in the same time slot.
  </div>

  <!-- NEXT Button -->
<div 
  @click="changeView" 
  class="w-full flex-grow h-12 px-6 py-3 bg-[#2ab3cb] rounded-lg shadow-sm justify-center items-center gap-3 inline-flex overflow-hidden mt-4 lg:mt-2"
  :class="{ 'cursor-not-allowed': selected.start === null, 'bg-gray-400': selected.start === null }" 
  :style="{ pointerEvents: selected.start === null ? 'none' : 'auto' }">
  <div class="text-black text-sm font-bold font-['Manrope'] leading-normal tracking-tight cursor-pointer">NEXT</div>
</div>

</section>
</template>

<script>
import backArrow from "assets/backarrow.svg";
export default {
  props: [
    "value",
    "limit",
    "isUpgraded"
  ],

  data() {
    return {
      img: {
        backArrow
      },
      loading: true, // Whether loading AJAX data
      days: [], // All the slot information for different days
			selected: this.value,
			daySelected: null,
			reloadSeconds: 30,
      maxSpaces: 5 // NOTE: This should not be hardcoded, fix it later
    }
  },

  created() {
    this.reload();
  },

  watch: {
    limit(val) {
      this.reset();
    }
  },

  methods: {
    // Change view (next step)
    changeView() {
      this.$emit("changeView");
    },

    // Emit back event
    emmitBack() {
      this.$emit("back");
    },

    // Set the selected slot for the day
		setSlot(slot, day) {
			if (!slot.disabled) {
				this.selected = slot;
        this.$emit("input", slot);
      }
    },

    // Reset the selection
    reset() {
      this.selected = {
        start: null,
        end: null
      };
      this.$emit("input", this.selected);
    },

    // Reload data
    reload() {
      this.$api.get("/slots?days=14", {
        params: {
          user: this.user
        }
      }).then(res => {
        this.loading = false;
        this.days = res.data;

        window.setTimeout(() => this.reload(), this.reloadSeconds * 1000);
      });
    },


    // Select a slot
    selectSlot(slot) {
      if (!slot.disabled) {
        this.selectedSlot = slot;
        // Handle further actions here when a slot is selected (like booking)
      }
    }
  },

  computed: {
    parseDays() {
      let clone = obj => JSON.parse(JSON.stringify(obj));
      let days = clone(this.days);

      days.map(day => {
        day.slots.map(slot => {
          if (slot.spaces < this.limit && slot.spaces !== this.maxSpaces) slot.disabled = true;
          return slot;
        });

        return day;
      });

      return days;
    }
  }
}
</script>


