// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Add any component-specific styles here */
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.vue"],"names":[],"mappings":";AAiCA,2CAAA","sourcesContent":["    <template>\n      <div class=\"hidden lg:block lg:w-1/3 bg-gray-100 p-6 rounded-l-3xl\">\n        <div class=\"flex flex-col items-center\">\n          <div class=\"flex items-center justify-between w-full mb-6\">\n            <img :src=\"img.logoDark\" class=\"w-14 h-14 rounded-lg\" alt=\"Logo\">\n          </div>\n          <div class=\"mt-5 text-center\">\n            <p class=\"text-xl font-bold text-gray-900\">Hi {{ user.name }}</p>\n            <p class=\"text-sm text-gray-600\">Welcome to your Wash Cycle.</p>\n          </div>\n        </div>\n      </div>\n    </template>\n    \n    <script>\n    export default {\n      name: 'Sidebar',\n      props: {\n        img: {\n          type: Object,\n          required: true\n        },\n        user: {\n          type: Object,\n          required: true\n        }\n      }\n    }\n    </script>\n    \n    <style scoped>\n    /* Add any component-specific styles here */\n    </style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
