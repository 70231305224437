<template lang="html">
  <section class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="w-full max-w-4xl bg-white rounded-3xl shadow-lg flex flex-col lg:flex-row">
      <!-- Image -->
      <img class="w-full h-72 lg:w-1/2 lg:h-auto object-cover lg:rounded-l-3xl" :src="welcomeImage" alt="Welcome Image" />

      <!-- Content -->
      <div class="flex flex-col p-6 lg:p-12 lg:w-1/2 lg:items-start">
        <!-- Text -->
        <div class="mb-6">
          <h1 class="text-zinc-900 text-3xl font-bold font-['Manrope'] leading-9 tracking-tight">Welcome to Wash Cycle</h1>
          <p class="text-zinc-700 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mt-2">
            Cardiff’s highest rated laundry service. Let’s get your washing done with Wash Cycle.
          </p>
        </div>

        <!-- Buttons -->
        <div class="flex flex-col gap-4 mb-6">
          <!-- Email Button -->
          <button class="w-full max-w-md mx-auto px-6 py-3 bg-[#2ab3cb] rounded-lg shadow text-center text-white text-sm font-bold font-['Manrope'] leading-normal tracking-tight"@click="registerClick">
            Register with Email Address
          </button>

          <!-- Google Button
        <button class="w-full max-w-md mx-auto px-6 py-3 bg-white rounded-lg shadow border border-[#d2d6db] flex items-center justify-center gap-3">
 <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
              <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path>
              <path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path>
              <path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path>
              <path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
            </svg>
  <span class="text-[#081f3f] text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
    Sign up with Google
  </span>
</button> -->

          <!-- Sign In -->
          <div class="text-center">
            <p class="text-zinc-700 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">
              Already have an account? 
              <span class="text-[#13286d] text-sm font-bold font-['Manrope'] leading-normal tracking-tight cursor-pointer" @click="loginClick">Sign In</span>
            </p>
          </div>
        </div>

        <!-- Terms and Policy -->
        <div class="mt-auto text-center text-xs">
          <p class="text-zinc-700 font-medium font-['Manrope'] leading-tight tracking-tight">
            By signing up or logging in, I accept Wash Cycle Ltd’s
            <a href="https://washcycle.co.uk/terms/" class="text-[#2ab3cb] font-medium font-['Manrope'] leading-tight tracking-tight">Terms of Service</a>
            and
            <a href="https://washcycle.co.uk/privacy/" class="text-[#2ab3cb] font-medium font-['Manrope'] leading-tight tracking-tight">Privacy Policy</a>.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
const DEV = process.env.NODE_ENV !== "production";
import welcomeImage from "../assets/topWelcome.png";

export default {
	data() {
		return {
			welcomeImage,
		}
	},
	methods: {
		home() {
			this.$router.push("/");
		},
		registerClick() {

      this.$router.push("/register");
    },
		loginClick() {
      this.$router.push("/login");
    }
	},



	created() {
		let existingMetaTag = document.querySelector('meta[name="description"]');
		this.warning = null;
		if (this.login) {
			this.warning = null;
			this.login = false;
			this.forgot = false;
			this.register = true;
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Signup";
			existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff! - Signup");

		}
		this.$api.get("/user", { redirect: false }).then(res => {
			if (res.status !== 401) this.home();
		});

		// Check the tab url ref
		let { ref } = this.$route.query;



	},

	computed: {

	}
}
</script>

<style lang="less" scoped>

</style>
