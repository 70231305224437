<template lang="html">
  <div class="min-h-screen flex flex-wrap bg-gray-100 justify-center items-center pb-[73px]">
    <div class="bg-white w-full max-w-md lg:max-w-4xl h-auto lg:flex rounded-3xl shadow-lg overflow-hidden">
     <Sidebar :img="img" :user="user" />
   <div class="flex-1 flex-wrap flex  p-4">
  <MobileHeader :img="img" :user="user" />

<div class="lock" v-if="processing"></div>
        <category-view 
          @changeStandalone="changeView('standaloneView')" 
          @changeBundle="changeView('bundleView')" 
          @changeRedeem="changeView('SlotsView')" 
          v-model="category" 
          v-if="category !== 'redeem' && orderstep == 'categoryView'">
        </category-view>
<standalone-view @changeView="changeView('loadView')" @back="redirectToHome()"  v-if="['standalone', 'express', 'custom'].includes(category) && orderstep == 'standaloneView'" v-model="standalone" ></standalone-view>
<bundle-view @changeView="changeView('BundleBasketView')"  @back="changeView('categoryView')" v-if="category === 'bundle' && orderstep == 'bundleView'" v-model="bundle"></bundle-view>
<load-view   @changeView="changeView('PreferencesView')" @back="changeView('standaloneView')"   v-if="orderstep == 'loadView' " v-model="standalone" ></load-view>
	  <Preferences-view
    v-show="orderstep == 'PreferencesView'"
    @changeView="changeView('SlotsView')"
    @back="changeView('loadView')"
		v-model="standalone"
		:hideDrying="hideDrying"
    ></Preferences-view>
	 
<slots-view 
    v-show="orderstep == 'SlotsView'" 
    v-if="orderstep == 'SlotsView' && ['standalone', 'express','redeem'].includes(category)" 
    v-model="standalone.slot"
		@changeView="changeView('SummaryView')"
    @back="changeView('categoryView')"
    :user="user" 
    :isUpgraded="isUpgraded" 
    :limit="slotLimit">
</slots-view>
<slots-view 
    v-show="orderstep == 'RedeemSlotView'" 
    v-if="orderstep == 'RedeemSlotView'" 
    v-model="redeem.slot"
		@changeView="changeView('RedeemBasketView')"
    @back="redirectToHome()"
    :user="user" 
    :isUpgraded="isUpgraded" 
    :limit="slotLimit">
</slots-view>
<summary-view @changeView="changeView('BasketView')" @addToCart="addToCart()" @addAnother="addAnother()"  @back="changeView('SlotsView')" v-if="orderstep == 'SummaryView'" v-model="standalone" ></summary-view>
<summaryCustom-view @changeView="changeView('BasketView')" @addToCart="addToCart()" @addAnother="addAnother()"  @back="changeView('standaloneView')" v-if="orderstep == 'CustomSummaryView'" v-model="standalone" ></summaryCustom-view>
<basket-view  @changeView="changeView('ThanksView')"  @back="changeView('SummaryView')" v-model="basket" v-if="orderstep == 'BasketView'"></basket-view>
<bundleBasket-view  @changeView="changeView('ThanksView')" @back="redirectToHome()" v-model="bundle" v-if="orderstep == 'BundleBasketView'"></bundleBasket-view>
<redeemBasket-view  @changeView="changeView('ThanksView')" @back="redirectToHome()" v-model="redeem" v-if="orderstep == 'RedeemBasketView'"></redeemBasket-view>

<thanks-view  v-if="orderstep == 'ThanksView'"></thanks-view>

  <FooterView class="w-full" />
</div>
      <!-- Footer Section -->
    </div>
  </div>
</template>

<script>
import CategoryView from "./Category";
import FooterView from "@/components/Footer.vue";
import Sidebar from "../../components/Sidebar.vue";
import MobileHeader from "../../components/MobileHeader.vue";
import FeatherView from "./Feather";
import StandaloneView from "./Standalone";
import RedeemView from "./Redeem";
import SlotsView from "./Slots";
import AddressView from "./Address";
import BundleView from "./Bundle";
import SummaryView from "./Summary";
import SummaryCustomView from "./CustomSummary";
import BasketView from "./Basket";
import BundleBasketView from "./BundleBasket";
import RedeemBasketView from "./RedeemBasket";

import ThanksView from "./Thanks";
import {
	loadStripe
} from '@stripe/stripe-js';
import logoDark from "@/assets/logo-dark.png";
import LoadView from "./Load";
import PreferencesView from "./PreferencesView.vue";
export default {
	components: {
		CategoryView,
		FeatherView,
		StandaloneView,
		SlotsView,
		AddressView,
		BundleView,
		BasketView,
		BundleBasketView,
		RedeemBasketView,
		RedeemView,
		SummaryView,
		ThanksView,
		FooterView,
		SummaryCustomView,
		Sidebar,
		MobileHeader,
		LoadView,
		PreferencesView,
	},

	data() {
		return {
			img: {
				logoDark,
			},
			stripe: loadStripe(STRIPE_KEY),
			category: 'standalone', 
			preferences: null,
			totalCost: 0,
			selectedDetergent: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: ""
			},
			selectedDrying: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: ""
			},
			selectedSoftener: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: ""
			},
			selectedWashing: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: ""
			},
			//preferences end
			orderstep: 'categoryView',

			basket: [],
			paydetails: {
				price: 0,
				discountedprice: 0,
				card: null,
				basket: [{}],
				pride: 0, //pride stuff
				ordername: null,
				appVersion: 31,
				refereal: false,
				giftcodes: {
				price: null,
				giftcode: null,	
				used:null
				}

			},
			urlcheck: 0,
			user: {
				name: null,
				email: null,
				stripe_id: null
			},
			baskettotal: 0,
			blanketdiscount: {
				name: "5% Discount", //block stuff
				enabled: false,
				ammount: 5,
				pricename: "5%",
				value: "percent",
				applied: true
			},

			refereal: {
				id: null,
				user_id: null,
				referral_code: null,
				referring_code: null,
				referred_users: [],
				tokens: 0,
				has_ordered: null
			},

			standalone: {
				load: {},
				type:'clothes',
				whites: false,
				feathers: false,
				kidsClothes: false,
				renew: true,
				ownBag: false,
				prererencesTotal:0,
				slot: {
				start: null,
				end: null
			},
				address: null,
			card: {
				id: null
			},
				softener: {
					type: 0 // Default to no softener preference
				},

			},
			bundle: {
				renew: true, // Default to auto renew
				bundle: {
					type: null
				}
			},
			redeem: {
				whites: false,
				item: {
					id: null
				},
				slot: {
				start: null,
				end: null
			},
			},
			voucher: {
				name: null,
				word: null,
				value: null,
				singleuse:false,
			},
			giftcodes: {
				giftcode: null,
				price: null,
			},
			voucherenabled: false,
			termsAgreed: false,
			isUpgraded: false,
			processing: false, // Whether the order is currently processing
		}
	},

	created() {
		//preferences
		this.$api.get("/preferences/all", {
		}).then(res => {
			this.preferences = res.data;
		});

		//upgraded address
		this.$api.post("/user/isUpgraded", {
		}).then(res => {
			this.isUpgraded = res.data;
		});

		//get tokens
			this.$api.get("/referral").then(res => {
			this.refereal = res.data;
		});

		
		let {
			redeem
		} = this.$route.query;
		this.$router.replace("/order"); // Replace URL with no query params

		if (redeem) {
			this.redeem.item.id = redeem;
			this.category = 'redeem';
			this.orderstep = 'RedeemSlotView';
		}
	},

	mounted() {


	},
	computed: {
				slotLimit() {
			let kg, limit;

			switch (this.category) {
				case 0:
					kg = this.standalone.load ? this.standalone.load.kg : 0;
					limit = Math.ceil(kg / 4);
					return (limit > 1) ? limit : null;
					break;

				case 1:

					return this.house.people || null;
					break;

				case 3:
					kg = this.redeem.item.kg;
					limit = Math.ceil(kg / 4);
					return (limit > 1) ? limit : null;
					break;

				case 4:

					kg = this.custom.load ? this.custom.load.kg : 0;
					limit = Math.ceil(kg / 4);
					return (limit > 1) ? limit : null;
					break;
				case 5:

					kg = this.express.load ? this.express.load.kg : 0;
					limit = Math.ceil(kg / 4);
					return (limit > 1) ? limit : null;
					break;

			}
		},
		hideDrying() {
			this.standalone.category = this.category;
      return (this.category === 'standalone' && this.standalone.type === 'wash_spin_fold') ||
             (this.category === 'express' && this.standalone.type === 'wash_spin_fold') ||
             (this.category === 'custom' && this.standalone.type === 'wash_spin_fold');
    },
	},
	methods: {
			 redirectToHome() {
      this.$router.push("/");
    },
addAnother() {
  // Reset all properties to their default values
  this.orderstep = 'standaloneView';
  this.standalone = {
    load: {},
    type: 'clothes',
    whites: false,
		feathers: false,
		prererencesTotal:0,
    				ownBag:false,
				kidsClothes: false,
    slot: {
      start: null,
      end: null,
    },
    address: null,
    card: {
      id: null,
    },
    softener: {
      type: 0,  // Default to no softener preference
},
   addons:  0,
    orderPrice:  0,		
  };
},

		addToCart() {
  this.basket.push({...this.standalone});
},
		changeView(orderstep) {
			if (orderstep === 'SummaryView' && this.category === 'custom') {
				this.orderstep = 'CustomSummaryView';
			} else if (orderstep === 'SlotsView' && this.category === 'custom') {
				this.orderstep = 'CustomSummaryView';
			} else {
				this.orderstep = orderstep;  // otherwise just set the orderstep
			}
		},

		viewbasket() {
			if (!this.basket.length) {
				return
			}
			this.orderstep = 9;

		},

		newOrder() {
			this.orderstep = 'categoryView'
		},
	},

}

</script>

<style lang="less">
</style>
