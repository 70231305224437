// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.terms[data-v-b78c56c0] {
  font-size: 14px;
}
@media (max-width: 1100px) {
section.terms .agree-txt[data-v-b78c56c0] {
    padding-top: 20px;
}
}
section.terms li[data-v-b78c56c0]:not(:last-of-type) {
  margin-bottom: 12px;
}
section.terms .checkbox[data-v-b78c56c0] {
  cursor: default;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 4px;
  display: flex;
  transition: background-color 0.3s, color 0.3s;
}
section.terms .checkbox.active[data-v-b78c56c0] {
  background-color: rgba(46, 204, 113, 0.1);
  color: #27ae60;
}
section.terms .checkbox span[data-v-b78c56c0] {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Order/Terms.vue","webpack://./Terms.vue"],"names":[],"mappings":"AAGA;EAYE,eAAA;ACbF;ADOI;AAAA;IACE,iBAAA;ACJJ;AACF;ADJA;EAeI,mBAAA;ACRJ;ADPA;EAmBI,eAAA;EACA,aAAA;EACA,sCAAA;EACA,kBAAA;EACA,aAAA;EACA,6CAAA;ACTJ;ADWI;EACE,yCAAA;EACA,cAAA;ACTN;ADnBA;EAgCM,iBAAA;ACVN","sourcesContent":["\n@mobile: ~'(max-width: 1100px)';\n\nsection.terms {\n  .agree {\n    @media @mobile {}\n  }\n\n  .agree-txt {\n    @media @mobile {\n      padding-top: 20px;\n    }\n\n  }\n\n  font-size: 14px;\n\n  li:not(:last-of-type) {\n    margin-bottom: 12px;\n  }\n\n  .checkbox {\n    cursor: default;\n    padding: 20px;\n    background-color: rgba(0, 0, 0, 0.025);\n    border-radius: 4px;\n    display: flex;\n    transition: background-color 0.3s, color 0.3s;\n\n    &.active {\n      background-color: rgba(46, 204, 113, 0.1);\n      color: rgba(39, 174, 96, 1);\n    }\n\n    span {\n      margin-left: 10px;\n    }\n  }\n}\n","section.terms {\n  font-size: 14px;\n}\n@media (max-width: 1100px) {\n  section.terms .agree-txt {\n    padding-top: 20px;\n  }\n}\nsection.terms li:not(:last-of-type) {\n  margin-bottom: 12px;\n}\nsection.terms .checkbox {\n  cursor: default;\n  padding: 20px;\n  background-color: rgba(0, 0, 0, 0.025);\n  border-radius: 4px;\n  display: flex;\n  transition: background-color 0.3s, color 0.3s;\n}\nsection.terms .checkbox.active {\n  background-color: rgba(46, 204, 113, 0.1);\n  color: #27ae60;\n}\nsection.terms .checkbox span {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
