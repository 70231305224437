<template lang="html">
  <div class="min-h-screen flex bg-gray-100 justify-center items-center pb-[73px]">
    <div class="bg-white w-full max-w-md lg:max-w-4xl h-auto lg:flex rounded-3xl shadow-lg overflow-hidden">

      <!-- Sidebar for Desktop -->
      <Sidebar :img="img" :user="user" />

      <!-- Main Content Section -->
      <div class="flex-1 flex flex-col p-4">
     <MobileHeader :img="img" :user="user" />
        <!-- Header Section -->
        <div class="flex items-center justify-between mb-6">
          <span class="text-xl font-bold text-gray-900">Bundles</span>
        </div>

        <!-- Loader -->
        <loader name="your bundles" v-if="loading"></loader>

        <!-- Bundles Section -->
        <div class="flex-1 p-4 overflow-y-auto">
          <div v-if="!loading && bundles.length">

            <!-- Individual Bundle -->
            <div class="bg-white rounded-lg mb-6" v-for="bundle in bundles" :key="bundle.id">

              <div class="meta flex items-center justify-between">
                <div class="flex items-center space-x-4">
                  <!-- Bundle Name -->
                  <div class="text-lg font-semibold text-gray-900">{{ bundle.name }}</div>
                  
                  <!-- Button -->
                </div>
                
                <!-- Time Information -->
                <div class="text-xs text-gray-600">
                  
                  <span v-if="!bundle.isexpired">
                    {{ bundle.renew ? "Renews" : "Expires" }} {{ bundle.time_string }} ({{ bundle.date_string }})
                  </span>
                                    <div 
                    class="renew button cursor-pointer border border-teal-500 text-teal-500 rounded-full py-0.5 px-2 text-xs hover:bg-teal-100"
                    v-if="bundle.renew" @click="cancel(bundle)">
                    Cancel
                  </div>
                  <div 
                    class="renew button cursor-pointer border border-teal-500 text-teal-500 rounded-full py-0.5 px-2 text-xs hover:bg-teal-100"
                    v-if="!bundle.renew" @click="renew(bundle)">
                    Auto-Renew
                  </div>  
                  <span v-if="bundle.isexpired">Expires in 15 minutes</span>
                  
                </div>
              </div>

              <!-- Items Section -->
              <div class="items p-4">
                <div 
                  class="item border border-gray-300 rounded-lg p-4 mb-2 flex flex-col" 
                  :class="{ 'bg-gray-100': item.credits > 0 }" 
                  v-for="item in bundle.items" 
                  :key="item.id" 
                  @click="redeem(item)"
                >
                  <div class="flex items-center mb-2">
                    <div class="credits w-10 h-10 flex items-center justify-center border border-teal-500 rounded-full text-teal-500 mr-3">
                      {{ item.credits }}
                    </div>
                    <div class="flex-grow">
                      <div class="text-sm font-medium">
                        {{ item.name_string }} ({{ item.treatment_string }}) 
                      </div>
                      <div class="text-xs text-gray-600">
                        {{ item.whites ? "Whites" : "Colours" }} 
                        {{ item.feathers ? "• Feather Duvet" : "" }} 
                        {{ bundle.softener_upgrade ? "• Luxury Softener" : "" }}
                      </div>
                    </div>
                  </div>
                  <div class="redeem button text-teal-500 cursor-pointer text-center" v-if="item.credits > 0">
                    Redeem
                  </div>
                </div>
              </div>
              <div class="cost text-xs text-teal-500 mt-2 text-right">{{ bundle.cost | formatPrice }} inc. VAT</div>
              <div class="id text-right text-gray-500 mb-2">BUNDLE <b>#{{ bundle.id }}</b></div>
            </div>
          </div>

          <div class="new" v-if="!loading && !bundles.length">
            <div class="text-center">
              <div class="text-lg font-bold text-gray-900">You don't have any bundles</div>
              <div class="text-sm text-gray-600">Using us a lot? Why not save yourself some money and grab yourself a bundle subscription</div>
              <div class="button blue cursor-pointer bg-teal-500 text-white p-2 rounded-lg mt-4" @click="newBundle">New Bundle</div>
            </div>
          </div>
        </div>

        <!-- Footer Section -->
        <FooterView />
      </div>
    </div>
  </div>
</template>

<script>
import FooterView from "@/components/Footer.vue"; // Import the footer component
import Sidebar from "@/components/Sidebar.vue"; // Import the sidebar component
import logoDark from "@/assets/logo-dark.png"; // Import logo image
import bellIcon from "@/assets/bell.svg"; // Import bell icon image
import bundles from "@/assets/icons/bundles.png"; // Import bundles image
import MobileHeader from "../../components/MobileHeader.vue";
export default {
  components: {
    FooterView,
    Sidebar,
    MobileHeader
  },
  data() {
    return {
      img: {
        logoDark,
        bellIcon,
        bundles,
      },
      loading: true, // Loading data from API
      bundles: [],
      user: {
        name: 'User Name', // Placeholder for user name
        email: 'user@example.com' // Placeholder for user email
      }
    }
  },

  created() {
    this.reload();
    this.fetchUserData(); // Call to fetch user data
  },

  methods: {
    newBundle() {
      this.$router.push({ path: "/order", query: { category: 2 } });
    },

    reload() {
      this.$api.get("/user/bundles").then(res => {
        this.loading = false;
        this.bundles = res.data;
      });
    },

    redeem(item) {
      if (item.credits) {
        this.$router.push({ path: "/order", query: { redeem: item.id } });
      }
    },

    cancel(bundle) {
      const confirm = window.confirm("Are you sure you wish to cancel this bundle?");
      if (confirm) {
        this.$api.get("/bundle/" + bundle.id + "/cancel").then(() => {
          this.reload();
        });
      }
    },

    renew(bundle) {
      const confirm = window.confirm('Are you sure you wish to turn on auto renew for this bundle?');
      if (confirm) {
        this.$api.get('/bundle/' + bundle.id + '/renew').then(res => {
          if (!res.data) window.alert(`Bundle Outdated. Please buy a new bundle`);
          this.reload();
        });
      }
    },

    async fetchUserData() {
      try {
        const res = await this.$api.get("/user");
        if (res.status === 200 && res.data) {
          this.user = res.data;
        } else {
          this.redirectToWelcome();
        }
      } catch (error) {
        this.redirectToWelcome();
      }
    }
  },

  filters: {
    formatPrice: price => "£" + price.toFixed(2),
    VatPrice: price => "£" + (price * 0.20).toFixed(2),
  }
}
</script>

<style lang="less" scoped>
/* Add any specific styling here if needed */
</style>