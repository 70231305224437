<template lang="html">
  <div class="min-h-screen flex items-center justify-center bg-gray-100 pt-4">
    <div class="bg-white w-full max-w-md lg:max-w-4xl h-auto lg:flex lg:flex-row rounded-3xl shadow-lg overflow-hidden"> <!-- added overflow-hidden and h-auto -->

        <!-- Sidebar for Desktop -->
      <Sidebar :img="img" :user="user" />

             <!-- Header Section -->
<MobileHeader :img="img" :user="user" />
      <div class="flex-1 flex flex-col p-4"> <!-- changed p-6 to p-4 for smaller padding on mobile -->
        <div class="flex items-center justify-between mb-4">
          <div class="flex flex-col">
            <span class="text-xl font-bold text-gray-900">Orders</span>
          </div>
          <div class="flex items-center space-x-2">
            <div class="text-sm text-gray-600">Exclude closed.</div>
            <div class="flex items-center cursor-pointer" @click="toggle">
              <div class="w-4 h-4 rounded border border-gray-300 flex items-center justify-center" 
                   :class="{ 'bg-blue-500 border-transparent': open, 'bg-gray-300': !open }">
                <svg v-if="open" xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M5 12l5 5L20 7"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <!-- Loader -->
        <loader name="your orders" v-if="loading"></loader>

        <!-- Orders List -->
        <div class="flex-1 overflow-y-auto">
          <div class="bg-white shadow-lg mt-4 rounded-2xl"> <!-- added rounded-2xl -->
            <div v-if="!loading && orders.length">
              <div v-for="order in orders.slice(0, ordersShown)" :key="order.id" class="mb-4 border border-gray-200 rounded-lg p-4">
                  <div class="mt-4 text-sm text-gray-600">
                    ORDER <b>#{{ order.id }}</b>
                  </div>
                      <div class="mb-4 pt-5">
                  <div :class="getTitleColor(order.statuses[0].title)" class="font-bold">{{ order.statuses[0].title }}</div>
                  <div class="text-sm text-gray-600">{{ order.statuses[0].description }}</div>
                </div>
                <div class="mb-4">
                  <div class="flex flex-wrap text-sm text-gray-600">
                    <div>
                      <div class="font-bold">Ordered</div>
                      <div>{{ order.created_string }}</div>
                    </div>
                    <div>
                      <div class="font-bold">Collection & Delivery</div>
                      <div>{{ order.address_string }}<span v-if="order.phone">, {{ order.phone }}</span></div>
                    </div>
                    <div>
                      <div class="font-bold">Collection</div>
                      <div>{{ order.slot.time_string }} {{ order.slot.day_string }}</div>
                    </div>
                  </div>
                </div>
                        <div class="mt-2 text-[#2ab3cb] cursor-pointer pb-3" @click="toggleDetails(order.id)">
          {{ isOrderOpen(order.id) ? 'Hide Details' : 'Show Details' }}
        </div>
                    <div v-if="isOrderOpen(order.id)" class="mb-4">
                  <div class="font-bold text-sm text-gray-600">Details</div>
                  <div v-for="standalone in order.standalones" :key="standalone.id" class="mb-2">
                    <div class="text-sm text-gray-600">
                      <div>{{ standalone.express ? "Express" : "Standalone" }}</div>
                      <div>{{ standalone.name_string }}</div>
                      <div class="text-xs">{{ standalone.treatment_string }}</div>
                      <div class="text-xs">{{ standalone.whites ? "Whites" : "Colours" }}</div>
                      <div v-if="!order.detergent_name" class="text-xs">{{ standalone.softener.name }}</div>
                      <div class="text-xs">{{ standalone.feathers ? "Feather Duvet" : "" }}</div>
                    </div>
                    <div class="font-bold text-sm text-gray-600 pt-5">Cost</div>
                    <div class="text-sm font-bold mt-1">{{ (standalone.cost - standalone.giftcode_value) | formatPrice }} inc. VAT</div>
                  </div>

                  <!-- Gift Code Used -->
                  <div v-for="standalone in order.standalones" v-if="standalone.giftcode_used" class="flex justify-between mb-2">
                    <div class="text-sm text-gray-600">
                      <div>Gift Code Used</div>
                      <div>{{ standalone.giftcode_used }}</div>
                    </div>
                  </div>


                  <!-- Preferences -->
                  <div v-if="order.detergent_name" class="mb-2">
                    <div class="text-sm text-gray-600">
                      <div class="font-bold">Preferences</div>
                      <div>{{ order.detergent_name }}</div>
                      <div>{{ order.softener_name }}</div>
                      <div>{{ order.drying_name }}</div>
                      <div>{{ order.washing_name }}</div>
                    </div>
                  </div>

                  <!-- Charges -->
                  <div v-if="order.charges" class="flex justify-between mb-2">
                    <div class="text-sm text-gray-600">
                      <div class="font-bold">Charges</div>
                      <div>Order overweight charge</div>
                    </div>
                    <div v-if="order.charges_refunded" class="text-sm font-bold text-gray-900">{{ order.charges | formatPrice }} (refunded)</div>
                    <div v-if="order.charges && !order.charges_refunded" class="text-sm font-bold text-red-500">{{ order.charges | formatPrice }}</div>
                  </div>


                  <!-- Order ID and Cancel Button -->

                <div class="mt-2 cursor-pointer text-red-500 flex items-center pt-5" 
                    @click="cancelOrder(order.id, isCancellationDisabled(order.slot.start, order.statuses[0].title))"
                    :class="{ 'opacity-50 cursor-not-allowed': isCancellationDisabled(order.slot.start, order.statuses[0].title) }">
                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/>
                    </svg>
                    <span class="mr-2">Cancel Order</span>
                </div>
              

                </div>
              </div>
            </div>
 
          </div>
          <div class="w-full max-w-md lg:max-w-4xl p-6 mt-4 bg-white rounded-3xl shadow-lg text-center" v-if="!loading && !orders.length">
            <div class="text-lg font-bold text-gray-900">You don't have any orders</div>
            <div class="text-sm text-gray-600">Let's fix that! Put your feet up and let someone else take care of your laundry</div>
            <button class="bg-green-500 text-white p-2 rounded-xl mt-4 cursor-pointer" @click="newOrder">
              New order
            </button>
          </div>
        </div>
        <button class="h-12 px-6 py-3 bg-[#2ab3cb] rounded-lg shadow justify-center items-center gap-3 inline-flex" v-if="orders.length > 5 && ordersShown < orders.length" @click="loadMore">
          <div class="text-white text-sm font-bold leading-normal tracking-tight">
            Load more orders
          </div>
        </button>
        <FooterView/>
      </div>
    </div>
  </div>
</template>



<script>
import FooterView from "@/components/Footer.vue";
import Sidebar from "../../components/Sidebar.vue";
import MobileHeader from "../../components/MobileHeader.vue";

import logoDark from "@/assets/logo-dark.png";
import bellIcon from "@/assets/bell.svg";

export default {
  components: {
    FooterView,
    Sidebar,
    MobileHeader,
  },
  data() {
    return {
      img: {
        logoDark,
        bellIcon,
      },
      open: true,
      loading: true, // Whether loading AJAX data
      orders: [],
      openedOrders: [],
      preferences: null,
      timeoutId: null,
      reloadInterval: 4000,
      ordersShown: 3,
      user: {
        name: null,
        email: null,
      },
    }
  },

  methods: {
    getTitleColor(title) {
      switch (title) {
        case 'Accepted':
          return 'text-green-600'; // Green for accepted status
        case 'On the way':
          return 'text-blue-500'; // Blue for on the way
        case 'Collected':
          return 'text-teal-500'; // Teal for collected
        case 'Cleaning':
          return 'text-orange-500'; // Orange for cleaning
        case 'Cleaned':
          return 'text-yellow-600'; // Yellow for cleaned
        case 'On its way':
          return 'text-light-blue-500'; // Light blue for on its way
        case 'Delivered':
          return 'text-green-700'; // Dark green for delivered
        case 'Half delivered':
          return 'text-amber-500'; // Amber for half delivered
        case 'Refunded':
          return 'text-red-600'; // Red for refunded
        case 'Missed collection':
          return 'text-gray-600'; // Gray for missed collection
        default:
          return 'text-gray-900'; // Default color if none match
      }
    },
    
async fetchUserOrders() {
  try {
    if (this.user) {
      let path = "/user/orders?open=true";
      const res = await this.$api.get(path);
      if (res.status === 200) {
     		this.orders = res.data;
        this.loading = false;
      }
    }
  } catch (error) {
    console.error("Failed to fetch orders", error);
  }
}, redirectToWelcome() {
      this.$router.push("/welcome");
    },
		    async fetchUserData() {
      try {
        const res = await this.$api.get("/user");

        if (res.status === 200 && res.data) {
          this.user = res.data;
          this.fetchUserOrders(); // Fetch orders only if user is successfully logged in
        } else {
          this.redirectToWelcome();
        }
      } catch (error) {
        this.redirectToWelcome();
      }
		},
		    redirectToWelcome() {
      this.$router.push("/welcome");
    },
		toggle() {
    this.open = !this.open;
    },
 toggleDetails(orderId) {
    const index = this.openedOrders.indexOf(orderId);
    if (index === -1) {
      // Order not in the array, so add it (open details)
      this.openedOrders.push(orderId);
    } else {
      // Order is already in the array, so remove it (close details)
      this.openedOrders.splice(index, 1);
    }
  },
  isOrderOpen(orderId) {
    return this.openedOrders.includes(orderId);
  },
		 loadMore () {
     	 this.ordersShown *= 2
    },
		reload() {
    this.fetchUserData();
    let path = "/user/orders";
    if (this.open) path += "?open=true";

    this.$api.get(path).then(res => {
      this.loading = false;
      this.orders = res.data;

      // Set the timeout after the data is loaded
			this.timeoutId = window.setTimeout(this.reload, this.reloadInterval);
    }).catch(error => {
      this.loading = false; // Stop the loading indicator in case of an error
    });
  },
		cancelOrder(id, isDisabled) { 
	 		if (isDisabled) {
        // Cancel is disabled, do nothing (or show alert/message)
        return;
				}
			let confirm = window.confirm(`Are you sure you want to cancel order ${id}?`);	
			if (confirm) {
				this.$api.post("/Order/refundOrder", { id: id }).then(res => {
				if(res.status===200) window.alert("Order succesfully canceled, you're money will be refunded up to 7 working days")
				else window.alert("something went wrong, Please contact us to cancel this order")
			});
			}
		},
		isCancellationDisabled(startTime, status) {
			if (status !== "Accepted") return true
      const twentyFourHoursInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const startDateTime = new Date(startTime).getTime();
      const currentDateTime = new Date().getTime();
      return startDateTime - currentDateTime < twentyFourHoursInMs;
    },
		newOrder() {
			this.$router.push({
				path: "/order",
				query: { category: 0 }
			});
		},
		clearTimeouts() {
			if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
        this.timeoutId = null; // Clear the stored timeout ID
      }
    },
	},


	created() {
		this.reload();
	},
	beforeDestroy() {
		
    this.clearTimeouts(); // Clear timeouts when the component is destroyed
  },
	beforeRouteLeave(to, from, next) {
    this.clearTimeouts(); // Clear timeouts when navigating away from the component
    next();
  },

	filters: {
		formatPrice: price => {
			if (price < 0) {
				price = 0;
			}
			return "£" + price.toFixed(2);
		}
	},
	
}
</script>

<style lang="less">

</style>
