<template lang="html">
  <section class="user flex flex-col p-4 pb-[73px]">
    <div class="user-details flex flex-col bg-white p-4 rounded-lg  w-full ">
      <div class="mb-4">
        <span class="block mb-1">Name:</span>
        <input v-model="newuser.name" :placeholder="user.name" class="details-input w-full p-2 border rounded" />
      </div>

      <div class="mb-4">
        <span class="block mb-1">Email:</span>
        <input v-model="newuser.email" type="email" class="details-input w-full p-2 border rounded" :placeholder="user.email" />
      </div>

      <div class="mb-4">
        <span class="block mb-1">Password:</span>
        <input disabled placeholder="********" class="details-input w-full p-2 border rounded" />
        <span @click="showpasschange" class="button cursor-pointer text-blue-500 hover:underline">Change Password</span>
      </div>

      <div class="actions flex justify-between text-right mb-4">
				<div @click="savechanges()" class="address new text-white text-center bg-[#2ab3cb] p-4 mt-3 w-full rounded-lg shadow-md cursor-pointer mb-4" >
        Save Changes
      </div>
      </div>
    </div>

    <pass-modal v-if="showmodal" :user="user" @close="showmodal = false"></pass-modal>
  </section>
</template>


<script>
import Vuetify from 'vuetify';
import PassModal from "./PassModal"

export default {
	components: {
		PassModal,
	},

	data() {
		return {
			loading: true,
			processing: false,
			showmodal: false,
			user: {
				name: null,
				email: null,
				id: null
			},
			newuser: {
				name: null,
				email: null,
				id: null
			},
			showNewCard: false
		}
	},

	created() {
		this.$api.get("/user").then(res => {
			this.user = res.data;
			this.loading = false;
		});
	},

	methods: {
		showpasschange() {
			this.showmodal = true;
		},

		cancel() {
			this.$router.push("/");
		},

		savechanges() {
			this.newuser.id = this.user.id;
			var emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
			if (this.newuser.email && !emailCheck.test(this.newuser.email)) {
				window.alert("Enter a valid email address");
				return;
			}
			this.$api.post("/user/updatedetails", this.newuser).then(res => {
				if (res.status == '200') {
					window.alert("User details updated successfully");
				}
			});
		},
	}
}
</script>
