// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.loader {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  color: #436266;
}
@keyframes spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
section.loader .spinner {
  width: 16px;
  height: 16px;
  border: solid 2px #01BAD4;
  border-right: solid 3px transparent;
  border-left: solid 3px transparent;
  border-radius: 50%;
  animation: 1s spin linear infinite;
}
section.loader .text {
  margin-left: 10px;
}
section.loader .name {
  color: #01BAD4;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader.vue"],"names":[],"mappings":"AACA;EACC,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;AAAD;AAEC;AACC;IAAO,uBAAA;AACP;AAAA;IAAK,yBAAA;AAGL;AACF;AAZA;EAYE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mCAAA;EACA,kCAAA;EACA,kBAAA;EACA,kCAAA;AAGF;AArBA;EAsBE,iBAAA;AAEF;AAxBA;EA0BE,cAAA;EACA,gBAAA;AACF","sourcesContent":["\nsection.loader {\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 20px 10px;\n\tcolor: #436266;\n\n\t@keyframes spin {\n\t\tfrom { transform: rotate(0deg); }\n\t\tto { transform: rotate(360deg); }\n\t}\n\n\t.spinner {\n\t\twidth: 16px;\n\t\theight: 16px;\n\t\tborder: solid 2px #01BAD4;\n\t\tborder-right: solid 3px transparent;\n\t\tborder-left: solid 3px transparent;\n\t\tborder-radius: 50%;\n\t\tanimation: 1s spin linear infinite;\n\t}\n\n\t.text {\n\t\tmargin-left: 10px;\n\t}\n\n\t.name {\n\t\tcolor: #01BAD4;\n\t\tfont-weight: 500;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
