<template lang="html">
  <section class="addresses flex flex-col p-4 pb-[73px]">
    <loader name="your addresses" v-if="loading"></loader>
    <div class="addresses flex flex-wrap justify-center" v-else>
      <div class="address flex flex-col bg-white p-4 rounded-lg shadow-md w-full m-2" v-for="address in addresses" :key="address.id">
        <div class="meta mb-2">
          <div class="name text-lg font-semibold">{{ address.name }}</div>
          <div class="lines text-gray-700">{{ address.string }}</div>
          <div class="phone text-gray-500" v-if="address.phone">{{ address.phone }}</div>
        </div>

        <div class="actions flex justify-between  border-t border-gray-300 p-2 rounded">
          <div class="action text-red-500 cursor-pointer hover:underline" @click="remove(address)">Remove</div>
          <!-- You can uncomment the edit option if needed -->
          <!-- <div class="action text-blue-500 cursor-pointer hover:underline">Edit</div> -->
        </div>
      </div>

      <div class="block" v-if="processing"></div>
      <div class="address new text-white text-center bg-[#2ab3cb] p-4 mt-3 w-full rounded-lg shadow-md cursor-pointer mb-4" @click="showNewAddress = true">
        Add a new address
      </div>
    </div>

    <new-address v-if="showNewAddress" @close="close"></new-address>
  </section>
</template>

<script>
import NewAddress from "../../components/NewAddress";

export default {
  components: {
    NewAddress
  },

  data() {
    return {
      addresses: [],
      preferred: null,
      loading: true, // Whether loading AJAX data
      showNewAddress: false,
      processing: false, // To manage processing state
    };
  },

  created() {
    this.reload();
  },

  methods: {
    remove(address) {
      let confirm = window.confirm("Are you sure you wish to delete this address?");

      if (confirm) {
        this.processing = true;
        this.$api.delete("/address/" + address.id).then(res => {
          if (res.status === 200) {
            this.reload();
          } else {
            window.alert(res.data);
          }
          this.processing = false;
        });
      }
    },

    reload() {
      this.$api.get("/user/addresses").then(res => {
        let { addresses, preferred } = res.data;

        this.loading = false;
        this.addresses = addresses;
        this.preferred = preferred;
      });
    },

    close() {
      this.showNewAddress = false;
      this.reload();
    }
  },
    watch: {
    showNewAddress(newVal) {
      if (newVal) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
}
</script>

<style scoped>
/* Add any additional scoped styles here if necessary */
</style>
