<template>
  <section class="w-full max-w-full bg-white rounded-3xl relative">
    <!-- Header Section -->
    <header class="w-full px-4 py-3 flex-wrap bg-white border-b border-gray-100 flex justify-center items-center gap-2">
      <!-- Back Button -->
      <button type="button" class="w-6 h-6 flex items-center justify-center cursor-pointer" @click="redirectToHome" aria-label="Go back">
        <img :src="img.backArrow" class="w-6 h-6 rounded-full" alt="Back arrow">
      </button>
      <h1 class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Select Your Order Type
      </h1>
    </header>

    <!-- Options Section -->
    <main class="w-full px-4 flex flex-col gap-3 flex-grow">
      <!-- Form to group related radio options -->
      <form class="w-full flex flex-col gap-3">
        <fieldset class="border-0 p-0 m-0 flex flex-col gap-3">
          <legend class="sr-only">Select order type</legend>

          <!-- Standalone Option -->
          <label
            class="block p-3 rounded-xl flex flex-col gap-3 items-start flex-grow min-h-[100px] cursor-pointer relative"
            :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected === 'standalone', 'border-gray-200 border': selected !== 'standalone'}"
            for="standalone-option"
          >
            <div class="border-gray-200 border w-10 h-10 bg-[#f2f4fd] rounded-full flex items-center justify-center">
              <img :src="img.standalone" class="w-6 h-6 rounded-full" alt="Standalone icon">
            </div>
            <div class="flex-grow">
              <h2 class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Standalone</h2>
              <p class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">A single Laundry Job</p>
            </div>
            <input
              type="radio"
              id="standalone-option"
              name="category"
              value="standalone"
              :checked="selected === 'standalone'"
              class="absolute top-3 right-3"
              @change="setCategory('standalone')"
            >
          </label>

          <!-- Express Standalone Option -->
          <label
            class="block p-3 rounded-xl flex flex-col gap-3 items-start flex-grow min-h-[100px] cursor-pointer relative"
            :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected === 'express', 'border-gray-200 border': selected !== 'express'}"
            for="express-option"
          >
            <div class="border-gray-200 border w-10 h-10 bg-[#f2f4fd] rounded-full flex items-center justify-center">
              <img :src="img.express" class="w-6 h-6 rounded-full" alt="Express icon">
            </div>
            <div class="flex-grow">
              <h2 class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Express Standalone</h2>
              <p class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">Guaranteed same day return</p>
            </div>
            <input
              type="radio"
              id="express-option"
              name="category"
              value="express"
              :checked="selected === 'express'"
              class="absolute top-3 right-3"
              @change="setCategory('express')"
            >
          </label>

          <!-- Bundle Option -->
          <label
            class="block p-3 rounded-xl flex flex-col gap-3 items-start flex-grow min-h-[100px] cursor-pointer relative"
            :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected === 'bundle', 'border-gray-200 border': selected !== 'bundle'}"
            for="bundle-option"
          >
            <div class="border-gray-200 border w-10 h-10 bg-[#f2f4fd] rounded-full flex items-center justify-center">
              <img :src="img.bundle" class="w-6 h-6 rounded-full" alt="Bundle icon">
            </div>
            <div class="flex-grow">
              <h2 class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Bundle</h2>
              <p class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">A discounted subscription</p>
            </div>
            <input
              type="radio"
              id="bundle-option"
              name="category"
              value="bundle"
              :checked="selected === 'bundle'"
              class="absolute top-3 right-3"
              @change="setCategory('bundle')"
            >
          </label>

          <!-- Custom Bundle Option -->
          <label
            class="block p-3 rounded-xl flex flex-col gap-3 items-start flex-grow min-h-[100px] cursor-pointer relative"
            :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected === 'custom', 'border-gray-200 border': selected !== 'custom'}"
            for="custom-option"
          >
            <div class="border-gray-200 border w-10 h-10 bg-[#f2f4fd] rounded-full flex items-center justify-center">
              <img :src="img.custom" class="w-6 h-6 rounded-full" alt="Custom bundle icon">
            </div>
            <div class="flex-grow">
              <h2 class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Custom Bundle</h2>
              <p class="text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">A custom discounted bundle</p>
            </div>
            <input
              type="radio"
              id="custom-option"
              name="category"
              value="custom"
              :checked="selected === 'custom'"
              class="absolute top-3 right-3"
              @change="setCategory('custom')"
            >
          </label>
        </fieldset>
      </form>

      <!-- Next Button -->
      <button
        @click="changeView"
        class="flex-grow h-12 px-6 py-3 bg-[#2ab3cb] rounded-lg shadow-sm justify-center items-center gap-3 inline-flex mt-4 lg:mt-2"
        type="button"
      >
        <span class="text-black text-sm font-bold font-['Manrope'] leading-normal tracking-tight">NEXT</span>
      </button>
    </main>
  </section>
</template>

<script>
import standalone from "assets/tshirt.svg";
import express from "assets/alarm.svg";
import bundle from "assets/bundle.svg";
import custom from "assets/custombundle.svg";
import basket from "assets/basket.svg";
import backArrow from "assets/backarrow.svg";

export default {
  props: ["value"],

  data() {
    return {
      img: { standalone, bundle, express, custom, basket, backArrow },
      selected: this.value,
    };
  },

  methods: {
    changeView() {
      switch (this.selected) {
        case "bundle":
          this.$emit("changeBundle");
          break;
        case "standalone":
          this.$emit("changeStandalone");
          break;
        case "express":
          this.$emit("changeStandalone");
          break;
        case "custom":
          this.$emit("changeStandalone");
          break;
        case "redeem":
          this.$emit("changeRedeem");
          break;
        default:
          console.warn(`Unknown category: ${this.selected}`);
      }
    },
    redirectToHome() {
      this.$router.push("/");
    },
    setCategory(id) {
      this.selected = id;
      this.$emit("input", id);
    },
  },
};
</script>