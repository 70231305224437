<template lang="html">
<section class="standalone w-full bg-gray-50 rounded-3xl">
  <!-- Header -->
  <div class="w-full px-6 py-4 bg-white border-b border-gray-200 flex justify-center items-center gap-3 shadow-sm">
    <div class="w-8 h-8 flex items-center justify-center cursor-pointer" @click="emmitBack()">
      <img :src="img.backArrow" class="w-8 h-8 rounded-full" alt="Back">
    </div>
    <div class="flex-grow text-center text-zinc-900 text-lg font-bold font-['Manrope'] tracking-tight">
      Redeem Order
    </div>
  </div>

  <!-- Loader -->
  <loader v-if="loading" name="basket"></loader>
   
  <div class="p-6 space-y-5" v-else>
		 <div class="bg-white p-4 rounded-lg shadow-sm">
      <div class="flex justify-between items-center mb-3">
        <div class="text-sm font-bold text-[#111826]">Your Address</div>
        <button @click="showNewAddress = true" class="px-3 py-1.5 text-xs font-bold text-[#081f3f] border rounded-lg shadow-sm">
          Add New
        </button>
      </div>
<div class="overflow-x-auto mb-6">
  <select 
    v-model="selected.address" 
    class="font-['Manrope'] text-sm mb-2 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb] sm:text-sm">
    <option 
      v-for="address in addresses" 
      :key="address.id" 
      :value="address" 
      class="font-['Manrope'] text-sm whitespace-normal">
      {{ address.name }} {{ address.address_line_1 }} {{ address.city }} {{ address.postcode }}
    </option>
  </select>
</div>
    </div>
  <div class="w-full max-w-md lg:max-w-4xl h-auto relative p-4">
    <div class="h-12 flex flex-col justify-start items-start mb-4">
      <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">Laundry Content</div>
      <div class="text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">Is your laundry load whites or colours?</div>
    </div>
    <div class="flex justify-between ">
      <div @click="setWhites(false)" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': !whites, 'border-gray-200': whites}" class="flex-grow h-[50px] rounded-xl border  items-center justify-center cursor-pointer relative mr-2 flex">
        <div class="text-center text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">Colours</div>
        <input type="radio" :checked="!whites" class="absolute top-3 right-3">
      </div>
      <div @click="setWhites(true)" :class="{'bg-[#2ab3cb]/20 border-[#2ab3cb]': whites, 'border-gray-200': !whites}" class="flex-grow h-[50px] rounded-xl border  items-center justify-center cursor-pointer relative ml-2 flex">
        <div class="text-center text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">Whites</div>
        <input type="radio" :checked="whites" class="absolute top-3 right-3">
      </div>
    </div>
  </div>
    <div class="bg-white p-5 rounded-lg shadow-lg">
      <div class="text-sm w-full font-bold text-[#111826] ">{{selected.item.bundle_name}} {{selected.item.name_string}}</div>
      <!-- You can include summary details here if needed -->
    </div>
        <button @click="trypay" 
      :disabled="!selected.address" 
      :class="[
        'flex-grow h-12 w-full px-6 py-3 font-bold rounded-lg shadow-sm',
        !selected.address ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#081F3F] text-white'
      ]">
      Redeem
    </button>
		<new-address v-if="showNewAddress" @close="close"></new-address>

  </div>
</section>
</template>


<script>
import backArrow from "assets/backarrow.svg";
import NewAddress from "../../components/NewAddress";

export default {
	components: {
		NewAddress,
	},

	props: [
		"value"
	],

	data() {
		return {
			img: { backArrow },
			loading: true, // Whether loading AJAX data
			processing:false,
			selected: this.value,
			showNewAddress: false,
      addresses: [],
			appVersion: 31,
			paydetails: {
				price: 0,
				discountedprice: 0,
				card: null,
				basket: [{}],
				pride: 0, //pride stuff
				ordername: null,
				appVersion: 31,
				refereal: false,
				giftcodes: {
				price: null,
				giftcode: null,	
				used:null
				}

			},
			whites:false,
		}
	},

	created() {
	   let id = this.selected.item.id;
    this.$api.get("/bundle/item/" + id).then((res) => {
      this.loading = false;
      this.selected.item = res.data;

    });
		this.$api.get("/user/addresses").then(res => {
        let { addresses, preferred } = res.data;
        this.loading = false;
        this.addresses = addresses;
        this.preferred = preferred;
      });
	},

	methods: {
		emmitBack() {
			this.$emit("back");
		},
		changeView() {
			this.$emit("changeView");
    },
		setWhites(whites) {
			this.whites = whites
			this.selected.whites = whites;
		},
		close() {
      this.showNewAddress = false;
			this.reload();
    },
		trypay() {
			this.paydetails = { ...this.selected };
			this.paydetails.category = "redeem"
			this.paydetails.address = this.selected.address;
			this.paydetails.slot = this.selected.slot;
			this.price = 0
				this.processing = true;
				this.$api.post("/order", this.paydetails,  {
					redirect: false
				}).then(res => {
					this.handleServerResponse(res)

				});
		},
				 paymentsuccess() {
					this.changeView()
		},
				 handleServerResponse(response) {
					if (response.error) {

						window.alert(response.error);
					} else if (response.data.error) {

						window.alert(response.data.error);
					}  else {
						this.processing = false;
						this.paymentsuccess();
					}
				},
						reload() {

        this.$api.get("/user/addresses").then(res => {
        let { addresses, preferred } = res.data;

        this.loading = false;
        this.addresses = addresses;
      });
		},
},
}
</script>

<style lang="less" scoped>
</style>
