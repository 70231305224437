<template lang="html">
<section class="login min-h-screen flex items-center justify-center bg-gray-100">
  <div class="relative bg-white rounded-3xl shadow-lg p-8 w-full max-w-md sm:max-w-lg lg:max-w-xl">
    <!-- Back Arrow and Empty Space -->
    <div class="flex justify-between items-center mb-6">
      <!-- Back Arrow -->
      <div class="w-6 h-6 flex justify-center items-center">
        <img :src="backArrow" alt="Back Arrow" class="w-full h-full object-contain cursor-pointer" @click=home />
      </div>
      <!-- Logo -->
      <div class="w-10 h-10">
        <img :src="logoDark" alt="Logo" class="w-full h-full object-contain cursor-pointer" @click=home />
      </div>
    </div>

    <!-- Main Content -->
    <div class="text-center mb-8">
      <div class="text-zinc-900 text-2xl font-bold font-['Manrope'] leading-9 tracking-tight">Welcome Back</div>
      <div class="text-zinc-700 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mt-2">
        Enter your login details below or 
        <a class="text-zinc-700 text-sm font-bold font-['Manrope'] underline cursor-pointer" @click=RegisterClick>register here</a> 
        if you don’t have an account.
      </div>
    </div>

    <div class="mb-6">
      <label class="block text-zinc-900 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mb-2" for="email">Email Address</label>
      <input id="email" type="email" placeholder="Enter your email address" class="w-full px-3 py-2 bg-white rounded-xl border border-[#d2d6db] text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight"
			 v-model="user.email"
			/>
    </div>

    <div class="mb-6">
      <label class="block text-zinc-900 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mb-2" for="password">Password</label>
      <input id="password" type="password" placeholder="Enter your password" class="w-full px-3 py-2 bg-white rounded-xl border border-[#d2d6db] text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight"
			v-model="user.password"
			/>
      <div class="text-right text-zinc-700 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mt-1 cursor-pointer">
        <a class="cursor-pointer"@click="ResetClick">Forgot Password</a>
      </div>
    </div>
   <!-- Warning Message -->
    <div v-if="warning" class="mb-6">
      <p class="text-red-600 text-sm font-medium font-['Manrope'] leading-normal tracking-tight">{{ warning }}</p>
    </div>
    <div class="mb-6">
    <button
  class="w-full px-6 py-3 rounded-lg shadow border text-sm font-bold font-['Manrope'] leading-normal tracking-tight"
  :class="{
    'bg-gray-100 border-gray-100 text-zinc-300': !loginReady,
    'bg-[#2AB3CB] border-[#2AB3CB] text-white': loginReady
  }"
  :disabled="!loginReady"
  @click="submit"
>
  Sign In
</button>
    </div>
 <!--
    <div class="mb-6">
      <div class="w-full px-6 py-3 bg-white rounded-lg shadow border border-[#d2d6db] flex items-center justify-center">
        <div class="w-6 h-6 flex justify-center items-center mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="20" viewBox="0 0 48 48">
            <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path>
            <path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path>
            <path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path>
            <path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
          </svg>
        </div>
        <div class="text-[#081f3f] text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
          Sign in with Google
        </div>
      </div>
    </div>
 -->
    <div class="text-center text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight">
      Don’t have an account? 
      <a  class="text-[#2ab3cb] text-xs font-bold font-['Manrope'] leading-tight cursor-pointer"@click=RegisterClick>Register</a>
    </div>
  </div>
</section>
</template>


<script>
const DEV = process.env.NODE_ENV !== "production";
import logoDark from "../assets/logo-dark.png"
import backArrow from "../assets/icons/blackArrow.png"

export default {
	data() {
		return {
			logoDark,
			backArrow,
		

			user: {
				email: "",
				password: "",
			},

			warning: null,
			postcodeWarning: null
		}
	},

	methods: {
		home() {
			this.$router.push("/welcome");
		},loginClick() {
      this.$router.push("/login");
    },RegisterClick() {
      this.$router.push("/register");
		},ResetClick() {
      this.$router.push("/reset");
		},
		
		submit() {
			this.warning = null;
				if (this.loginReady) {
					this.processing = true;
					this.$api.post("/user/login", this.user, { redirect: false }).then(res => {
						this.processing = false;
						if (res.status !== 200) this.warning = res.data;
						else this.home();
					});
				}
			// else if (this.forgot) {
			// 	this.processing = true;

			// 	this.$api.post("/user/forgot", this.user, { redirect: false }).then(res => {
			// 		this.processing = false;

			// 		if (res.status !== 201) this.warning = res.data;
			// 		if (res.status == 200) {
			// 			alert("Please check your inbox")
			// 			this.login = true;
			// 			this.toggle();
			// 			this.toggle();

			// 		}
			// 	});

			// }
		}
	},

	created() {
		let existingMetaTag = document.querySelector('meta[name="description"]');
		this.warning = null;
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Signup";
			existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff! - Signup");
		// Check if already logged in and redirect
		this.$api.get("/user", { redirect: false }).then(res => {
			if (res.status !== 401) this.home();
		});



	},

	computed: {
		loginReady() {
			let { user } = this;
			let ready = true;
			if (user.email.length < 5) ready = false;
			if (user.password.length < 4) ready = false;
			return ready;
		},

	}
}
</script>

<style lang="less" scoped>
/* Ensure responsive scaling for logo and back arrow */
@media (max-width: 640px) {
  .login img {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 641px) {
  .login img {
    width: 40px;
    height: 40px;
  }
}
</style>
