<template lang="html">
  <section class="pass-dialog dialog-wrapper">
    <div @click="close" class="bg"></div>
    <div class="dialog flex flex-col p-4 rounded-lg shadow-lg">

      <label class="header-primary text-lg font-semibold mb-4">Change Password</label>
      <div class="mb-4 flex items-center">
        <span class="block w-1/3 mb-1">Old Password:</span>
        <input 
          type="password" 
          v-model="passwords.old" 
          class="details-input flex-1 w-2/3 p-2 border border-gray-300 rounded" 
        />
      </div>

      <div class="mb-4 flex items-center">
        <span class="block w-1/3 mb-1">New Password:</span>
        <input 
          type="password" 
          minlength="8" 
          v-model="passwords.new" 
          class="details-input flex-1 w-2/3 p-2 border border-gray-300 rounded" 
        />
      </div>

      <div class="mb-4 flex items-center">
        <span class="block w-1/3 mb-1">Confirm Password:</span>
        <input 
          type="password" 
          minlength="8" 
          v-model="passwords.confirm" 
          class="details-input flex-1 w-2/3 p-2 border border-gray-300 rounded" 
        />
      </div>

      <span class="forgot-tip text-gray-600 mb-4">
        You can also log out and use the forgot password button during login to reset your password.
      </span>

      <div class="pill selected button cursor-pointer text-white" @click="changepass">Change Password</div>
    </div>

    <div class="background" @click="close"></div>
  </section>
</template>

<script>
import Vue from "vue";
export default {
  props: ["show", "user"],
  data() {
    return {
      passwords: {
        userid: null,
        old: null,
        new: null,
        confirm: null,
      },
      showWarn: false,
    };
  },
  methods: {
    changepass() {
      let confirm = window.confirm("Are you sure you want to change password?");
      if (confirm) {
        if (this.passwords.new !== this.passwords.confirm) {
          alert("New password and confirm password do not match.");
        } else if (this.passwords.new === this.passwords.old) {
          alert("New password and old password are the same.");
        } else if (!this.passwords.old) {
          alert("Please enter old password.");
        } else if (!this.passwords.new) {
          alert("Please enter new password.");
        } else if (!this.passwords.confirm) {
          alert("Please confirm new password.");
        } else {
          this.passwords.userid = this.user.id;
          Vue.prototype.$api.post("/user/updatepassword", this.passwords).then(res => {
            if (res.status == '200') {
              window.alert("Password Changed Successfully.");
            } else if (res.status == '401') {
              window.alert("Current Password Does Not Match.");
            } else {
              window.alert("Something went wrong.");
            }
          });
        }
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.bg {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.forgot-tip {
  font-size: 13px;
  padding: 10px 0;
  color: gray;
}

.pill {
  padding: 8px 18px;
  margin: 10px;
  background: #01bad4;
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.details-input {
  padding: 10px;
  margin-top: 10px;
  color: #01bad4;
  font-size: 14px;
  border: solid 1px #01bad4;
  border-radius: 7px;
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 100%;
  max-width: 400px; /* Set a max width for better layout */
  text-align: left;
  z-index: 100;
  border-radius: 8px; /* Rounded corners */
}
</style>
