import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import MainView from '@/views/Main';
import WelcomeView from '@/views/Welcome';
import OrderView from '@/views/Order';
import BundlesView from '@/views/Dashboard/Bundles';
import AccountView from "@/views/Profile"
import SupportView from "@/views/Support"


import LoginView from "@/views/Login"
import RegisterView from "@/views/Register"
import RegisterSplashView from "@/views/Registersplash"
import Reset from "@/views/Reset"

import PastOrders from "@/views/Dashboard/Orders";

const routes = [{
		path: "/welcome",
		component: WelcomeView
},
	{
		path: "/login",
		component: LoginView
	},
	{
		path: "/register",
		component: RegisterView
	},
	{
		path: "/hi",
		component: RegisterSplashView
	},
	{
		path: "/reset",
		component: Reset
	},
	{
		path: "/",
		component: MainView,
	},{
		path: "/orders",
		component: PastOrders
	},
	{
		path: "/order",
		component: OrderView
	},
	{
		path: "/bundles",
		component: BundlesView
	},
	{
		path: "/account",
	component: AccountView
	},
	{
		path: "/support",
		component: SupportView
	},
]

const router = new VueRouter({
	routes,
	mode: "history"
});

export default router;