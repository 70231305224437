var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"standalone w-full bg-gray-50 rounded-3xl"},[_c('div',{staticClass:"w-full px-4 py-3 bg-white border-b border-gray-100 flex justify-center items-center gap-2"},[_c('div',{staticClass:"w-6 h-6 flex flex-wrap items-center justify-center cursor-pointer",on:{"click":function($event){return _vm.emmitBack()}}},[_c('img',{staticClass:"w-6 h-6 rounded-full",attrs:{"src":_vm.img.backArrow,"alt":"Back"}})]),_vm._v(" "),_c('div',{staticClass:"flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight"},[_vm._v("\n        Order Summary \n      ")])]),_vm._v(" "),_c('div',{staticClass:"p-4 space-y-4"},[_c('div',{staticClass:"bg-white p-4 rounded-lg shadow-sm"},[_c('div',{staticClass:"text-sm font-bold text-[#111826] mb-2"},[_vm._v("Order Summary")]),_vm._v(" "),_c('div',{staticClass:"flex justify-between text-sm"},[_c('div',[_vm._v(_vm._s(_vm.selected.load.name_string))]),_vm._v(" "),_c('div',{staticClass:"font-bold text-zinc-900"},[_vm._v("£"+_vm._s(_vm.selected.load.price))])]),_vm._v(" "),_c('div',{staticClass:"flex justify-between text-sm border-b pb-2 mt-2"},[_c('div',[_vm._v("Addons")]),_vm._v(" "),_c('div',{staticClass:"font-bold text-zinc-900"},[_vm._v("£"+_vm._s(_vm.calculateAddons.toFixed(2)))])]),_vm._v(" "),_c('div',{staticClass:"flex justify-between mt-4 text-sm font-bold"},[_c('div',[_vm._v("Total")]),_vm._v(" "),_c('div',{staticClass:"text-base"},[_vm._v("£"+_vm._s(_vm.total.toFixed(2)))])])]),_vm._v(" "),_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex-grow h-[50px] rounded-xl border items-center justify-center cursor-pointer relative mr-2 flex",class:{
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': _vm.selected.renew, 
      'border-gray-200': !_vm.selected.renew
    },on:{"click":function($event){return _vm.setRenew(true)}}},[_c('div',{staticClass:"text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight"},[_vm._v("\n      Auto-Renew \n    ")]),_vm._v(" "),_c('input',{staticClass:"absolute top-3 right-3",attrs:{"type":"radio"},domProps:{"checked":_vm.selected.renew}})]),_vm._v(" "),_c('div',{staticClass:"flex-grow h-[50px] rounded-xl border items-center justify-center cursor-pointer relative ml-2 flex",class:{
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': !_vm.selected.renew, 
      'border-gray-200': _vm.selected.renew
    },on:{"click":function($event){return _vm.setRenew(false)}}},[_c('div',{staticClass:"text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight"},[_vm._v("\n      No Renewal\n    ")]),_vm._v(" "),_c('input',{staticClass:"absolute top-3 right-3",attrs:{"type":"radio"},domProps:{"checked":!_vm.selected.renew}})])])]),_vm._v(" "),_c('div',{staticClass:"w-full p-4 bg-white border-t border-gray-200 flex flex-wrap gap-4"},[_c('button',{class:[
      'flex-grow h-12 w-full px-6 py-3 font-bold rounded-lg shadow-sm',
      'bg-[#2ab3cb] text-black',
      _vm.buttonClicked ? 'animate-pulse bg-green-400 text-white' : ''
    ],on:{"click":_vm.addToCart}},[_vm._v("\n    Add To Cart\n  ")]),_vm._v(" "),_c('button',{class:[
      'flex-grow h-12 px-6 py-3 font-bold rounded-lg shadow-sm',
      'bg-[#2ab3cb] text-black'
    ],on:{"click":_vm.addAnother}},[_vm._v("\n    Add Another \n  ")]),_vm._v(" "),_c('button',{class:[
      'flex-grow h-12 px-6 py-3 font-bold rounded-lg shadow-sm',
      'bg-[#081F3F] text-white'
    ],on:{"click":_vm.changeView}},[_vm._v("\n    Finish and Pay\n  ")])]),_vm._v(" "),(_vm.showNewAddress)?_c('new-address',{on:{"close":_vm.close}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }