// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Montserrat", sans-serif;
  background-color: #FFF;
  color: #151515;
}
.wrapper-full {
  width: 1100px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
}
@media (max-width: 1100px) {
.wrapper-full {
    width: 95vw;
    display: block;
    padding: 0 20px;
}
}
h1 {
  font-weight: 300;
  font-size: 28px;
  padding: 20px 0;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/App.vue","webpack://./App.vue"],"names":[],"mappings":"AAGA;EACC,qCAAA;EACA,sBAAA;EACA,cAAA;ACFD;ADKA;EACC,aAAA;EACA,eAAA;EACA,sBAAA;EACA,cAAA;ACHD;ADIO;AAAA;IACN,WAAA;IACA,cAAA;IACA,eAAA;ACDC;AACF;ADIA;EACC,gBAAA;EACA,eAAA;EACA,eAAA;EACA,SAAA;ACFD","sourcesContent":["\n@mobile: ~'(max-width: 1100px)';\n\nbody {\n\tfont-family: \"Montserrat\", sans-serif;\n\tbackground-color: #FFF;\n\tcolor: #151515;\n}\n\n.wrapper-full {\n\twidth: 1100px;\n\tpadding: 0 20px;\n\tbox-sizing: border-box;\n\tmargin: 0 auto;\n       @media\t@mobile{\n\twidth:95vw;\n\tdisplay:block;\n\tpadding: 0 20px;\n       } \t \n}\n\nh1 {\n\tfont-weight: 300;\n\tfont-size: 28px;\n\tpadding: 20px 0;\n\tmargin: 0;\n}\n","body {\n  font-family: \"Montserrat\", sans-serif;\n  background-color: #FFF;\n  color: #151515;\n}\n.wrapper-full {\n  width: 1100px;\n  padding: 0 20px;\n  box-sizing: border-box;\n  margin: 0 auto;\n}\n@media (max-width: 1100px) {\n  .wrapper-full {\n    width: 95vw;\n    display: block;\n    padding: 0 20px;\n  }\n}\nh1 {\n  font-weight: 300;\n  font-size: 28px;\n  padding: 20px 0;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
