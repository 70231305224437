import Vue from "vue";
import App from "@/views/App";
import Loader from "@/components/Loader";
import '@/assets/tailwind.css';  


import "normalize.css";
import "@/base/index.less";
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import router from "./router";

// Attach API to vue instance
import api from "./api";
Vue.use((Vue, options) => {
	Vue.prototype.$api = api(router);
});

// Global loading component
Vue.component("loader", Loader);

new Vue({
	el: "#app",
	render: h => h(App),
	router
});
