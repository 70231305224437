<template lang="html">
<section class="orders">
	<div class="header">
		<div class="headerleft">
	
	
		<span>Orders</span>
			<span class="live" v-if="!loading">Live</span>	</div>
	
	
	</div>
	<div class="mobile toggle">
		<div class="label">Show only open orders</div>

		<div class="switch" :class="{ open }" @click="toggle">
			<div class="circle"></div>
		</div>
	</div>
	<loader name="your orders" v-if="loading"></loader>

	<div class="orders" v-if="!loading && orders.length">
		<div class="order" :class="{ open: order.open }" v-for="order in orders.slice(0,ordersShown)">
			<div class="meta">
				<div class="segment">
					<div class="label">Ordered</div>
					<div class="text">{{ order.created_string }}</div>
				</div>

				<div class="segment">
					<div class="label">Collection &amp; Delivery</div>
					<div class="text">{{ order.address_string }}<span v-if="order.phone">, {{ order.phone }}</span></div>
				</div>

				<div class="segment">
					<div class="label">Collection</div>
					<div class="text">{{ order.slot.time_string }} {{ order.slot.day_string }}</div>
				</div>
			</div>

			<div class="content">
				<div class="status">
					<div class="title">
						<span>{{ order.statuses[0].title }}</span>
						<span class="time">{{ order.statuses[0].time_string }}</span>
					</div>
					<div class="desc">{{ order.statuses[0].description }}</div>
				</div>
				
				<div class="items">
					
					<div class="item" v-for="standalone in order.standalones">
						<div class="left">
							<div class="category">{{ standalone.express ? "Express" : "Standalone" }}</div>
							<div  class="name">{{ standalone.protector || standalone.singleduvet || standalone.doubleduvet || standalone.kignsizeduvet || standalone.singlepillow || standalone.bulkyblankets || standalone.dressinggowns
	|| standalone.topperpadded || standalone.dogbed || standalone.mixed || standalone.doublepillow ? standalone.name_string.substring(standalone.name_string.indexOf('kg') + 2) : standalone.name_string }}</div>
							<div class="desc">({{ standalone.treatment_string }})</div>
							<div class="desc">{{ standalone.whites ? "Whites" : "Colours" }}</div>
							<div v-if="!order.detergent_name" class="desc">{{ standalone.softener.name }}</div>
							<div class="desc">{{ standalone.feathers ? "Feather Duvet" : "" }}</div>
						</div>
					
						<div class="right">{{ standalone.cost - standalone.giftcode_value | formatPrice }} <span style="">inc. VAT</span></div>
						
					</div>
					<div class="item" v-for="standalone in order.standalones">
							<div class="left">
						<div class="category" v-if="standalone.giftcode_used" >Gift Code Used <span style=""></span></div>
							<div class="desc">{{ standalone.giftcode_used }}</div>


					</div>
					</div>
					<div class="item" v-for="house in order.houses">
						<div class="left">
							<div class="category">House discount</div>
							<div class="name">{{ house.name_string }}</div>
							<div class="desc">{{ house.treatment_string }}</div>
							<div class="desc">x{{ house.people }} ({{ house.cost / house.people | formatPrice }} each)</div>
						</div>

						<div class="right">{{ house.cost | formatPrice }}</div>
					</div>
				</div>
				<div v-if="order.detergent_name" class="items">
					<div class="item">
				<div class="left">
							<div class="category">Preferences</div>
							<div class="desc">{{ order.detergent_name }}</div>
							<div class="desc">{{ order.softener_name }}</div>
							<div class="desc">{{ order.drying_name }}</div>
							<div class="desc">{{ order.washing_name }}</div>
						</div></div></div>
				<div v-if="order.charges" class="item"><div class="left">
							<div class="category">Charges</div>
							<div  class="desc">Order overwieght charge</div>
							
						</div>
							<div v-if="order.charges_refunded" class="right">{{order.charges | formatPrice }}(refunded)</div>
							<div v-if="order.charges && !order.charges_refunded" class="right chargesred">{{order.charges | formatPrice }}</div>
						</div>
						<div v-if="order.baby_charge" class="item"><div class="left">
							<div class="category">Charges</div>
							<div class="desc">Children's clothes charge</div>
							
						</div>
							<div class="right">£2.40</div>
						</div>
					<div v-if="order.bagdiscount" class="item"><div class="left">
							<div class="category">Discount</div>
							<div  class="desc">includes 20p use own bag discount</div>
							
						</div>
							<div class="right discountgreen">£0.20</div>
						</div>

				<div class="id">ORDER <b>#{{ order.id }}</b></div>
				    <div class="cancel" @click="cancelOrder(order.id,isCancellationDisabled(order.slot.start,order.statuses[0].title))" :class="{ 'cancelDisabled': isCancellationDisabled(order.slot.start,order.statuses[0].title) }">
      <span>Cancel Order</span>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/>
      </svg>
    </div>

			</div>
		</div>
	</div>
	<div v-if="!loading">
<button class="loadMore" v-if="orders.length > 5 && ordersShown < orders.length" @click="loadMore">
  Load more orders
</button></div>

	<div class="new" v-if="!loading && !orders.length">
		<img class="image" :src="img.orders">
		<div class="title">You don't have any orders</div>
		<div class="subtitle">Let's fix that! Put your feet up and let someone else take care of your laundry</div>
		<div class="button green" @click="newOrder">New order</div>
	</div>
</section>
</template>

<script>
import moment from "moment";
import orders from "assets/icons/orders.png";

export default {
	data() {
		return {
			img: { orders },
			open: true,
			loading: true, // Whether loading AJAX data
			orders: [],
			preferences: null,
			reloadInterval: 4000,
			ordersShown: 5,
		}
	},

	methods: {
		toggle() {
			this.open = !this.open;
			reload();
		
		},
		 loadMore () {
     	 this.ordersShown *= 2
    },
		reload() {
			let path = "/user/orders";
			if (this.open) path += "?open=true";
			this.$api.get(path).then(res => {
					if (this.open) path += "?open=true";
				this.loading = false;
				this.orders = res.data;
	
				window.setTimeout(this.reload, this.reloadInterval);


			});
		},
		cancelOrder(id, isDisabled) { 
	 		if (isDisabled) {
        // Cancel is disabled, do nothing (or show alert/message)
        return;
				}
			let confirm = window.confirm(`Are you sure you want to cancel order ${id}?`);	
			if (confirm) {
				this.$api.post("/Order/refundOrder", { id: id }).then(res => {
				if(res.status===200) window.alert("Order succesfully canceled, you're money will be refunded up to 7 working days")
				else window.alert("something went wrong, Please contact us to cancel this order")
			});
			}
		},
		isCancellationDisabled(startTime, status) {
			if (status !== "Accepted") return true
      const twentyFourHoursInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const startDateTime = new Date(startTime).getTime();
      const currentDateTime = new Date().getTime();
      return startDateTime - currentDateTime < twentyFourHoursInMs;
    },
		newOrder() {
			this.$router.push({
				path: "/order",
				query: { category: 0 }
			});
		}
	},

	created() {
		this.reload();
	},

	filters: {
		formatPrice: price => {
			if (price < 0) {
				price = 0;
			}
			return "£" + price.toFixed(2);
		}
	}
}
</script>

<style lang="less">
@mobile: ~'(max-width: 1100px)';

section.orders {
.mobile{
	display: none;
		@media @mobile {
		display:initial;
		}}

.desktop{
	display: initial;
	@media @mobile {
		display:none;
		}}

.loadMore{    
    margin: auto;
    display: flex;
    position: inherit;
    border-radius: 100px;
    border: solid 1px rgba(1, 186, 212, 0.5);
    color: #01BAD4;
    padding: 20px;}
.toggle {
		padding: 20px;
	

		display: flex;
		justify-content: end;
		align-items: center;
		font-size: 14px;
		color: #161616;
		@media @mobile {width:100%;}	
.label{padding-right:20px;}
		.switch {
			background-color: #f8fdfe;
			width: 54px;
			height: 28px;
			border-radius: 100px;
			overflow: hidden;
			display: flex;
			padding: 4px;
			box-sizing: border-box;
			transition: 0.3s all;
			border: 1px #b4e8f1 solid;
@media @mobile {float: right;}	
			.circle {
				background-color: #FFF;
				width: 20px;
				height: 20px;
				border-radius: 100px;
				box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
			}

			&.open {
				justify-content: flex-end;
				background-color: #01BAD4;
			}
		}
	}	



	.header {
		
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media @mobile {display: block;}	

		.live {
			font-size: 13px;
			color: #2ecc71;
			border: solid 1px #2ecc71;
			border-radius: 4px;
			padding: 4px 8px;
			cursor: default;
			font-weight: 300;
			@media @mobile {float: right;}	
		}
	}

	.order {
		border: solid 1px #DDD;
		border-radius: 4px;
		margin: 10px 0;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		.meta {
			display: flex;
			background-color: #FAFAFA;
			border-bottom: solid 1px #DDD;
			justify-content: space-between;

			.segment {
				padding: 20px;
				flex-grow: 1;
				font-size: 14px;

				.label {
					font-weight: 500;
					color: #444;
					margin-bottom: 3px;
				}

				.text {
					font-size: 15px;
				}
			}
		}

		.content {
			padding: 20px;

			.item {
				display: flex;
				justify-content: space-between;
				align-items: center;
@media @mobile {display: block;}	
				border-top: solid 1px #DDD;
				padding: 14px 0;
				&:last-child { border-bottom: solid 1px #DDD; }
				.discountgreen{color:#2ecc71}
				.chargesred{color:#cc2e2ead}
				.right{

					@media @mobile {display: block;text-align:center;padding-top:10px;}
				}
				.left {
					display: flex;
					align-items: center;
					@media @mobile {display: block; text-align: center;padding-top:20px;}	
					.name { font-weight: 500;@media @mobile {padding-top:20px;} }
					.desc { margin-left: 10px;@media @mobile {margin-left: 0px;padding-top:5px;text-align: center; margin: auto;} }

					.category {
						font-size: 13px;
						padding: 4px 8px;
						margin-right: 10px;
						border-radius: 4px;
						border: solid 1px #DDD;
					}
				}
				
			}
		}

		.status {
			margin-bottom: 14px;

			.title {
				font-size: 22px;
				margin-bottom: 10px;
				display: flex;
				align-items: center;

				.time {
					font-size: 14px;
					margin-left: 10px;
					color: #888;
				}
			}

			.desc {
				color: #444;
				    font-size: 14px;
    color: #888;
    max-width: 300px;
    margin: 0;
    padding-left: 0px;
    padding: 10px;
    text-align: left;
    padding-left: 0px;
			}
		}

		.id {
			text-align: right;
			margin-top: 10px;
			font-size: 12px;
			color: #AAA;
			padding-bottom: 10px;
		}
		.cancel {
			text-align: right;
			font-size: 12px;
			color: #bf6969;
			fill: #bf6969;
    align-items: center;
    cursor: pointer;
		border-radius: 15px;
    max-width: 114px;
    margin-left: auto;
		}
		 .cancel span {
			position: relative;
    top: -8px;
    }
		.cancelDisabled{
			color:#7d7d7d;
			fill:#7d7d7d;
			cursor: auto;
		}
		// Add special styling to open orders
		&.open {
			border: solid 1px rgba(46, 204, 113, 0.3);

			.meta {
				background-color: rgba(46, 204, 113, 0.03);
				border-bottom: solid 1px rgba(46, 204, 113, 0.3);
				@media @mobile {display: block;}	
			}

			.status .title {
				color: #2ecc71;
			}
		}
	}
}
</style>
