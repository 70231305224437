<template>
  <div class="min-h-screen flex flex-col justify-between bg-gray-100 pb-16">
    <div class="flex justify-center items-center flex-1">
      <div class="bg-white w-full max-w-md lg:max-w-4xl lg:h-auto lg:flex lg:flex-row rounded-3xl shadow-lg">
        <!-- Sidebar for Desktop -->
      <Sidebar :img="img" :user="user" />

        <!-- Main Content Section -->
        <div class="flex-1 flex flex-col">
          <!-- Header Section -->
  <MobileHeader :img="img" :user="user" />

          <div>
            <!-- Loading state when orders are being fetched -->
            <div v-if="loading" class="text-center bg-[#f38744] text-white p-4 mt-3 mx-6 mb-4 rounded-xl">
              <p class="text-lg font-bold">Loading your orders...</p>
              <!-- Optionally add a loading spinner here -->
            </div>
            <!-- Show when no active orders after loading -->
            <div v-else-if="orders.length === 0" class="bg-[#f38744] text-white p-4 mt-3 mx-6 mb-4 rounded-xl">
              <p class="text-lg font-bold">You have 0 active orders.</p>
              <p class="text-sm">Let’s change that. Let us take care of your laundry!</p>
              <div class="mt-3 p-2 bg-[#f38744] rounded-lg cursor-pointer text-center">
                <span class="text-sm font-bold cursor-pointer" @click="redirect('order')">Click here to start a new order →</span>
              </div>
            </div>

            <!-- Show when there are active orders -->
            <div v-else :class="getStatusClasses(status.title)" class="p-4 text-white mt-3 mx-6 mb-4 rounded-xl">
              <div class="flex flex-wrap justify-between items-center gap-3 w-full">
                <!-- Left side (Icon and Text) -->
                <div class="flex items-center gap-3">
                  <div class="w-12 h-12 relative bg-[#f9f5ff] rounded-lg">
                    <div class="w-12 h-12 absolute top-0 left-0"></div>
                    <div class="w-6 h-6 absolute top-[12px] left-[12px] flex justify-center items-center">
                      <div class="w-6 h-6 relative">
                        <img :src="img.placeOrderIcon" alt="Order Icon">
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col justify-start cursor-pointer" @click="redirect('orders')">
                    <div class="text-zinc-100 text-xs font-medium leading-tight tracking-tight">
                      You have {{ orders.length }} active order<span v-if="orders.length > 1">s</span>
                    </div>
                    <div class="text-[#fdfdfd] text-base font-bold leading-7 tracking-tight">
                      {{ status.title }}
                    </div>
                  </div>
                </div>

                <!-- Right side (See Details) -->
                <div class="ml-auto flex items-center" @click="redirect('orders')">
                  <div class="text-white text-xs font-bold leading-tight tracking-tight cursor-pointer">See Details</div>
                </div>
              </div>

              <!-- Progress bar -->
              <div class="h-2 bg-white rounded-full mt-3">
                <div :style="{ width: getProgressBarWidth(status.title) }" class="h-2 bg-[#084c2e] rounded-full"></div>
              </div>

              <!-- Status description -->
              <div class="text-zinc-100 text-xs font-medium leading-tight tracking-tight mt-2">
                {{ status.description }}
              </div>
            </div>
          </div>

          <!-- Quick Access Cards -->
          <div class="grid grid-cols-2 gap-4 px-6 mb-4">
            <div class="bg-blue-100 p-4 rounded-xl cursor-pointer" @click="redirect('order')">
              <div class="p-2 bg-white border border-blue-300 rounded-lg w-10 h-10 flex items-center justify-center">
                <img :src="img.placeOrderIcon" class="w-6 h-6" alt="Place Order Icon">
              </div>
              <h2 class="mt-3 text-lg font-bold text-gray-900">Place an order</h2>
              <p class="text-sm text-gray-500">Click here to place a new order.</p>
            </div>
            <div class="bg-green-100 p-4 rounded-xl cursor-pointer" @click="redirect('orders')">
              <div class="p-2 bg-white border border-blue-300 rounded-lg w-10 h-10 flex items-center justify-center">
                <img :src="img.ordersIcon" class="w-6 h-6" alt="Past Order Icon">
              </div>
              <h2 class="mt-3 text-lg font-bold text-gray-900">Past orders</h2>
              <p class="text-sm text-gray-500">View and manage your past orders here.</p>
            </div>
            <div class="bg-yellow-100 p-4 rounded-xl cursor-pointer" @click="redirect('support')">
              <div class="p-2 bg-white border border-blue-300 rounded-lg w-10 h-10 flex items-center justify-center">
                <img :src="img.supportIcon" class="w-6 h-6" alt="Past Order Icon">
              </div>
              <h2 class="mt-3 text-lg font-bold text-gray-900">Get help</h2>
              <p class="text-sm text-gray-500">Click here to find help and support.</p>
            </div>
            <div class="bg-red-100 p-4 rounded-xl cursor-pointer" @click="redirect('bundles')">
              <div class="p-2 bg-white border border-blue-300 rounded-lg w-10 h-10 flex items-center justify-center">
                <img :src="img.bundlesIcon" class="w-6 h-6" alt="Past Order Icon">
              </div>
              <h2 class="mt-3 text-lg font-bold text-gray-900">Active bundles</h2>
              <p class="text-sm text-gray-500">Save time and money with bundles here.</p>
            </div>
          </div>

          <!-- Promotional Banners -->
          <div class="px-6 mb-4 flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
            <div class="flex-1 bg-[#081f3f] p-4 rounded-xl text-white cursor-pointer" @click="redirect('account?category=5')">
              <h2 class="text-sm font-bold">Invite a Friend to Get 10% Off!</h2>
              <p class="text-xs">Invite now →</p>
            </div>
            <div v-if="!loading && orders.length === 0" class="flex-1 bg-teal-500 p-4 rounded-xl text-white cursor-pointer" @click="redirect('order')">
              <h2 class="text-sm font-bold">Get 10% Off Your First Order!</h2>
              <p class="text-xs">Order now</p>
            </div>
          </div>

          <!-- Bottom Navigation Bar -->
          <FooterView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logoDark from "../assets/logo-dark.png";
import bellIcon from "../assets/bell.svg";
import homeIcon from "../assets/home.svg";
import ordersIcon from "../assets/pastOrders.svg";
import supportIcon from "../assets/support.svg";
import accountIcon from "../assets/account.svg";
import placeOrderIcon from "../assets/placeOrder.svg";
import bundlesIcon from "../assets/bundles.svg";
import Sidebar from "../components/Sidebar.vue";
import FooterView from "../components/Footer.vue";
import MobileHeader from "../components/MobileHeader.vue";

export default {
  components: {
    FooterView,
    Sidebar,
    MobileHeader,
  },

  data() {
    return {
      img: {
        logoDark,
        bellIcon,
        homeIcon,
        ordersIcon,
        supportIcon,
        accountIcon,
        placeOrderIcon,
        bundlesIcon,
      },
      OrderScreen: false,
      loading: true, // Loading data from API
      orders: [],
      status: {
        title: "",
        lastUpdate: "",
      },
      user: {
        name: null,
        email: null,
      },
      alert: {
        text: null,
        url: null,
      },
    };
  },

  created() {
    this.fetchUserData();
  },

  methods: {
    getStatusClasses(title) {
      switch (title) {
        case "Accepted":
          return "bg-[#5cb85c]"; // Green background for Accepted
        case "On the way":
          return "bg-[#5bc0de]"; // Blue background for On the way
        case "Collected":
          return "bg-[#5bc0de]"; // Blue background for Collected
        case "Cleaning":
          return "bg-[#f0ad4e]"; // Orange background for Cleaning
        case "Cleaned":
          return "bg-[#ffc107]"; // Yellow background for Cleaned
        case "On its way":
          return "bg-[#5bc0de]"; // Blue background for On its way
        case "Delivered":
          return "bg-[#5cb85c]"; // Dark Green background for Delivered
        case "Half delivered":
          return "bg-[#ffc107]"; // Amber background for Half delivered
        case "Refunded":
          return "bg-[#d9534f]"; // Red background for Refunded
        case "Missed collection":
          return "bg-[#6c757d]"; // Gray background for Missed collection
        default:
          return "bg-[#099250]"; // Default color if none match
      }
    },
    getProgressBarWidth(title) {
      switch (title) {
        case "Accepted":
          return "20%"; // 20% for Accepted
        case "On the way":
          return "40%"; // 40% for On the way
        case "Collected":
          return "60%"; // 60% for Collected
        case "Cleaning":
          return "70%"; // 70% for Cleaning
        case "Cleaned":
          return "85%"; // 85% for Cleaned
        case "On its way":
          return "90%"; // 90% for On its way
        case "Delivered":
          return "100%"; // 100% for Delivered
        case "Half delivered":
          return "50%"; // 50% for Half delivered
        case "Refunded":
          return "0%"; // 0% for Refunded
        case "Missed collection":
          return "10%"; // 10% for Missed collection
        default:
          return "0%"; // Default width if none match
      }
    },
    async fetchUserData() {
      try {
        const res = await this.$api.get("/user");

        if (res.status === 200 && res.data) {
          this.user = res.data;
          this.fetchUserOrders(); // Fetch orders only if user is successfully logged in
        } else {
          this.redirect("/welcome");
        }
      } catch (error) {
        this.redirect("/welcome");
      }
    },
    extractStatus(orders) {
      const allStatuses = orders.flatMap((order) => order.statuses);

      const latestStatus = allStatuses.reduce((latest, current) => {
        return new Date(latest.time) > new Date(current.time) ? latest : current;
      }, allStatuses[0]);
      this.status.title = latestStatus.title;
      this.status.description = latestStatus.description;
    },
    async fetchUserOrders() {
      try {
        if (this.user) {
          let path = "/user/orders?open=true";
          const res = await this.$api.get(path);
          if (res.status === 200) {
            this.orders = res.data;

            if (res.data.length > 0) {
              this.extractStatus(res.data);
            }
            this.loading = false;
          }
        }
      } catch (error) {
        console.error("Failed to fetch orders", error);
      }
    },
    redirect(route) {
      this.$router.push(route);
    },
    redirectToFullUrl(url) {
      window.location.href = url;
    },

    profile() {
      this.$router.push("/profile");
    },

    billing() {
      this.OrderScreen = false;
      this.$router.push("/billing");
    },
  },

  computed: {},
};
</script>

<style>
</style>