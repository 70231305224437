<template lang="html">
  <div class="w-full max-w-md lg:max-w-4xl h-auto relative bg-white rounded-3xl overflow-hidden p-4">
    <!-- Description -->
    <div class="h-12 flex flex-col justify-start items-start mt-5 mb-7">
      <div class="text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Select Your Duvet Preference
      </div>
      <div class="text-zinc-700 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">
        Choose between a standard or feather duvet option.
      </div>
    </div>

    <!-- Options Section -->
    <div class="grid grid-cols-2 sm:grid-cols-2 gap-4">
      <!-- Standard Duvet Option -->
      <div
        @click="setFeather(false)"
        :class="{ 'bg-[#2ab3cb]/20 border-[#2ab3cb]': !feather, 'border-gray-200': feather }"
        class="w-full h-24 sm:h-32 rounded-xl border flex flex-col items-center justify-center p-2 cursor-pointer relative"
      >
        <div class="text-center">
          <div class="text-zinc-900 text-sm sm:text-base font-bold font-['Manrope'] leading-7 tracking-tight">
            None / Standard duvet
          </div>
          <div class="text-zinc-500 text-xs sm:text-sm font-medium font-['Manrope'] leading-tight tracking-tight">
            £0.00
          </div>
        </div>
        <input type="radio" :checked="!feather" class="absolute top-3 right-3" />
      </div>

      <!-- Feather Duvet Option -->
      <div
        @click="setFeather(true)"
        :class="{ 'bg-[#2ab3cb]/20 border-[#2ab3cb]': feather, 'border-gray-200': !feather }"
        class="w-full h-24 sm:h-32 rounded-xl border flex flex-col items-center justify-center p-2 cursor-pointer relative"
      >
        <div class="text-center">
          <div class="text-zinc-900 text-sm sm:text-base font-bold font-['Manrope'] leading-7 tracking-tight">
            Feather duvet
          </div>
          <div class="text-zinc-500 text-xs sm:text-sm font-medium font-['Manrope'] leading-tight tracking-tight">
            £2.00
          </div>
        </div>
        <input type="radio" :checked="feather" class="absolute top-3 right-3" />
      </div>
    </div>
  </div>
</template>

<script>
import backArrow from "assets/backarrow.svg";
export default {
  props: ["value"],

  data() {
		
    return {
			img: { backArrow },
      feather: this.value,
    };
  },

  methods: {
    setFeather(feather) {
      this.feather = feather;
      this.$emit("input", this.feather);
    },
    changeView() {
      this.$emit("next");
    },
    emmitBack() {
      this.$emit("back");
    },
  },
};
</script>