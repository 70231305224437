<template lang="html">
<section class="footer"><div class="wrapper-full">
	<img :src="img.logoDark">
	<p>version 1.34</p>
	<p>Copyright &copy; Wash Cycle LTD {{ new Date().getFullYear() }}. All rights reserved. You, the customer, are responsible for ensuring that only items that can withstand a wash are used with our service. We will not check for any items left in pockets or items of clothing that cannot withstand our washing process.</p>
	<p>Flat 3, 53 Richmond House, Richmond Road, Roath, Cardiff, CF24 3AR</p>
</div></section>
</template>

<script>
import logoDark from "assets/logo-dark.png";

export default {
	data() {
		return {
			img: { logoDark }
		}
	}
}
</script>

<style lang="less" scoped>
section.footer {

	border-top: solid 1px #DDD;
	font-size: 12px;
	padding: 40px 0;
	margin-top: 50px;
	text-align: center;

	.wrapper-full {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	img {
		width: 50px;
		height: auto;
		margin-bottom: 20px;
	}

	p {
		max-width: 600px;
		line-height: 1.6;
	}
}
</style>
