<template lang="html">
  <div class="min-h-screen flex bg-gray-100 justify-center items-center">
    <div class="bg-white w-full max-w-md lg:max-w-4xl h-auto lg:flex rounded-3xl shadow-lg overflow-hidden">

      <!-- Sidebar for Desktop -->
      <Sidebar :img="img" :user="user" />

      <!-- Main Content Section -->
      <div class="flex-1 flex flex-col p-4">
        
        <!-- Header Section -->
        <MobileHeader :img="img" :user="user" />
        <div class="flex items-center justify-between mb-6">
          <span class="text-xl font-bold text-gray-900">Account</span>
        </div>

        <!-- Bundles Section -->
        <div>
          <div v-if="treecount" class="p-6 bg-green-100 border border-green-300 rounded-lg shadow-md">
            <h3 class="text-lg font-semibold text-green-800 text-center">
              Congratulations! 
            </h3>
            <p class="text-center text-green-700 mt-2">
              You planted <span class="font-bold">{{ treecount }}</span> trees since we started our partnership with <a href="https://ecologi.com" class="underline text-green-800 hover:text-green-900">Ecologi.com</a> 🌳🌳🌳
            </p>
          </div>

          <!-- Modern Tab Navigation -->
<nav
  class="flex flex-wrap justify-center space-x-4 mb-6 mt-6 bg-blue-200 p-2 rounded-lg shadow-inner"
>
  <div
    class="tab cursor-pointer p-2 rounded-lg text-xs flex flex-col items-center space-y-1"
    :class="{ 'bg-blue-500 text-white': category === 1 }"
    @click="setcategory(1)"
  >
    <i class="fas fa-user text-base"></i>
    <span>User Details</span>
  </div>

  <!-- Uncomment this section if you want to add Wash Preferences back -->
  <!-- <div
    class="tab cursor-pointer p-2 rounded-lg text-xs flex flex-col items-center space-y-1"
    :class="{ 'bg-blue-500 text-white': category === 4 }"
    @click="setcategory(4)"
  >
    <i class="fas fa-cog text-base"></i>
    <span>Wash Preferences</span>
  </div> -->

  <div
    class="tab cursor-pointer p-2 rounded-lg text-xs flex flex-col items-center space-y-1"
    :class="{ 'bg-blue-500 text-white': category === 2 }"
    @click="setcategory(2)"
  >
    <i class="fas fa-credit-card text-base"></i>
    <span>Billing</span>
  </div>

  <div
    class="tab cursor-pointer p-2 rounded-lg text-xs flex flex-col items-center space-y-1"
    :class="{ 'bg-blue-500 text-white': category === 3 }"
    @click="setcategory(3)"
  >
    <i class="fas fa-map-marker-alt text-base"></i>
    <span>Addresses</span>
  </div>

  <div
    class="tab cursor-pointer p-2 rounded-lg text-xs flex flex-col items-center space-y-1"
    :class="{ 'bg-blue-500 text-white': category === 5 }"
    @click="setcategory(5)"
  >
    <i class="fas fa-users text-base"></i>
    <span>Referrals</span>
  </div>

  <div
    class="tab cursor-pointer p-2 rounded-lg text-xs flex flex-col items-center space-y-1"
    :class="{ 'bg-blue-500 text-white': category === 6 }"
    @click="setcategory(6)"
  >
    <i class="fas fa-gift text-base"></i>
    <span>Gift Codes</span>
  </div>
</nav>


          <user-view v-if="category === 1"></user-view>
          <billing-view v-if="category === 2"></billing-view>
          <addresses-view v-if="category === 3"></addresses-view>
          <preferences-view v-if="category === 4"></preferences-view>
          <refferals-view v-if="category === 5"></refferals-view>
          <gift-view v-if="category === 6"></gift-view>
        </div>

        <!-- Footer Section -->
        <FooterView />
      </div>
    </div>
  </div>
</template>


<script>
import FooterView from "@/components/Footer.vue"; // Import the footer component
import Sidebar from "@/components/Sidebar.vue"; // Import the sidebar component
import logoDark from "@/assets/logo-dark.png"; // Import logo image
import bellIcon from "@/assets/bell.svg"; // Import bell icon image
import bundles from "@/assets/icons/bundles.png"; // Import bundles image
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import BillingView from "./Account/Billing";
import UserView from "./Account/User";
import PreferencesView from "./Account/Preferences";
import AddressesView from "./Account/Addresses";
import RefferalsView from "./Account/Refferals";
import GiftView from "./Account/Gift";
import MobileHeader from "../components/MobileHeader.vue";
export default {
  components: {
    FooterView,
    BillingView,
		AddressesView,
		PreferencesView,
		UserView,
		RefferalsView,
    GiftView,
    Sidebar,
    MobileHeader
  },
 data() {
	 return {
			 img: {
        logoDark,
        bellIcon,
        bundles,
      },
			loading: true,
			processing: false,
			tab:null,
			category:1,
			treecount:null,
		
		
	user: {
				name: null,
				email: null
			},
			showNewCard: false
		}
	},

  created() {
    const category = parseInt(this.$route.query.category, 10); // Ensure it's an integer
    this.category = category || 1; 
		this.$api.get("/user").then(res => {
			this.user = res.data;
			this.loading = false;
		});
			this.$api.get("/user/ecologycount").then(res => {
		
			this.treecount = res.data;
			
		
		});
	},

	methods: {
		reload() {
			
		},
		
	
	setcategory(i){
	this.category = i;
	
	},
		close() {
			
		},

	
		
	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';

</style>
