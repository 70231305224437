<template>
  <div class="min-h-screen flex bg-gray-100 justify-center items-center pb-[73px]">
    <div class="bg-white w-full max-w-md lg:max-w-4xl h-auto lg:flex rounded-3xl shadow-lg overflow-hidden">

        <!-- Sidebar for Desktop -->
      <Sidebar :img="img" :user="user" />

      <!-- Main Content Section -->
      <div class="flex-1 flex flex-col p-4">
<MobileHeader :img="img" :user="user" />
        <!-- Header Section -->
        <div class="flex items-center justify-between mb-6">
          <span class="text-xl font-bold text-gray-900">Redeem bundle</span>
        </div>

        <!-- Loader -->
        <loader v-if="loading" name="bundle item"></loader>

        <!-- Redeem Section -->
        <div class="flex-1 p-4 overflow-y-auto" v-else>
          <div class="flex items-center justify-center text-center border border-gray-300 rounded-full h-24 w-24 mx-auto mb-6">
            <div>
              <div class="text-2xl font-semibold">{{ selected.item.credits }}</div>
              <div class="text-xs uppercase">{{ selected.item.credits === 1 ? "Credit" : "Credits" }}<br>Left</div>
            </div>
          </div>

          <div class="meta flex flex-col items-center mb-6">
            <div class="text-lg font-semibold text-center">In your <b>{{ selected.item.bundle_name }}</b> bundle</div>
            <div class="text-sm text-gray-600" v-if="selected.item.softener_upgrade">Free Softener Upgrade</div>
          </div>

            <div class="info p-4 border border-gray-300 rounded-lg mb-6 flex justify-between items-center">
            <div>
              <div class="text-lg font-semibold">{{ selected.item.name_string }}</div>
              <div class="text-sm text-gray-600">{{ selected.item.treatment_string }}</div>
              <div class="text-sm text-gray-600">{{ selected.item.whites ? "Whites" : "Colours" }} {{ selected.item.feathers ? "Feather Duvet" : "" }}</div>
            </div>
            <div>
              <input type="radio" name="selection" class="form-radio">
            </div>
          </div>

          <content-view v-model="selected.whites"></content-view>
        </div>

        <!-- Footer Section -->
        <FooterView />
      </div>
    </div>
  </div>
</template>

<script>
import ContentView from "./Content";
import Sidebar from "@/components/Sidebar.vue"; // Import the sidebar component
import FooterView from "@/components/Footer.vue"; // Import the footer component
import logoDark from "@/assets/logo-dark.png"; // Import logo image
import MobileHeader from "@/components/MobileHeader.vue"; // Import the mobile header component
export default {
  props: ["value"],

  components: {
    ContentView,
    FooterView,
    Sidebar,
    MobileHeader
  },

  data() {
    return {
      img: {
        logoDark,
      },
      loading: true, // Whether loading data
      selected: this.value,
      user: {
        name: 'User Name', // Placeholder for user name
        email: 'user@example.com' // Placeholder for user email
      }
    };
  },

  created() {
    let id = this.selected.item.id;
    this.$api.get("/bundle/item/" + id).then((res) => {
      this.loading = false;
      this.selected.item = res.data;
    });
    this.fetchUserData(); // Call to fetch user data
  },

  methods: {
    async fetchUserData() {
      try {
        const res = await this.$api.get("/user");
        if (res.status === 200 && res.data) {
          this.user = res.data;
        } else {
          this.redirectToWelcome();
        }
      } catch (error) {
        this.redirectToWelcome();
      }
    }
  }
};
</script>

<style scoped>
/* Add any specific styling here if needed */
</style>