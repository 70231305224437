<template lang="html">
  <section class="gift flex flex-col p-4 max-w-xl mx-auto pb-[73px]">
    <loader name="your cards" v-if="processing"></loader>

    <div v-if="!giftCode" class="flex flex-col">
      <p class="text mb-4">Here, you can purchase gift code vouchers for your friends and family.</p>
<div class="flex items-center mb-4">
  <span class="mr-2 text-lg font-semibold">Amount:</span>
  <span class="input-pound relative w-full">
    <input
      v-model.number="gift.price"
      :placeholder="gift.price"
      type="number"
      min="12"
      max="100"
      class="details-input border border-blue-500 rounded p-3 pl-8 text-blue-500 w-full"
    />
    <span class="absolute left-3 top-3" style="margin-top: 0.15rem; color: #01bad4;">£</span> 
  </span>
</div>



      <span class="block mb-2">Pick Card:</span>

      <div class="options mb-4" v-if="!processing">
        <div
          class="option cursor-pointer mb-2 border border-gray-300 rounded p-2 bg-white shadow-md"
          :class="{ selected: selected.id === card.id }"
          v-for="card in cards"
          @click="setCard(card)"
        >
          <div class="brand font-semibold">{{ card.brand }}</div>
          <div class="number">•••• •••• •••• {{ card.last4 }}</div>
          <div class="expires text-gray-500">{{ card.expiry_string }}</div>
        </div>
      </div>


<div
    class="address new text-white text-center p-4 mt-3 w-full rounded-lg shadow-md mb-4"
    :class="{
      'bg-gray-300 cursor-not-allowed': processing || !selected.id,
      'bg-[#2ab3cb]': !processing && selected.id
    }"
    @click="handleClick"
  >
    Buy
  </div>
    </div>

    <div v-if="giftCode" class="mt-4">
      <h1 class="text-2xl font-bold mb-2">Here is your gift code:</h1>
      <span class="italic text-lg">(This was also emailed to you)</span>
      <h1 class="font-semibold text-blue-500">{{ giftCode }}</h1>
    </div>
  </section>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      stripe: loadStripe(STRIPE_KEY),
      cards: [],
      giftCode: null,
      selected: { id: null },
      processing: false,
      gift: {
        price: 12,
        appVersion: 31,
      },
      user: {
        name: null,
        email: null,
        id: null,
      },
    };
  },

  created() {
    this.$api.get('/user').then(res => {
      this.user = res.data;
    });
    this.processing = true;

    this.$api.get('/user/cards').then(res => {
      this.processing = false;
      this.cards = res.data.cards;
    });
  },

	methods: {
		setCard(card) {
			this.selected = card;
		},
		handleClick() {
			if (!this.processing && this.selected.id) {
				this.trypay();
			}
		},

    trypay() {
      let vm = this;
      if (Cookies.get('washcycle-gift')) {
        alert('Last order is still processing, please try again in 5 minutes');
        return;
      }

      var inTwentySec = new Date(new Date().getTime() + 0.5 * 6 * 1);
      Cookies.set('washcycle-gift', 'processing', {
        expires: inTwentySec,
      });

      const giftCodeData = { ...this.gift, id: this.selected.id };
      this.processing = true;

      this.$api.post('/gift-code', giftCodeData, {
        redirect: false,
      }).then(res => {
        handleServerResponse(res);
      });

      function handleServerResponse(response) {
        const stripe = Stripe(STRIPE_KEY);
        if (response.error) {
          window.alert(response.error);
        } else if (response.data.error) {
          window.alert(response.data.error);
          vm.processing = false;
        } else if (response.data.requires_action) {
          stripe.handleCardAction(response.data.payment_intent_client_secret).then(function (result) {
            if (result.error) {
              window.alert(result.error.message);
              vm.processing = false;
            } else {
              const giftCodeData = { ...vm.gift, id: vm.selected.id };
              vm.$api.post('/gift-code', {
                payment_intent_id: result.paymentIntent.id,
                giftCodeData,
              }, {
                redirect: false,
              }).then(res => {
                return res.data;
              }).then(function (confirmresult) {
                if (confirmresult.success) {
                  vm.processing = false;
                  vm.giftCode = confirmresult.code;
                } else {
                  vm.processing = false;
                  window.alert(confirmresult.error);
                }
              });
            }
          });
        } else {
          vm.processing = false;
          vm.giftCode = response.data.code;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>


</style>
