var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"p-6 max-w-3xl mx-auto rounded-lg shadow-md pb-[73px]"},[_c('div',[(_vm.loading)?_c('loader',{attrs:{"name":"your preferences"}}):_c('div',[_c('div',{staticClass:"mb-8"},[_c('h2',{staticClass:"text-gray-700 mb-4"},[_vm._v("Select Your Detergent")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"},_vm._l((_vm.preferences.detergent),function(detergent){return _c('div',{staticClass:"option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105 relative",class:[
        detergent.id == _vm.selectedDetergent.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white',
        detergent.disabled === 'true' ? 'opacity-60 cursor-not-allowed' : ''
      ],on:{"click":function($event){return _vm.setPreferences(detergent)}}},[_c('input',{staticClass:"absolute top-2 right-2 w-5 h-5",attrs:{"type":"radio","disabled":detergent.disabled === 'true'},domProps:{"checked":detergent.id === _vm.selectedDetergent.id}}),_vm._v(" "),_c('div',{staticClass:"text-gray-900"},[_vm._v(_vm._s(detergent.name))]),_vm._v(" "),_c('div',{staticClass:"text-[#2ab3cb]"},[_vm._v(_vm._s(_vm._f("formatPrice")(detergent.price)))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"mb-8"},[_c('h2',{staticClass:"text-gray-700 mb-4"},[_vm._v("Choose a Softener")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"},_vm._l((_vm.preferences.softener),function(softener){return _c('div',{staticClass:"option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105",class:[
                softener.id == _vm.selectedSoftener.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white',
                softener.disabled === 'true' ? 'opacity-60 cursor-not-allowed' : ''
              ],on:{"click":function($event){return _vm.setPreferences(softener)}}},[_c('input',{staticClass:"absolute top-2 right-2 w-5 h-5",attrs:{"type":"radio","disabled":softener.disabled === 'true'},domProps:{"checked":softener.id === _vm.selectedSoftener.id}}),_vm._v(" "),_c('div',{staticClass:"text-gray-900"},[_vm._v(_vm._s(softener.name))]),_vm._v(" "),_c('div',{staticClass:"text-gray-600 text-sm"},[_vm._v(_vm._s(softener.Description))]),_vm._v(" "),_c('div',{staticClass:"text-[#2ab3cb]"},[_vm._v(_vm._s(_vm._f("formatPrice")(softener.price)))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"mb-8"},[_c('h2',{staticClass:"text-gray-700 mb-4"},[_vm._v("Select Stain Removal")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"},_vm._l((_vm.preferences.stain),function(stain){return _c('div',{staticClass:"option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105",class:[
                stain.id == _vm.selectedStain.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white',
                stain.disabled === 'true' ? 'opacity-60 cursor-not-allowed' : ''
              ],on:{"click":function($event){return _vm.setPreferences(stain)}}},[_c('input',{staticClass:"absolute top-2 right-2 w-5 h-5",attrs:{"type":"radio","disabled":stain.disabled === 'true'},domProps:{"checked":stain.id === _vm.selectedStain.id}}),_vm._v(" "),_c('div',{staticClass:"text-gray-900"},[_vm._v(_vm._s(stain.name))]),_vm._v(" "),_c('div',{staticClass:"text-gray-600 text-sm"},[_vm._v(_vm._s(stain.Description))]),_vm._v(" "),_c('div',{staticClass:"text-[#2ab3cb]"},[_vm._v(_vm._s(_vm._f("formatPrice")(stain.price)))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"mb-8"},[_c('h2',{staticClass:"text-gray-700 mb-4"},[_vm._v("Choose Drying Option")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"},_vm._l((_vm.preferences.drying),function(drying){return _c('div',{staticClass:"option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105",class:[
                drying.id == _vm.selectedDrying.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white'
              ],on:{"click":function($event){return _vm.setPreferences(drying)}}},[_c('input',{staticClass:"absolute top-2 right-2 w-5 h-5",attrs:{"type":"radio"},domProps:{"checked":drying.id === _vm.selectedDrying.id}}),_vm._v(" "),_c('div',{staticClass:"text-gray-900"},[_vm._v(_vm._s(drying.name))]),_vm._v(" "),_c('div',{staticClass:"text-[#2ab3cb]"},[_vm._v(_vm._s(_vm._f("formatPrice")(drying.price)))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"mb-8"},[_c('h2',{staticClass:"text-gray-700 mb-4"},[_vm._v("Select Washing Type")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"},_vm._l((_vm.preferences.washing),function(washing){return _c('div',{staticClass:"option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105",class:[
                washing.id == _vm.selectedWashing.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white'
              ],on:{"click":function($event){return _vm.setPreferences(washing)}}},[_c('input',{staticClass:"absolute top-2 right-2 w-5 h-5",attrs:{"type":"radio"},domProps:{"checked":washing.id === _vm.selectedWashing.id}}),_vm._v(" "),_c('div',{staticClass:"text-gray-900"},[_vm._v(_vm._s(washing.name))]),_vm._v(" "),_c('div',{staticClass:"text-gray-900 text-sm"},[_vm._v(_vm._s(washing.description))]),_vm._v(" "),_c('div',{staticClass:"text-[#2ab3cb]"},[_vm._v(_vm._s(_vm._f("formatPrice")(washing.price)))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"mt-6 flex justify-center items-center"},[_c('div',{staticClass:"text-lg text-gray-700 inline-block mr-2"},[_vm._v("Total:")]),_vm._v(" "),_c('div',{staticClass:"text-center text-[#2ab3cb] font-bold inline-block"},[_vm._v("\n            "+_vm._s(_vm._f("formatPrice")(_vm.totalCost))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"save mt-8 px-6 py-4 text-white text-lg roundedcursor-pointer hover:bg-[#2ab3cb] transition-colors duration-300 text-center",on:{"click":function($event){return _vm.updatePreferencesconfirm()}}},[_vm._v("\n          SAVE PREFERENCES\n        ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }