<template lang="html">
<section class="standalone w-full  bg-gray-50 rounded-3xl ">
  <!-- Header -->
    <div class="w-full px-4 py-3 bg-white border-b border-gray-100 flex justify-center items-center gap-2">
      <div class="w-6 h-6 flex flex-wrap items-center justify-center cursor-pointer" @click="emmitBack()">
        <img :src="img.backArrow" class="w-6 h-6 rounded-full" alt="Back">
      </div>
      <div class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Order Summary 
      </div>
    </div>

  <!-- Main Content -->
  <div class="p-4 space-y-4">
    <!-- Collection/Delivery Time -->
    <div class="bg-white p-4 rounded-lg shadow-sm">
      <div class="text-sm font-bold text-[#111826] mb-2">Select Collection/Delivery Time & Date</div>
      <div class="flex items-center gap-4">
        <div class="bg-[#2ab3cb]/20 border border-[#2ab3cb]/20 rounded-lg p-2">
          <div class="w-8 h-8"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.965 18C8.84612 18.8343 8.43021 19.5977 7.79368 20.1499C7.15714 20.7022 6.34272 21.0063 5.5 21.0063C4.65728 21.0063 3.84286 20.7022 3.20632 20.1499C2.56979 19.5977 2.15388 18.8343 2.035 18H1V6C1 5.73478 1.10536 5.48043 1.29289 5.29289C1.48043 5.10536 1.73478 5 2 5H16C16.2652 5 16.5196 5.10536 16.7071 5.29289C16.8946 5.48043 17 5.73478 17 6V8H20L23 12.056V18H20.965C20.8461 18.8343 20.4302 19.5977 19.7937 20.1499C19.1571 20.7022 18.3427 21.0063 17.5 21.0063C16.6573 21.0063 15.8429 20.7022 15.2063 20.1499C14.5698 19.5977 14.1539 18.8343 14.035 18H8.965ZM15 7H3V15.05C3.39456 14.6472 3.8806 14.3457 4.41675 14.1711C4.9529 13.9966 5.52329 13.9541 6.07938 14.0474C6.63546 14.1407 7.16077 14.3669 7.61061 14.7069C8.06044 15.0469 8.42148 15.4905 8.663 16H14.337C14.505 15.647 14.73 15.326 15 15.05V7ZM17 13H21V12.715L18.992 10H17V13ZM17.5 19C17.898 19 18.2796 18.8419 18.561 18.5605C18.8424 18.2791 19.0005 17.8975 19.0005 17.4995C19.0005 17.1015 18.8424 16.7199 18.561 16.4385C18.2796 16.1571 17.898 15.999 17.5 15.999C17.102 15.999 16.7204 16.1571 16.439 16.4385C16.1576 16.7199 15.9995 17.1015 15.9995 17.4995C15.9995 17.8975 16.1576 18.2791 16.439 18.5605C16.7204 18.8419 17.102 19 17.5 19ZM7 17.5C7 17.303 6.9612 17.108 6.88582 16.926C6.81044 16.744 6.69995 16.5786 6.56066 16.4393C6.42137 16.3001 6.25601 16.1896 6.07403 16.1142C5.89204 16.0388 5.69698 16 5.5 16C5.30302 16 5.10796 16.0388 4.92597 16.1142C4.74399 16.1896 4.57863 16.3001 4.43934 16.4393C4.30005 16.5786 4.18956 16.744 4.11418 16.926C4.0388 17.108 4 17.303 4 17.5C4 17.8978 4.15804 18.2794 4.43934 18.5607C4.72064 18.842 5.10218 19 5.5 19C5.89782 19 6.27936 18.842 6.56066 18.5607C6.84196 18.2794 7 17.8978 7 17.5Z" fill="#081F3F"/>
</svg>
</div>
        </div>
        <div class="flex-1">
          <div class="text-xs text-zinc-500">We’ll Collect</div>
          <div class="text-sm font-bold text-zinc-900">    {{ formattedSlot }}</div>
        </div>
        <button  @click="emmitBack()" class="px-3 py-1.5 text-xs font-bold text-[#081f3f] border rounded-lg shadow-sm">
          Edit
        </button>
      </div>
    </div>

    <!-- Address -->
    <div class="bg-white p-4 rounded-lg shadow-sm">
      <div class="flex justify-between items-center mb-3">
        <div class="text-sm font-bold text-[#111826]">Your Address</div>
        <button @click="showNewAddress = true" class="px-3 py-1.5 text-xs font-bold text-[#081f3f] border rounded-lg shadow-sm">
          Add New
        </button>
      </div>
<div class="overflow-x-auto mb-6">
  <select 
    v-model="selected.address" 
    class="font-['Manrope'] text-sm mb-2 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb] sm:text-sm">
    <option 
      v-for="address in addresses" 
      :key="address.id" 
      :value="address" 
      class="font-['Manrope'] text-sm whitespace-normal">
      {{ address.name }} {{ address.address_line_1 }} {{ address.city }} {{ address.postcode }}
    </option>
  </select>
</div>
    </div>


    <!-- Order Summary -->
<div class="bg-white p-4 rounded-lg shadow-sm">
  <div class="text-sm font-bold text-[#111826] mb-2">Order Summary</div>
  <div class="flex justify-between text-sm">
    <div>{{ selected.load.name_string }}</div>
    <div class="font-bold text-zinc-900">£{{ selected.load.price }}</div>
  </div>
  <div class="flex justify-between text-sm border-b pb-2 mt-2">
    <div>Addons</div>
    <div class="font-bold text-zinc-900">£{{ calculateAddons.toFixed(2) }}</div>
  </div>

  <div class="flex justify-between mt-4 text-sm font-bold">
    <div>Total</div>
    <div class="text-base">£{{ total.toFixed(2) }}</div>
  </div>
</div>
  </div>

  <!-- Footer -->
  <div class="w-full p-4 bg-white border-t border-gray-200 flex flex-wrap gap-4">
       
    <!-- View Cart Button -->
<button 
  @click="addToCart" 
  :disabled="!selected.address"
  :class="[
    'flex-grow h-12 px-6 w-full py-3 font-bold rounded-lg shadow-sm transition-transform duration-300',
    !selected.address ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#2ab3cb] text-black',
    buttonClicked ? 'scale-105 bg-green-400 text-white' : ''
  ]">
  <span v-if="buttonClicked" class="mr-2">✓</span>
 Add To Cart
</button>
    <!-- Add To Cart Button -->
<button  @click="addAnother" 
      :disabled="!selected.address" 
      :class="[
        'flex-grow h-12 px-6 py-3 font-bold rounded-lg shadow-sm',
        !selected.address ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#2ab3cb] text-black'
      ]">
      Add Another 
    </button>
 
        <button @click="changeView" 
      :disabled="!selected.address" 
      :class="[
        'flex-grow h-12 px-6 py-3 font-bold rounded-lg shadow-sm',
        !selected.address ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#081F3F] text-white'
      ]">
      Finish and Pay
    </button>
  </div>
    <new-address v-if="showNewAddress" @close="close"></new-address>

</section>
</template>

<script>
import NewAddress from "../../components/NewAddress";
import backArrow from "assets/backarrow.svg";

export default {
	components: {
    NewAddress
	},

	props: [
		"value"
	],

	data() {
    return {
      img: {backArrow},
			loading: true, // Whether loading AJAX data
			cards: [],
			selected: this.value,
      showNewCard: false,
      buttonClicked:false,
      showNewAddress: false,
      addresses: [],
      addons: 0,
      orderPrice:0,
		}
	},

	created() {
		this.reload();
	},

  methods: {
    changeView() {
    this.$emit("input", this.selected);
		this.$emit("changeView");
    },
    addAnother() {
      this.addons =  0,
      this.orderPrice=0,
      this.selected = {
    load: {},
    type: 'clothes',
    whites: false,
    feathers: false,
    				ownBag:false,
				kidsClothes: false,
    slot: {
      start: null,
      end: null,
    },
    address: null,
    card: {
      id: null,
    },
    softener: {
      type: 0,  // Default to no softener preference
    },
  };
			this.$emit("addAnother");
    },
addToCart() {
  this.buttonClicked = true;
  this.$emit("input", this.selected);
  this.$emit("addToCart");
  setTimeout(() => {
    this.buttonClicked = false;
  }, 1000); // Button stays in the "clicked" state for 2 seconds (adjust as needed)
},
		reload() {
			this.$api.get("/user/cards").then(res => {
				this.loading = false;
				this.cards = res.data.cards;
      });
        this.$api.get("/user/addresses").then(res => {
        let { addresses, preferred } = res.data;

        this.loading = false;
        this.addresses = addresses;
      });
		},

		editCards() {
			this.$router.push("/billing");
		},
   emmitBack() {
      this.$emit("back");
    },
		close() {
      this.showNewCard = false;
      this.showNewAddress = false;
			this.reload();
    },

  },

  computed: {
    formattedSlot() {
       const startDate = new Date(this.selected.slot.start);
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayOfWeek = daysOfWeek[startDate.getDay()];
      const day = startDate.getDate()
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = months[startDate.getMonth()];
      const year = startDate.getFullYear();
      const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}`;
      return `${formattedDate}, ${this.selected.slot.time_string}`;
    },
    calculateAddons() {
      const preferencesCost = this.selected.preferencesTotal || 0;
      const express = this.selected.category === 'express' ? 6 : 0;
      const kidsClothesCost = this.selected.kidsClothes ? 2.0 : 0;
      const feathersCost = this.selected.feather ? 2.0 : 0;
      const ownBagDiscount = this.selected.ownBag ? -0.2 : 0;
      this.addons = Math.round((preferencesCost + kidsClothesCost + express + feathersCost + ownBagDiscount) * 100) / 100;
      this.selected.addons = this.addons
      this.$emit("input", this.selected);
      return this.addons;
    },
    // Calculate total price
    total() {
      this.orderPrice = Math.round((this.selected.load.price + this.addons) * 100) / 100;
      this.selected.orderPrice = this.orderPrice
      this.$emit("input", this.selected);
      return this.orderPrice
    },
  }
}
</script>

<style lang="less" scoped>
</style>
