<template lang="html">
  <section class="p-6 max-w-3xl mx-auto  rounded-lg shadow-md pb-[73px]">
    <div>
      <loader v-if="loading" name="your preferences"></loader>
      <div v-else>
        <!-- Detergent Section -->
<div class="mb-8">
  <h2 class="text-gray-700 mb-4">Select Your Detergent</h2>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    <div 
      class="option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105 relative"
      :class="[
        detergent.id == selectedDetergent.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white',
        detergent.disabled === 'true' ? 'opacity-60 cursor-not-allowed' : ''
      ]"
      v-for="detergent in preferences.detergent" 
      @click="setPreferences(detergent)"
    >
      <!-- Radio button positioned in the top-right corner -->
      <input type="radio" 
             :disabled="detergent.disabled === 'true'" 
             class="absolute top-2 right-2 w-5 h-5"
             :checked="detergent.id === selectedDetergent.id" />

      <div class="text-gray-900">{{ detergent.name }}</div>
      <div class="text-[#2ab3cb]">{{ detergent.price | formatPrice }}</div>
    </div>
  </div>
</div>


        <!-- Softener Section -->
        <div class="mb-8">
          <h2 class="  text-gray-700 mb-4">Choose a Softener</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div 
              class="option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105"
              :class="[
                softener.id == selectedSoftener.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white',
                softener.disabled === 'true' ? 'opacity-60 cursor-not-allowed' : ''
              ]"
              v-for="softener in preferences.softener" 
              @click="setPreferences(softener)"
            >
                  <!-- Radio button positioned in the top-right corner -->
              <input type="radio" 
             :disabled="softener.disabled === 'true'" 
             class="absolute top-2 right-2 w-5 h-5"
             :checked="softener.id === selectedSoftener.id" />
              <div class=" text-gray-900">{{ softener.name }}</div>
              <div class="text-gray-600 text-sm">{{ softener.Description }}</div>
              <div class="text-[#2ab3cb] ">{{ softener.price | formatPrice }}</div>
            </div>
          </div>
        </div>

        <!-- Stain Removal Section -->
        <div class="mb-8">
          <h2 class="  text-gray-700 mb-4">Select Stain Removal</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div 
              class="option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105"
              :class="[
                stain.id == selectedStain.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white',
                stain.disabled === 'true' ? 'opacity-60 cursor-not-allowed' : ''
              ]"
              v-for="stain in preferences.stain" 
              @click="setPreferences(stain)"
            >
            <input type="radio" 
             :disabled="stain.disabled === 'true'" 
             class="absolute top-2 right-2 w-5 h-5"
             :checked="stain.id === selectedStain.id" />
              <div class=" text-gray-900">{{ stain.name }}</div>
              <div class="text-gray-600 text-sm">{{ stain.Description }}</div>
              <div class="text-[#2ab3cb] ">{{ stain.price | formatPrice }}</div>
            </div>
          </div>
        </div>

        <!-- Drying Section -->
        <div class="mb-8">
          <h2 class="  text-gray-700 mb-4">Choose Drying Option</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div 
              class="option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105"
              :class="[
                drying.id == selectedDrying.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white'
              ]"
              v-for="drying in preferences.drying" 
              @click="setPreferences(drying)"
            >
            <input type="radio" 
             class="absolute top-2 right-2 w-5 h-5"
             :checked="drying.id === selectedDrying.id" />
              <div class=" text-gray-900">{{ drying.name }}</div>
              <div class="text-[#2ab3cb] ">{{ drying.price | formatPrice }}</div>
            </div>
          </div>
        </div>

        <!-- Washing Section -->
        <div class="mb-8">
          <h2 class="  text-gray-700 mb-4">Select Washing Type</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div 
              class="option border rounded-lg p-6 cursor-pointer transition-transform transform hover:scale-105"
              :class="[
                washing.id == selectedWashing.id ? 'border-[#2ab3cb] bg-blue-100' : 'border-gray-300 bg-white'
              ]"
              v-for="washing in preferences.washing" 
              @click="setPreferences(washing)"
            >
            <input type="radio" 
             class="absolute top-2 right-2 w-5 h-5"
             :checked="washing.id === selectedWashing.id" />
              <div class=" text-gray-900">{{ washing.name }}</div>
              <div class="text-gray-900 text-sm">{{ washing.description }}</div>
              <div class="text-[#2ab3cb] ">{{ washing.price | formatPrice }}</div>
            </div>
          </div>
        </div>
        
        <!-- Total Preferences -->
  <div class="mt-6 flex justify-center items-center">
          <div class="text-lg text-gray-700 inline-block mr-2">Total:</div>
          <div class="text-center text-[#2ab3cb] font-bold inline-block">
            {{ totalCost | formatPrice }}
          </div>
        </div>

        <!-- Save Button -->
        <div 
          @click="updatePreferencesconfirm()" 
          class="save mt-8 px-6 py-4  text-white text-lg roundedcursor-pointer hover:bg-[#2ab3cb] transition-colors duration-300 text-center"
        >
          SAVE PREFERENCES
        </div>
      </div>
    </div>
  </section>
</template>




<script>
import Vue from "vue";
export default {
  components: {},
  data() {
    return {
      loading: true,
      preferences: null,
      totalCost: 0,
      selectedDetergent: { id: "", name: "", description: "", price: 0, type: "", disabled: "" },
      selectedDrying: { id: "", name: "", description: "", price: 0, type: "", disabled: "" },
      selectedSoftener: { id: "", name: "", description: "", price: 0, type: "", disabled: "" },
      selectedStain: { id: "", name: "", description: "", price: 0, type: "", disabled: "" },
      selectedWashing: { id: "", name: "", description: "", price: 0, type: "", disabled: "" },
    };
  },
  async created() {
    try {
      const preferencesResponse = await this.$api.get("/preferences/all");
      this.preferences = preferencesResponse.data;
      this.loading = false;

      const userPreferencesResponse = await this.$api.get("/user/preferences");
      const userPreferences = userPreferencesResponse.data[0];

      this.selectedDetergent.id = userPreferences.detergent_id;
      this.selectedDrying.id = userPreferences.drying_id;
      this.selectedSoftener.id = userPreferences.softener_id;
      this.selectedStain.id = userPreferences.stain_id;
      this.selectedWashing.id = userPreferences.washing_id;

      let matchedID;

      matchedID = this.preferences.detergent.filter(item => item.id.indexOf(userPreferences.detergent_id) !== -1);
      this.selectedDetergent.price = matchedID[0].price;
      this.selectedDetergent.disabled = matchedID[0].disabled;

      matchedID = this.preferences.drying.filter(item => item.id.indexOf(userPreferences.drying_id) !== -1);
      this.selectedDrying.price = matchedID[0].price;
      this.selectedDrying.disabled = matchedID[0].disabled;

      matchedID = this.preferences.softener.filter(item => item.id.indexOf(userPreferences.softener_id) !== -1);
      this.selectedSoftener.price = matchedID[0].price;
      this.selectedSoftener.disabled = matchedID[0].disabled;

      matchedID = this.preferences.washing.filter(item => item.id.indexOf(userPreferences.washing_id) !== -1);
      this.selectedWashing.price = matchedID[0].price;
      this.selectedWashing.disabled = matchedID[0].disabled;

      matchedID = this.preferences.stain.filter(item => item.id.indexOf(userPreferences.stain_id) !== -1);
      this.selectedStain.price = matchedID[0].price;
      this.selectedStain.disabled = matchedID[0].disabled;

      this.PreferencesCost();
    } catch (error) {
      console.error(error);
    }
  },
  filters: {
    formatPrice: price => "£" + price.toFixed(2),
  },
  methods: {
    PreferencesCost() {
      this.totalCost =
        this.selectedDetergent.price +
        this.selectedDrying.price +
        this.selectedSoftener.price +
        this.selectedWashing.price +
        this.selectedStain.price;
    },
    updatePreferencesconfirm() {
      const PreferencesToUpdate = {
        detergent_id: this.selectedDetergent.id,
        drying_id: this.selectedDrying.id,
        softener_id: this.selectedSoftener.id,
        stain_id: this.selectedStain.id,
        washing_id: this.selectedWashing.id,
        cost: this.totalCost,
      };

      this.$api.post("/user/updatepreferences", PreferencesToUpdate).then(res => {
        if (res.status === '200') {
          window.alert("Preferences Saved");
        } else {
          window.alert("Something went wrong, please try again");
        }
      });
    },
    setPreferences(setSelected) {
      // Do not update if disabled
      if (setSelected.disabled === 'true') {
        return;
      }
      if (setSelected.type === "detergent") {
        this.selectedDetergent.id = setSelected.id;
        const MatchedID = this.preferences.detergent.filter(item => item.id.indexOf(setSelected.id) !== -1);
        this.selectedDetergent.price = MatchedID[0].price;
        this.PreferencesCost();
      } else if (setSelected.type === "drying") {
        this.selectedDrying.id = setSelected.id;
        const MatchedID = this.preferences.drying.filter(item => item.id.indexOf(setSelected.id) !== -1);
        this.selectedDrying.price = MatchedID[0].price;
        this.PreferencesCost();
      } else if (setSelected.type === "softener") {
        this.selectedSoftener.id = setSelected.id;
        const MatchedID = this.preferences.softener.filter(item => item.id.indexOf(setSelected.id) !== -1);
        this.selectedSoftener.price = MatchedID[0].price;
        this.PreferencesCost();
      } else if (setSelected.type === "stain") {
        this.selectedStain.id = setSelected.id;
        const MatchedID = this.preferences.stain.filter(item => item.id.indexOf(setSelected.id) !== -1);
        this.selectedStain.price = MatchedID[0].price;
        this.PreferencesCost();
      } else if (setSelected.type === "washing") {
        this.selectedWashing.id = setSelected.id;
        const MatchedID = this.preferences.washing.filter(item => item.id.indexOf(setSelected.id) !== -1);
        this.selectedWashing.price = MatchedID[0].price;
        this.PreferencesCost();
      }
    },
  },
};
</script>

<style scoped>
.option:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.save {
  transition: background-color 0.2s ease;
}
</style>
