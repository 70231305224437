    <template>
          <div class="p-6 max-lg:flex items-center justify-between hidden">
            <div class="flex items-center justify-between w-full">
              <!-- Logo -->
              <img :src="img.logoDark" class="w-14 h-14 rounded-lg" alt="Logo">

              <!-- Text -->
              <div class="flex-grow mx-4">
                <p class="text-xl font-bold text-gray-900">Hi {{ user.name }}</p>
                <p class="text-sm text-gray-600">Welcome to your Wash Cycle.</p>
              </div>
            </div>
          </div>
    </template>
    
    <script>
    export default {
      name: 'MobileHeader',
      props: {
        img: {
          type: Object,
          required: true
        },
        user: {
          type: Object,
          required: true
        }
      }
    }
    </script>
    
    <style scoped>
    /* Add any component-specific styles here */
    </style>