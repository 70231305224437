import { render, staticRenderFns } from "./Ownbag.vue?vue&type=template&id=8c28d438&scoped=true&"
import script from "./Ownbag.vue?vue&type=script&lang=js&"
export * from "./Ownbag.vue?vue&type=script&lang=js&"
import style0 from "./Ownbag.vue?vue&type=style&index=0&id=8c28d438&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c28d438",
  null
  
)

export default component.exports