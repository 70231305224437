<template lang="html">
<section class="addresses">
	<div class="header"><span>Choose a collection and delivery address</span><span class="link" @click="editAddresses">Edit addresses</span></div>

	<loader v-if="loading" name="your addresses"></loader>
	<div class="options" v-else>
		<div class="option" :class="{ selected: selected.id === address.id }" v-for="address in addresses" @click="setAddress(address)">
			<div class="name">{{ address.name }}</div>
			<div class="desc">{{ address.string }}<span v-if="address.phone">, {{ address.phone }}</span></div>
		</div>
		<div class="option dashed" @click="showNewAddress = true">Add a new address</div>
	</div>

	<new-address @close="close" v-if="showNewAddress"></new-address>
</section>
</template>

<script>
import NewAddress from "../../components/NewAddress";

export default {
	props: [
		"value"
	],

	components: {
		NewAddress
	},

	data() {
		return {
			loading: true, // Loading AJAX data
			addresses: [], // The available addresses
			selected: this.value,

			showNewAddress: false
		}
	},

	created() {
		this.reload();
	},

	methods: {
		reload() {
			this.$api.get("/user/addresses").then(res => {
				this.loading = false;
				this.addresses = res.data.addresses;
			});
		},

		setAddress(address) {
			this.selected = address;
			this.$emit("input", address);
		},

		editAddresses() {
			this.$router.push("/addresses");
		},

		close() {
			this.showNewAddress = false;
			this.reload();
		}
	}
}
</script>

<style lang="less" scoped>
section.addresses {
	.options {
		display: block;
	}

	.option {
		display: flex;
		flex-direction: row;
		text-align: left;
		align-items: left;
		flex-grow: unset;

		.name {
			font-size: 14px;
			font-weight: 500;
		}

		.desc {
			max-width: none;
			margin: 0;
			margin-left: 10px;
			margin-right: auto; 
		}
	}
}
</style>
