// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.addresses .options[data-v-00db055d] {
  display: block;
}
section.addresses .option[data-v-00db055d] {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: left;
  flex-grow: unset;
}
section.addresses .option .name[data-v-00db055d] {
  font-size: 14px;
  font-weight: 500;
}
section.addresses .option .desc[data-v-00db055d] {
  max-width: none;
  margin: 0;
  margin-left: 10px;
  margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/views/Order/Address.vue"],"names":[],"mappings":"AACA;EAEE,cAAA;AADF;AADA;EAME,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFF;AARA;EAaG,eAAA;EACA,gBAAA;AAFH;AAZA;EAkBG,eAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;AAHH","sourcesContent":["\nsection.addresses {\n\t.options {\n\t\tdisplay: block;\n\t}\n\n\t.option {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\ttext-align: left;\n\t\talign-items: left;\n\t\tflex-grow: unset;\n\n\t\t.name {\n\t\t\tfont-size: 14px;\n\t\t\tfont-weight: 500;\n\t\t}\n\n\t\t.desc {\n\t\t\tmax-width: none;\n\t\t\tmargin: 0;\n\t\t\tmargin-left: 10px;\n\t\t\tmargin-right: auto; \n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
