<template lang="html">
<section class="flex items-center justify-center min-h-screen bg-gray-100">
  <div class="relative w-full max-w-lg bg-white rounded-3xl shadow-lg p-8 space-y-6">

    <div class="flex justify-between items-center mb-6">
      <!-- Back Arrow -->
      <div class="w-6 h-6 flex justify-center items-center">
        <img :src="backArrow" alt="Back Arrow" class="w-full h-full object-contain cursor-pointer" @click="home" />
      </div>
      
      <!-- Text Content -->
      <div class="flex items-center gap-1">
        <span class="text-[#081f3f] text-sm font-bold font-['Manrope'] leading-normal tracking-tight cursor-pointer">Already have an account?</span>
        <span class="text-[#081f3f] text-sm font-bold font-['Manrope'] underline leading-normal tracking-tight"></span>
        <span class="text-[#2ab3cb] text-sm font-bold font-['Manrope'] underline leading-normal tracking-tight cursor-pointer" @click="loginClick">Sign in</span>
      </div>
    </div>

    <div class="text-center">
      <h1 class="text-zinc-900 text-2xl font-bold font-['Manrope'] leading-9 tracking-tight">Register</h1>
      <p class="text-zinc-700 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mt-2">
        Fill out the form below to register.
      </p>
    </div>

    <!-- Warning Message -->
    <div v-if="warning" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
      {{ warning }}
    </div>

    <div>
      <label for="fullName" class="block text-zinc-900 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mb-1">Full Name</label>
      <input id="fullName" type="text" placeholder="Enter your full name" class="w-full px-3 py-2 bg-white rounded-xl border border-[#d2d6db] text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight" v-model="user.name"/>
    </div>

    <div>
      <label for="email" class="block text-zinc-900 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mb-1">Email</label>
      <input id="email" type="email" placeholder="Enter your email" class="w-full px-3 py-2 bg-white rounded-xl border border-[#d2d6db] text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight" v-model="user.email"/>
    </div>

    <div>
      <label for="password" class="block text-zinc-900 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mb-1">Password</label>
      <input id="password" type="password" placeholder="Enter your password" class="w-full px-3 py-2 bg-white rounded-xl border border-[#d2d6db] text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight" v-model="user.password"/>
    </div>

    <div>
      <label for="confirmPassword" class="block text-zinc-900 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mb-1">Confirm Password</label>
      <input id="confirmPassword" type="password" placeholder="Confirm your password" class="w-full px-3 py-2 bg-white rounded-xl border border-[#d2d6db] text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight" v-model="user.confirmPassword"/>
    </div>

    <div>
      <label for="postcode" class="block text-zinc-900 text-sm font-medium font-['Manrope'] leading-normal tracking-tight mb-1">Postcode</label>
      <input id="postcode" type="text" placeholder="Enter your postcode" class="w-full px-3 py-2 bg-white rounded-xl border border-[#d2d6db] text-zinc-500 text-sm font-medium font-['Manrope'] leading-normal tracking-tight" v-model="user.postcode"/>
      <!-- Postcode Warning -->
      <div v-if="postcodeWarning" class="mt-2 text-xs text-red-600">
        {{ postcodeWarning }}
      </div>
    </div>

    <button @click="submit" :disabled="!signupReady" :class="signupReady ? 'bg-[#2AB3CB] text-white' : 'bg-gray-100 text-zinc-300 cursor-not-allowed'" class="w-full px-6 py-3 rounded-lg shadow border text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
      Sign Up
    </button>

    <div class="text-center">
      <p class="text-zinc-700 text-sm font-medium font-['Manrope'] leading-tight">
        Already have an account? 
        <a class="text-[#2ab3cb] text-sm font-bold font-['Manrope'] underline cursor-pointer" @click="loginClick">Sign In</a>
      </p>
    </div>
  </div>
</section>
</template>
<script>
const DEV = process.env.NODE_ENV !== "production";
import logoDark from "../assets/logo-dark.png"
import backArrow from "../assets/icons/blackArrow.png"
export default {
	data() {
		return {
			backArrow,
			logoDark,
			user: {
				email: "",
				password: "",
				postcode: "",
				name: "",
				confirmPassword: ""
			},

			warning: null,
			postcodeWarning: null
		}
	},

	methods: {
		home() {
			this.$router.push("/welcome");
		},
		loginClick() {
			this.$router.push("/login");
		},
		registerClick() {
			this.$router.push("/register");
		},
		validateEmail(email) {
			// Regular expression for validating email format
			const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailPattern.test(email);
		},
		submit() {
			this.warning = null;

			// Validate Email
			if (!this.validateEmail(this.user.email)) {
				this.warning = "Please enter a valid email address.";
				return;
			}

			// Proceed with signup if all conditions are met
			if (this.signupReady) {
				this.processing = true;
				this.$api.post("/user/signup", this.user, { redirect: false }).then(res => {
					this.processing = false;
					if (!DEV) {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({ event: "User Created" });
					}
					if (res.status !== 201) {
						this.warning = res.data;
						return;
					}
					this.$router.push("/hi");
				});
			}
		}
	},
	created() {
		let existingMetaTag = document.querySelector('meta[name="description"]');
		this.warning = null;
		this.warning = null;
		this.login = true;
		this.forgot = false;
		this.register = false;
		document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Login";
		existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff! - Login");
		// Check if already logged in and redirect
		this.$api.get("/user", { redirect: false }).then(res => {
			if (res.status !== 401) this.home();
		});
	},
	computed: {
		signupReady() {

			let { user } = this;
			let ready = true;
			let validPostcodes = ["cf24", "cf23", "cf3", "cf95", "cf99", "cf91", "cf10", "cf11", "cf30", "cf14", "cf64", "cf5", "cf5 ", "cf3 "];
			let firstFour = user.postcode.substring(0, 4).toLowerCase();
			if (user.postcode.length > 4 && !validPostcodes.includes(firstFour)) {
				this.postcodeWarning = "Sorry we don't operate in your area yet."
				ready = false;
			} else this.postcodeWarning = null;
			if (!validPostcodes.includes(firstFour)) ready = false;
			if (user.name < 3) ready = false;
			if (user.postcode < 3) ready = false;
			if (user.password < 4) ready = false;
			if (user.password !== user.confirmPassword) ready = false;
			return ready;
		}
	}
}
</script>

<style lang="less" scoped>
/* Ensure responsive scaling for logo and back arrow */
@media (max-width: 640px) {
  .login img {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 641px) {
  .login img {
    width: 40px;
    height: 40px;
  }
}
</style>
