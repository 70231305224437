// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.house .options.people .option[data-v-bd7b8c3e] {
  flex-grow: unset;
}
`, "",{"version":3,"sources":["webpack://./src/views/Order/House.vue","webpack://./House.vue"],"names":[],"mappings":"AACA;EAEE,gBAAA;ACDF","sourcesContent":["\nsection.house {\n\t.options.people .option {\n\t\tflex-grow: unset;\n\t}\n}\n","section.house .options.people .option {\n  flex-grow: unset;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
