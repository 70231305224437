import { render, staticRenderFns } from "./Addresses.vue?vue&type=template&id=971546c6&scoped=true&lang=html&"
import script from "./Addresses.vue?vue&type=script&lang=js&"
export * from "./Addresses.vue?vue&type=script&lang=js&"
import style0 from "./Addresses.vue?vue&type=style&index=0&id=971546c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "971546c6",
  null
  
)

export default component.exports