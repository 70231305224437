<template lang="html">
<section class="standalone w-full  bg-gray-50 rounded-3xl ">
  <!-- Header -->
    <div class="w-full px-4 py-3 bg-white border-b border-gray-100 flex justify-center items-center gap-2">
      <div class="w-6 h-6 flex flex-wrap items-center justify-center cursor-pointer" @click="emmitBack()">
        <img :src="img.backArrow" class="w-6 h-6 rounded-full" alt="Back">
      </div>
      <div class="flex-grow text-center text-zinc-900 text-base font-bold font-['Manrope'] leading-7 tracking-tight">
        Order Summary 
      </div>
    </div>

  <!-- Main Content -->
  <div class="p-4 space-y-4">
<div class="bg-white p-4 rounded-lg shadow-sm">
  <div class="text-sm font-bold text-[#111826] mb-2">Order Summary</div>
  <div class="flex justify-between text-sm">
    <div>{{ selected.load.name_string }}</div>
    <div class="font-bold text-zinc-900">£{{ selected.load.price }}</div>
  </div>
  <div class="flex justify-between text-sm border-b pb-2 mt-2">
    <div>Addons</div>
    <div class="font-bold text-zinc-900">£{{ calculateAddons.toFixed(2) }}</div>
  </div>

  <div class="flex justify-between mt-4 text-sm font-bold">
    <div>Total</div>
    <div class="text-base">£{{ total.toFixed(2) }}</div>
  </div>
</div>
<div class="flex justify-between">
  <div 
    @click="setRenew(true)" 
    :class="{
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': selected.renew, 
      'border-gray-200': !selected.renew
    }" 
    class="flex-grow h-[50px]  rounded-xl border items-center justify-center cursor-pointer relative mr-2 flex"
  >
    <div class=" text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
      Auto-Renew 
    </div>
    <input type="radio" :checked="selected.renew" class="absolute top-3 right-3">
  </div>
  <div 
    @click="setRenew(false)" 
    :class="{
      'bg-[#2ab3cb]/20 border-[#2ab3cb]': !selected.renew, 
      'border-gray-200': selected.renew
    }" 
    class="flex-grow h-[50px]  rounded-xl border items-center justify-center cursor-pointer relative ml-2 flex"
  >
    <div class=" text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">
      No Renewal
    </div>
    <input type="radio" :checked="!selected.renew" class="absolute top-3 right-3">
  </div>
  </div>
 </div>
<!-- Footer -->
  <div class="w-full p-4 bg-white border-t border-gray-200 flex flex-wrap gap-4">
  <!-- Add To Cart Button -->
  <button @click="addToCart" 
    :class="[
      'flex-grow h-12 w-full px-6 py-3 font-bold rounded-lg shadow-sm',
      'bg-[#2ab3cb] text-black',
      buttonClicked ? 'animate-pulse bg-green-400 text-white' : ''
    ]">
    Add To Cart
  </button>

  <button @click="addAnother" 
    :class="[
      'flex-grow h-12 px-6 py-3 font-bold rounded-lg shadow-sm',
      'bg-[#2ab3cb] text-black'
    ]">
    Add Another 
  </button>

  <!-- View Cart Button -->
  <button @click="changeView" 
    :class="[
      'flex-grow h-12 px-6 py-3 font-bold rounded-lg shadow-sm',
      'bg-[#081F3F] text-white'
    ]">
    Finish and Pay
  </button>
</div>
    <new-address v-if="showNewAddress" @close="close"></new-address>
</section>
</template>

<script>
import NewAddress from "../../components/NewAddress";
import backArrow from "assets/backarrow.svg";

export default {
	components: {
    NewAddress
	},

	props: [
		"value"
	],

	data() {
    return {
      img: {backArrow},
			loading: true, // Whether loading AJAX data
			cards: [],
			selected: this.value,
      showNewCard: false,
      buttonClicked:false,
      showNewAddress: false,
      addresses: [],
      addons: 0,
      orderPrice:0,
		}
	},

	created() {
		this.reload();
	},

  methods: {
    changeView() {
    this.$emit("input", this.selected);
		this.$emit("changeView");
    },
    addAnother() {
      this.addons =  0,
      this.orderPrice=0,
      this.selected = {
    load: {},
    type: 'clothes',
    whites: false,
    feathers: false,
    				ownBag:false,
				kidsClothes: false,
    slot: {
      start: null,
      end: null,
    },
    address: null,
    card: {
      id: null,
    },
    softener: {
      type: 0,  // Default to no softener preference
    },
  };
			this.$emit("addAnother");
    },
addToCart() {
  this.buttonClicked = true;
  this.$emit("input", this.selected);
  this.$emit("addToCart");
  setTimeout(() => {
    this.buttonClicked = false;
  }, 1000); // Button stays in the "clicked" state for 2 seconds (adjust as needed)
},
		reload() {
			this.$api.get("/user/cards").then(res => {
				this.loading = false;
				this.cards = res.data.cards;
      });
        this.$api.get("/user/addresses").then(res => {
        let { addresses, preferred } = res.data;

        this.loading = false;
        this.addresses = addresses;
      });
		},

		editCards() {
			this.$router.push("/billing");
		},
   emmitBack() {
      this.$emit("back");
    },
		close() {
      this.showNewCard = false;
      this.showNewAddress = false;
			this.reload();
    },

  },

  computed: {
    formattedSlot() {
       const startDate = new Date(this.selected.slot.start);
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayOfWeek = daysOfWeek[startDate.getDay()];
      const day = startDate.getDate()
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = months[startDate.getMonth()];
      const year = startDate.getFullYear();
      const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}`;
      return `${formattedDate}, ${this.selected.slot.time_string}`;
    },
    calculateAddons() {
      const preferencesCost = this.selected.preferencesTotal || 0;
      const express = this.selected.category === 'express' ? 6 : 0;
      const kidsClothesCost = this.selected.kidsClothes ? 2.0 : 0;
      const feathersCost = this.selected.feather ? 2.0 : 0;
      const ownBagDiscount = this.selected.ownBag ? -0.2 : 0;
      this.addons = Math.round((preferencesCost + kidsClothesCost + express + feathersCost + ownBagDiscount) * 100) / 100;
      this.selected.addons = this.addons
      this.$emit("input", this.selected);
      return this.addons;
    },
    // Calculate total price
    total() {
      this.orderPrice = Math.round((this.selected.load.price + this.addons) * 100) / 100;
      this.selected.orderPrice = this.orderPrice
      this.$emit("input", this.selected);
      return this.orderPrice
    },
  }
}
</script>

<style lang="less" scoped>
</style>
