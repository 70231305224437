<template lang="html">
  <section class="preferences p-4">
      <loader v-if="loading" name="your preferences"></loader>
      <div v-else>
        <!-- Detergent Section -->
        <div class="text-zinc-500 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">Detergent</div>
        <div class="mt-2" v-if="preferences && preferences.detergent">
          <div class="text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">{{ selectedDetergent.name }}</div>
          <div class="mt-2">
            <select v-model="selectedDetergent.id" @change="setPreferences({ type: 'detergent', id: selectedDetergent.id })" class="font-['Manrope'] text-sm mb-2 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb] sm:text-sm">
              <option class="font-['Manrope'] text-sm whitespace-normal" v-for="detergent in preferences.detergent" :key="detergent.id" :value="detergent.id">
                {{ detergent.name }} - {{ detergent.price | formatPrice }}
              </option>
            </select>
          </div>
        </div>

        <!-- Softener Section -->
        <div class="mt-4" v-if="preferences && preferences.softener">
          <div class="text-zinc-500 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">Softener</div>
          <div class="mt-2">
            <div class="text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">{{ selectedSoftener.name }}</div>
            <div class="mt-2">
              <select v-model="selectedSoftener.id" @change="setPreferences({ type: 'softener', id: selectedSoftener.id })" class="font-['Manrope'] text-sm mb-2 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb] sm:text-sm">
                <option class="font-['Manrope'] text-sm whitespace-normal"  v-for="softener in preferences.softener" :key="softener.id" :value="softener.id">
                  {{ softener.name }} - {{ softener.price | formatPrice }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Drying Section -->
        <div v-if="!hideDrying && preferences && preferences.drying" class="mt-4">
          <div class="text-zinc-500 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">Drying</div>
          <div class="mt-2">
            <div class="text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">{{ selectedDrying.name }}</div>
            <div class="mt-2">
              <select v-model="selectedDrying.id" @change="setPreferences({ type: 'drying', id: selectedDrying.id })" class="font-['Manrope'] text-sm mb-2 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb] sm:text-sm">
                <option class="font-['Manrope'] text-sm whitespace-normal" v-for="drying in preferences.drying" :key="drying.id" :value="drying.id">
                  {{ drying.name }} - {{ drying.price | formatPrice }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Washing Section -->
        <div class="mt-4" v-if="preferences && preferences.washing">
          <div class="text-zinc-500 text-xs font-medium font-['Manrope'] leading-tight tracking-tight">Washing</div>
          <div class="mt-2">
            <div class="text-zinc-900 text-sm font-bold font-['Manrope'] leading-normal tracking-tight">{{ selectedWashing.name }}</div>
            <div class="mt-2">
              <select v-model="selectedWashing.id" @change="setPreferences({ type: 'washing', id: selectedWashing.id })" class="font-['Manrope'] text-sm mb-2 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2ab3cb] focus:border-[#2ab3cb] sm:text-sm">
                <option class="font-['Manrope'] text-sm whitespace-normal" v-for="washing in preferences.washing" :key="washing.id" :value="washing.id">
                  {{ washing.name }} - {{ washing.price | formatPrice }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
export default {
  props: ["hideDrying","value"],
  data() {
    return {
      loading: true,
      preferences: null,
      totalCost: 0,
      selectedDetergent: {
        id: "",
        name: "",
        description: "",
        price: 0,
        type: "",
        disabled: ""
      },
      selectedDrying: {
        id: "",
        name: "",
        description: "",
        price: 0,
        type: "",
        disabled: ""
      },
      selectedSoftener: {
        id: "",
        name: "",
        description: "",
        price: 0,
        type: "",
        disabled: ""
      },
      selectedWashing: {
        id: "",
        name: "",
        description: "",
        price: 0,
        type: "",
        disabled: ""
      }
    };
  },
  created() {
    this.$api.get("/preferences/all").then(res => {
      this.preferences = res.data;
          this.$api.get("/user/preferences").then(res => {
      var MatchedID;
      this.selectedDetergent.id = res.data[0].detergent_id;
      this.selectedDrying.id = res.data[0].drying_id;
      this.selectedSoftener.id = res.data[0].softener_id;
      this.selectedWashing.id = res.data[0].washing_id;

      MatchedID = this.preferences.detergent.filter(item => item.id.indexOf(res.data[0].detergent_id) !== -1);
      this.selectedDetergent.price = MatchedID[0].price;
      this.selectedDetergent.disabled = MatchedID[0].disabled;
      MatchedID = this.preferences.drying.filter(item => item.id.indexOf(res.data[0].drying_id) !== -1);
      this.selectedDrying.price = MatchedID[0].price;
      this.selectedDrying.disabled = MatchedID[0].disabled;
      MatchedID = this.preferences.softener.filter(item => item.id.indexOf(res.data[0].softener_id) !== -1);
      this.selectedSoftener.price = MatchedID[0].price;
      this.selectedSoftener.disabled = MatchedID[0].disabled;
      MatchedID = this.preferences.washing.filter(item => item.id.indexOf(res.data[0].washing_id) !== -1);
      this.selectedWashing.price = MatchedID[0].price;
      this.selectedWashing.disabled = MatchedID[0].disabled;

      if (this.hideDrying) {
        this.selectedDrying.id = "R1";
        this.selectedDrying.price = 0;
      }
    this.totalCost = this.selectedDetergent.price + this.selectedDrying.price + this.selectedSoftener.price + this.selectedWashing.price;
      this.$emit("input", this.totalCost);
    
      this.PreferencesCost();
    });
      this.loading = false;
    });

  },
  filters: {
    formatPrice: price => "£" + price.toFixed(2)
  },
  methods: {
    PreferencesCost() {
    this.totalCost = this.selectedDetergent.price + this.selectedDrying.price + this.selectedSoftener.price + this.selectedWashing.price;
    this.$emit("input", this.totalCost);
    },
    updatePreferences() {
      var PreferencesToUpdate = {
        detergent_id: this.selectedDetergent.id,
        drying_id: this.selectedDrying.id,
        softener_id: this.selectedSoftener.id,
        washing_id: this.selectedWashing.id,
        cost: this.totalCost
      };

      this.$api.post("/user/updatepreferences", PreferencesToUpdate).then(res => {
        if (res.status == "200") {
          // Preferences updated successfully
        } else {
          window.alert("Something went wrong, please try again");
        }
      });
    },
    updatePreferencesconfirm() {
      var PreferencesToUpdate = {
        detergent_id: this.selectedDetergent.id,
        drying_id: this.selectedDrying.id,
        softener_id: this.selectedSoftener.id,
        washing_id: this.selectedWashing.id,
        cost: this.totalCost
      };

      this.$api.post("/user/updatepreferences", PreferencesToUpdate).then(res => {
        if (res.status == "200") {
          window.alert("Preferences Saved");
        } else {
          window.alert("Something went wrong, please try again");
        }
      });
    },
    setPreferences(setSelected) {
      if (setSelected.disabled == "true") {
        return;
      }

      if (setSelected.type == "detergent") {
        this.selectedDetergent.id = setSelected.id;
        const MatchedID = this.preferences.detergent.filter(item => item.id.indexOf(setSelected.id) !== -1);
        this.selectedDetergent.price = MatchedID[0].price;
        this.updatePreferences();
        this.PreferencesCost();
      }
      if (setSelected.type == "drying") {
        this.selectedDrying.id = setSelected.id;
        const MatchedID = this.preferences.drying.filter(item => item.id.indexOf(setSelected.id) !== -1);
        this.selectedDrying.price = MatchedID[0].price;
        this.updatePreferences();
        this.PreferencesCost();
      }
      if (setSelected.type == "softener") {
        this.selectedSoftener.id = setSelected.id;
        const MatchedID = this.preferences.softener.filter(item => item.id.indexOf(setSelected.id) !== -1);
        this.selectedSoftener.price = MatchedID[0].price;
        this.updatePreferences();
        this.PreferencesCost();
      }
      if (setSelected.type == "washing") {
        this.selectedWashing.id = setSelected.id;
        const MatchedID = this.preferences.washing.filter(item => item.id.indexOf(setSelected.id) !== -1);
        this.selectedWashing.price = MatchedID[0].price;
        this.updatePreferences();
        this.PreferencesCost();
      }
    },
    toggleDialog(type) {
      this.$set(this.dialogs, type, !this.dialogs[type]);
    },
    close() {
      this.showNewAddress = false;
      this.reload();
    }
  },
  watch: {
    hideDrying(newVal) {
      if (this.hideDrying) {
        this.selectedDrying.id = "R1";
        this.selectedDrying.price = 0;
      }
    }
  }
};
</script>

<style lang="less">

</style>