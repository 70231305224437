// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.bundle .items[data-v-6aec6420] {
  border: solid 1px #DDD;
  border-radius: 4px;
  margin: 10px 0;
}
section.bundle .item[data-v-6aec6420] {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
section.bundle .item[data-v-6aec6420]:not(:last-of-type) {
  border-bottom: solid 1px #DDD;
}
`, "",{"version":3,"sources":["webpack://./src/views/Order/Bundle.vue","webpack://./Bundle.vue"],"names":[],"mappings":"AACA;EAEE,sBAAA;EACA,kBAAA;EACA,cAAA;ACDF;ADHA;EAQE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;ACFF;ADIE;EACC,6BAAA;ACFH","sourcesContent":["\nsection.bundle {\n\t.items {\n\t\tborder: solid 1px #DDD;\n\t\tborder-radius: 4px;\n\t\tmargin: 10px 0;\n\t}\n\n\t.item {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tpadding: 10px;\n\n\t\t&:not(:last-of-type) {\n\t\t\tborder-bottom: solid 1px #DDD;\n\t\t}\n\t}\n}\n","section.bundle .items {\n  border: solid 1px #DDD;\n  border-radius: 4px;\n  margin: 10px 0;\n}\nsection.bundle .item {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 10px;\n}\nsection.bundle .item:not(:last-of-type) {\n  border-bottom: solid 1px #DDD;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
